import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INCREMENT_QTY,
  DECREMENT_QTY,
} from "../constants/ActionTypes";

export default function cartReducer(
  state = {
    cart: [],
  },
  action
) {
  switch (action.type) {
    case ADD_TO_CART:
      const productId = action.product.uuid;
      if (
        state.cart.findIndex((product) => product.uuid === productId) !== -1
      ) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.uuid === productId) {
            cartAcc.push({
              ...product,
              quantity: product.quantity + 1,
              sum:
                ((product.price * product.discount) / 100) *
                (product.quantity + 1),
            }); // Increment qty
          } else {
            cartAcc.push(product);
          }

          return cartAcc;
        }, []);

        return { ...state, cart };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            quantity: action.quantity,
            sum:
              ((action.product.price * action.product.discount) / 100) *
              action.quantity,
          },
        ],
      };

    case DECREMENT_QTY:
      const cart = state.cart.reduce((cartAcc, product) => {
        if (product.uuid === action.productId && product.quantity > 1) {
          cartAcc.push({
            ...product,
            quantity: product.quantity - 1,
            sum:
              ((product.price * product.discount) / 100) *
              (product.quantity - 1),
          });
        } else {
          cartAcc.push(product);
        }

        return cartAcc;
      }, []);

      return { ...state, cart };

    case REMOVE_FROM_CART:
      return {
        cart: state.cart.filter((item) => item.uuid !== action.product.uuid),
      };

    default:
  }
  return state;
}
