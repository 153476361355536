import { useCallback, useState } from 'react';

export const useMaxTimeOfBasket = () => {
  const [maxSeconds, setMaxSeconds] = useState(0);

  const extractMaxTime = useCallback((products) => {
    const promise = new Promise((resolve) => {
      const times = products
        .map((product) => product.approximate_preparation_time)
        .sort();

      const seconds = times[times.length - 1];

      resolve({ seconds });
    });

    promise.then((response) => {
      setMaxSeconds(response.seconds);
    });
  }, []);

  return { extractMaxTime, maxSeconds };
};
