import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ProductVariants from "./product-variants";

const DetailsWithPrice = props => {
  const [quantity, setQuantity] = useState(1);
  const {
    item,
    prices,
    pathname,
    addProduct,
    variantGroups,
    selectedVariants,
    selectVariant
  } = props;

  useEffect(
    () => {
      if (item.stock === 0) {
        setQuantity(0);
      }
    },
    [item]
  );

  const minusQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const plusQty = () => {
    setQuantity(quantity + 1);
  };

  const changeQty = e => {
    setQuantity(parseInt(e.target.value) || quantity);
  };

  const checkMaxPerOrder = React.useMemo(
    () => {
      if (
        !!item.max_per_order &&
        Number(item.max_per_order) === Number(quantity)
      ) {
        toast.warn("Llegaste al máximo permitido por orden");
        return true;
      }
      return false;
    },
    [quantity, item]
  );

  return (
    <div className="col-lg-6 rtl-text">
      <div className="product-right">
        {item.vendor && <h4>{item.vendor}</h4>}
        <h2> {item.name} </h2>
        {prices.compare_to > 0 && (
          <h4>
            <del>{`${prices.symbol} ${prices.compare_to.toFixed(2)}`}</del>
            <span>{prices.discount.toFixed(2)}% Descuento</span>
          </h4>
        )}
        <h3>{`${prices.symbol} ${prices.price}`}</h3>
        {item.description && (
          <div className="border-product">
            <h6 className="product-title">Descripción</h6>
            <p>{item.description}</p>
          </div>
        )}
        {variantGroups && variantGroups.length > 0 && (
          <div className="product-description border-product">
            <ProductVariants
              variantGroups={variantGroups}
              selectVariant={selectVariant}
              selectedVariants={selectedVariants}
            />
          </div>
        )}
        <div className="product-description border-product">
          <span
            className={
              item.stock === 0 || item.stock < quantity
                ? "outstock-cls"
                : "instock-cls"
            }
          >
            {item.stock === 0 || item.stock < quantity
              ? "Producto fuera de stock"
              : "En stock"}
          </span>
          <h6 className="product-title">cantidad</h6>
          <div className="qty-box">
            <div className="input-group">
              <span className="input-group-prepend">
                <button
                  type="button"
                  className="btn quantity-left-minus"
                  onClick={minusQty}
                  data-type="minus"
                  data-field=""
                >
                  <i className="fa fa-angle-left" />
                </button>
              </span>
              <input
                type="text"
                name="quantity"
                value={quantity}
                onChange={changeQty}
                readOnly={!item.is_available}
                className="form-control input-number"
              />
              <span className="input-group-prepend">
                <button
                  type="button"
                  className="btn quantity-right-plus"
                  onClick={
                    Number(quantity) >= item.stock || checkMaxPerOrder
                      ? () => {}
                      : plusQty
                  }
                  data-type="plus"
                  data-field=""
                  disabled={
                    !item.is_available ||
                    Number(quantity) >= item.stock ||
                    checkMaxPerOrder
                  }
                >
                  <i className="fa fa-angle-right" />
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className="product-buttons">
          <a
            className={`btn btn-solid with-icon m-0 ${!item.is_available &&
              "btn-disabled"}`}
            onClick={() => addProduct(item, quantity)}
            disabled={!item.is_available}
          >
            {item.is_available ? "Agregar" : "No disponible"}
            {item.is_available && (
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.14001 8.91002H36.87L31.08 24.84C30.86 25.45 30.28 25.85 29.63 25.85H12.04C11.26 25.85 10.61 25.27 10.51 24.5L7.60001 1.20001H1.44M15.3 33.55C15.3 35.25 13.92 36.63 12.22 36.63C10.52 36.63 9.14001 35.25 9.14001 33.55C9.14001 31.85 10.52 30.47 12.22 30.47C13.92 30.47 15.3 31.85 15.3 33.55ZM32.25 33.55C32.25 35.25 30.87 36.63 29.17 36.63C27.47 36.63 26.09 35.25 26.09 33.55C26.09 31.85 27.47 30.47 29.17 30.47C30.87 30.47 32.25 31.85 32.25 33.55Z"
                  stroke-width="1.134"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </a>
        </div>
        <div className="border-product">
          <h6 className="product-title">compartir</h6>
          <div className="product-icon">
            <ul className="product-social">
              <li>
                <a
                  href={`https://www.facebook.com/sharer.php?u=https://pasiflora.pe${pathname}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  href={`https://twitter.com/intent/tweet?text=${
                    item.name
                  }%20%https://pasiflora.pe${pathname}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  href={`https://mail.google.com/mail/?view=cm&fs=1&mailto:?&body=${
                    item.name
                  } https://pasiflora.pe${pathname}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-envelope" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsWithPrice;
