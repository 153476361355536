import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import CartPage from "../components/common/headers/common/cart-header";
import { CartContext } from "../contexts/cart";

const CartContainer = () => {
  const {
    getPrices,
    prices,
    handleRemoveProduct,
    productsCartArray,
  } = useContext(CartContext);

  useEffect(
    () => {
      getPrices();
    },
    [getPrices]
  );

  return (
    <li className="onhover-div mobile-cart">
      <div className="cart-qty-cls">{productsCartArray.length}</div>
      <Link to={`${process.env.PUBLIC_URL}/cart`}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/icons/cart.svg`}
          className="img-fluid"
          alt=""
        />
      </Link>
      <ul className="show-div shopping-cart">
        {productsCartArray.map((item, index) => (
          <CartPage
            key={index}
            item={item}
            total={prices.subtotal}
            removeFromCart={() => handleRemoveProduct(item)}
          />
        ))}
        {productsCartArray.length > 0 ? (
          <div>
            <li>
              <div className="total">
                <h5>
                  subtotal : <span>S/ {prices.subtotal}</span>
                </h5>
              </div>
            </li>
            <li>
              <div className="buttons">
                <Link
                  to={`${process.env.PUBLIC_URL}/cart`}
                  className="view-cart"
                >
                  ver carrito
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/checkout`}
                  className="checkout"
                >
                  checkout
                </Link>
              </div>
            </li>
          </div>
        ) : (
          <li>
            <h5>Tu carrito está vacío actualmente.</h5>
          </li>
        )}
      </ul>
    </li>
  );
};

export default CartContainer;
