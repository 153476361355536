import env from "./env";

export const slug_business = "pasiflora";
export const baseUrlAPIV5 = env.BASE_APIv5;
export const baseUrlAPIV6 = env.BASE_APIv6;
export const baseUrlV1 = env.BASE_APIv1;

export const fakeAPI =
  "https://802d0a58-9a21-400c-846f-aad40b992172.mock.pstmn.io/api/v6";


export const delivery = '5572a702-907d-4186-ae3f-94a7a4a349d3';
export const pickup = '9246472f-2a9f-45a2-ace3-56babc60a832';
export const ontable = 'b9e01f37-63f2-4d03-a783-2d5ac1904b02';

