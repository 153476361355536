const themeDefaultColor = "#ce9cf0";

// SVG Icons
export const svgFreeShipping = `<svg width="53" height="33" viewBox="0 0 53 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.5 27.44H29.7599V3.46002C29.7599 2.26002 28.78 1.28003 27.58 1.28003H3.59998C2.39998 1.28003 1.41998 2.26002 1.41998 3.46002V27.44H5.77997M14.5 27.44C14.5 29.85 12.55 31.8 10.14 31.8C7.72995 31.8 5.77997 29.85 5.77997 27.44C5.77997 25.03 7.72995 23.08 10.14 23.08C12.55 23.08 14.5 25.03 14.5 27.44ZM47.2 27.44C47.2 29.85 45.25 31.8 42.84 31.8C40.43 31.8 38.48 29.85 38.48 27.44C38.48 25.03 40.43 23.08 42.84 23.08C45.25 23.08 47.2 25.03 47.2 27.44ZM47.2 27.44H51.56V20.9C51.56 18.5 49.6 16.54 47.2 16.54H29.7599V27.44H38.48H47.2ZM42.84 16.54H29.7599V7.82001H36.3C39.91 7.82001 42.84 16.54 42.84 16.54Z" stroke=${themeDefaultColor} stroke-width="1.134" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;
export const svgservice = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve" width="512px" height="512px">
    <g>
        <g>
           <g>
             <path d="M472,432h-24V280c-0.003-4.418-3.588-7.997-8.006-7.994c-2.607,0.002-5.05,1.274-6.546,3.41l-112,160     c-2.532,3.621-1.649,8.609,1.972,11.14c1.343,0.939,2.941,1.443,4.58,1.444h104v24c0,4.418,3.582,8,8,8s8-3.582,8-8v-24h24     c4.418,0,8-3.582,8-8S476.418,432,472,432z M432,432h-88.64L432,305.376V432z" fill=${themeDefaultColor}/>
             <path d="M328,464h-94.712l88.056-103.688c0.2-0.238,0.387-0.486,0.56-0.744c16.566-24.518,11.048-57.713-12.56-75.552     c-28.705-20.625-68.695-14.074-89.319,14.631C212.204,309.532,207.998,322.597,208,336c0,4.418,3.582,8,8,8s8-3.582,8-8     c-0.003-26.51,21.486-48.002,47.995-48.005c10.048-0.001,19.843,3.151,28.005,9.013c16.537,12.671,20.388,36.007,8.8,53.32     l-98.896,116.496c-2.859,3.369-2.445,8.417,0.924,11.276c1.445,1.226,3.277,1.899,5.172,1.9h112c4.418,0,8-3.582,8-8     S332.418,464,328,464z" fill=${themeDefaultColor}/>
             <path d="M216.176,424.152c0.167-4.415-3.278-8.129-7.693-8.296c-0.001,0-0.002,0-0.003,0     C104.11,411.982,20.341,328.363,16.28,224H48c4.418,0,8-3.582,8-8s-3.582-8-8-8H16.28C20.283,103.821,103.82,20.287,208,16.288     V40c0,4.418,3.582,8,8,8s8-3.582,8-8V16.288c102.754,3.974,185.686,85.34,191.616,188l-31.2-31.2     c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116l44.656,44.656c0.841,1.018,1.925,1.807,3.152,2.296     c0.313,0.094,0.631,0.172,0.952,0.232c0.549,0.198,1.117,0.335,1.696,0.408c0.08,0,0.152,0,0.232,0c0.08,0,0.152,0,0.224,0     c0.609-0.046,1.211-0.164,1.792-0.352c0.329-0.04,0.655-0.101,0.976-0.184c1.083-0.385,2.069-1.002,2.888-1.808l45.264-45.248     c3.069-3.178,2.982-8.242-0.196-11.312c-3.1-2.994-8.015-2.994-11.116,0l-31.976,31.952     C425.933,90.37,331.38,0.281,216.568,0.112C216.368,0.104,216.2,0,216,0s-0.368,0.104-0.568,0.112     C96.582,0.275,0.275,96.582,0.112,215.432C0.112,215.632,0,215.8,0,216s0.104,0.368,0.112,0.568     c0.199,115.917,91.939,210.97,207.776,215.28h0.296C212.483,431.847,216.013,428.448,216.176,424.152z" fill=${themeDefaultColor}/>
             <path d="M323.48,108.52c-3.124-3.123-8.188-3.123-11.312,0L226.2,194.48c-6.495-2.896-13.914-2.896-20.408,0l-40.704-40.704     c-3.178-3.069-8.243-2.981-11.312,0.197c-2.994,3.1-2.994,8.015,0,11.115l40.624,40.624c-5.704,11.94-0.648,26.244,11.293,31.947     c9.165,4.378,20.095,2.501,27.275-4.683c7.219-7.158,9.078-18.118,4.624-27.256l85.888-85.888     C326.603,116.708,326.603,111.644,323.48,108.52z M221.658,221.654c-0.001,0.001-0.001,0.001-0.002,0.002     c-3.164,3.025-8.148,3.025-11.312,0c-3.125-3.124-3.125-8.189-0.002-11.314c3.124-3.125,8.189-3.125,11.314-0.002     C224.781,213.464,224.781,218.53,221.658,221.654z" fill=${themeDefaultColor}/>
           </g>
        </g>
    </g>
  </svg>`;

export const svgoffer = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -14 512.00001 512">
   <path
       d="m136.964844 308.234375c4.78125-2.757813 6.417968-8.878906 3.660156-13.660156-2.761719-4.777344-8.878906-6.417969-13.660156-3.660157-4.78125 2.761719-6.421875 8.882813-3.660156 13.660157 2.757812 4.78125 8.878906 6.421875 13.660156 3.660156zm0 0"
       fill=${themeDefaultColor} />
   <path
       d="m95.984375 377.253906 50.359375 87.230469c10.867188 18.84375 35.3125 25.820313 54.644531 14.644531 19.128907-11.054687 25.703125-35.496094 14.636719-54.640625l-30-51.96875 25.980469-15c4.78125-2.765625 6.421875-8.878906 3.660156-13.660156l-13.003906-22.523437c1.550781-.300782 11.746093-2.300782 191.539062-37.570313 22.226563-1.207031 35.542969-25.515625 24.316407-44.949219l-33.234376-57.5625 21.238282-32.167968c2.085937-3.164063 2.210937-7.230469.316406-10.511719l-20-34.640625c-1.894531-3.28125-5.492188-5.203125-9.261719-4.980469l-38.472656 2.308594-36.894531-63.90625c-5.34375-9.257813-14.917969-14.863281-25.605469-14.996094-.128906-.003906-.253906-.003906-.382813-.003906-10.328124 0-19.703124 5.140625-25.257812 13.832031l-130.632812 166.414062-84.925782 49.03125c-33.402344 19.277344-44.972656 62.128907-25.621094 95.621094 17.679688 30.625 54.953126 42.671875 86.601563 30zm102.324219 57.238282c5.523437 9.554687 2.253906 21.78125-7.328125 27.316406-9.613281 5.558594-21.855469 2.144531-27.316407-7.320313l-50-86.613281 34.640626-20c57.867187 100.242188 49.074218 85.011719 50.003906 86.617188zm-22.683594-79.296876-10-17.320312 17.320312-10 10 17.320312zm196.582031-235.910156 13.820313 23.9375-12.324219 18.664063-23.820313-41.261719zm-104.917969-72.132812c2.683594-4.390625 6.941407-4.84375 8.667969-4.796875 1.707031.019531 5.960938.550781 8.527344 4.996093l116.3125 201.464844c3.789063 6.558594-.816406 14.804688-8.414063 14.992188-1.363281.03125-1.992187.277344-5.484374.929687l-123.035157-213.105469c2.582031-3.320312 2.914063-3.640624 3.425781-4.480468zm-16.734374 21.433594 115.597656 200.222656-174.460938 34.21875-53.046875-91.878906zm-223.851563 268.667968c-4.390625-7.597656-6.710937-16.222656-6.710937-24.949218 0-17.835938 9.585937-34.445313 25.011718-43.351563l77.941406-45 50 86.601563-77.941406 45.003906c-23.878906 13.78125-54.515625 5.570312-68.300781-18.304688zm0 0"
       fill=${themeDefaultColor} />
   <path
       d="m105.984375 314.574219c-2.761719-4.78125-8.878906-6.421875-13.660156-3.660157l-17.320313 10c-4.773437 2.757813-10.902344 1.113282-13.660156-3.660156-2.761719-4.78125-8.878906-6.421875-13.660156-3.660156s-6.421875 8.878906-3.660156 13.660156c8.230468 14.257813 26.589843 19.285156 40.980468 10.980469l17.320313-10c4.78125-2.761719 6.421875-8.875 3.660156-13.660156zm0 0"
       fill=${themeDefaultColor} />
   <path
       d="m497.136719 43.746094-55.722657 31.007812c-4.824218 2.6875-6.5625 8.777344-3.875 13.601563 2.679688 4.820312 8.765626 6.566406 13.601563 3.875l55.71875-31.007813c4.828125-2.6875 6.5625-8.777344 3.875-13.601562-2.683594-4.828125-8.773437-6.5625-13.597656-3.875zm0 0"
       fill=${themeDefaultColor} />
   <path
       d="m491.292969 147.316406-38.636719-10.351562c-5.335938-1.429688-10.820312 1.734375-12.25 7.070312-1.429688 5.335938 1.738281 10.816406 7.074219 12.246094l38.640625 10.351562c5.367187 1.441407 10.824218-1.773437 12.246094-7.070312 1.429687-5.335938-1.738282-10.820312-7.074219-12.246094zm0 0"
       fill=${themeDefaultColor} />
   <path
       d="m394.199219 7.414062-10.363281 38.640626c-1.429688 5.335937 1.734374 10.816406 7.070312 12.25 5.332031 1.425781 10.816406-1.730469 12.25-7.070313l10.359375-38.640625c1.429687-5.335938-1.734375-10.820312-7.070313-12.25-5.332031-1.429688-10.816406 1.734375-12.246093 7.070312zm0 0"
       fill=${themeDefaultColor} />
  </svg>`;

export const svgpayment = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512px" height="512px">
   <g>
      <g>
         <g>
            <path d="M498.409,175.706L336.283,13.582c-8.752-8.751-20.423-13.571-32.865-13.571c-12.441,0-24.113,4.818-32.865,13.569     L13.571,270.563C4.82,279.315,0,290.985,0,303.427c0,12.442,4.82,24.114,13.571,32.864l19.992,19.992     c0.002,0.001,0.003,0.003,0.005,0.005c0.002,0.002,0.004,0.004,0.006,0.006l134.36,134.36H149.33     c-5.89,0-10.666,4.775-10.666,10.666c0,5.89,4.776,10.666,10.666,10.666h59.189c0.014,0,0.027,0.001,0.041,0.001     s0.027-0.001,0.041-0.001l154.053,0.002c5.89,0,10.666-4.776,10.666-10.666c0-5.891-4.776-10.666-10.666-10.666l-113.464-0.002     L498.41,241.434C516.53,223.312,516.53,193.826,498.409,175.706z M483.325,226.35L226.341,483.334     c-4.713,4.712-11.013,7.31-17.742,7.32h-0.081c-6.727-0.011-13.025-2.608-17.736-7.32L56.195,348.746L302.99,101.949     c4.165-4.165,4.165-10.919,0-15.084c-4.166-4.165-10.918-4.165-15.085,0.001L41.11,333.663l-12.456-12.456     c-4.721-4.721-7.321-11.035-7.321-17.779c0-6.744,2.6-13.059,7.322-17.781L285.637,28.665c4.722-4.721,11.037-7.321,17.781-7.321     c6.744,0,13.059,2.6,17.781,7.322l57.703,57.702l-246.798,246.8c-4.165,4.164-4.165,10.918,0,15.085     c2.083,2.082,4.813,3.123,7.542,3.123c2.729,0,5.459-1.042,7.542-3.124l246.798-246.799l89.339,89.336     C493.128,200.593,493.127,216.546,483.325,226.35z" fill=${themeDefaultColor} />
            <path d="M262.801,308.064c-4.165-4.165-10.917-4.164-15.085,0l-83.934,83.933c-4.165,4.165-4.165,10.918,0,15.085     c2.083,2.083,4.813,3.124,7.542,3.124c2.729,0,5.459-1.042,7.542-3.124l83.934-83.933     C266.966,318.982,266.966,312.229,262.801,308.064z" fill=${themeDefaultColor} />
            <path d="M228.375,387.741l-34.425,34.425c-4.165,4.165-4.165,10.919,0,15.085c2.083,2.082,4.813,3.124,7.542,3.124     c2.731,0,5.459-1.042,7.542-3.124l34.425-34.425c4.165-4.165,4.165-10.919,0-15.085     C239.294,383.575,232.543,383.575,228.375,387.741z" fill=${themeDefaultColor} />
            <path d="M260.054,356.065l-4.525,4.524c-4.166,4.165-4.166,10.918-0.001,15.085c2.082,2.083,4.813,3.125,7.542,3.125     c2.729,0,5.459-1.042,7.541-3.125l4.525-4.524c4.166-4.165,4.166-10.918,0.001-15.084     C270.974,351.901,264.219,351.9,260.054,356.065z" fill=${themeDefaultColor} />
            <path d="M407.073,163.793c-2-2-4.713-3.124-7.542-3.124c-2.829,0-5.541,1.124-7.542,3.124l-45.255,45.254     c-2,2.001-3.124,4.713-3.124,7.542s1.124,5.542,3.124,7.542l30.17,30.167c2.083,2.083,4.813,3.124,7.542,3.124     c2.731,0,5.459-1.042,7.542-3.124l45.253-45.252c4.165-4.165,4.165-10.919,0-15.084L407.073,163.793z M384.445,231.673     l-15.085-15.084l30.17-30.169l15.084,15.085L384.445,231.673z" fill=${themeDefaultColor} />
            <path d="M320.339,80.186c2.731,0,5.461-1.042,7.543-3.126l4.525-4.527c4.164-4.166,4.163-10.92-0.003-15.084     c-4.165-4.164-10.92-4.163-15.084,0.003l-4.525,4.527c-4.164,4.166-4.163,10.92,0.003,15.084     C314.881,79.146,317.609,80.186,320.339,80.186z" fill=${themeDefaultColor} />
            <path d="M107.215,358.057l-4.525,4.525c-4.165,4.164-4.165,10.918,0,15.085c2.083,2.082,4.813,3.123,7.542,3.123     s5.459-1.041,7.542-3.123l4.525-4.525c4.165-4.166,4.165-10.92,0-15.085C118.133,353.891,111.381,353.891,107.215,358.057z" fill=${themeDefaultColor} />
         </g>
      </g>
   </g>
  </svg>`;

export const svgBars = `
  <svg width="38" height="28" viewBox="0 0 38 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.909973 1.26001H37.49M0.909973 13.98H37.49H0.909973ZM0.909973 26.7H37.49H0.909973Z" stroke=${themeDefaultColor} stroke-width="0.84" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

//Pasiflora icons
export const svgComercio = `
<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 111 113.5"><defs><style>.cls-1,.cls-3,.cls-5{fill:none;}.cls-2{clip-path:url(#clip-path);}.cls-3,.cls-5{stroke:${themeDefaultColor};}.cls-3{stroke-linecap:round;stroke-linejoin:round;stroke-width:10px;}.cls-4{fill:${themeDefaultColor};}.cls-5{stroke-miterlimit:10;stroke-width:4px;}</style><clipPath id="clip-path"><rect class="cls-1" x="-13" y="-11.23" width="156.73" height="147.23"/></clipPath></defs><title>Comercio justo</title><g class="cls-2"><path class="cls-3" d="M7.9,56.89A47.48,47.48,0,1,0,55.37,9.41,47.47,47.47,0,0,0,7.9,56.89Z"/><path class="cls-4" d="M49.79,34A11.12,11.12,0,1,0,60.9,22.85,11.11,11.11,0,0,0,49.79,34"/><path class="cls-4" d="M90.81,88.48c15-23.33-32.53-32.05-32.53-32.05S35.57,50.87,28.56,43.14s5.32-27.3,5.32-27.3L21.33,24.66c-7.75,20.41,3.31,28.13,4.9,29.79.09.09,1.69,1,1.8,1.11,44,27.16,14.06,45.81,14.06,45.81S70,111.19,90.81,88.48"/><path class="cls-5" d="M90.81,88.48c15-23.33-32.53-32.05-32.53-32.05S35.57,50.87,28.56,43.14s5.32-27.3,5.32-27.3L21.33,24.66c-7.75,20.41,3.31,28.13,4.9,29.79.09.09,1.69,1,1.8,1.11,44,27.16,14.06,45.81,14.06,45.81S70,111.19,90.81,88.48Z"/></g></svg>
`;

export const svgCompra = `
<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.73 147.23"><title>compralocal</title><path fill=${themeDefaultColor} d="M102.42,39.14h37.29c5.63,0,7.58,1.9,7.8,7.43.77,20,.39,39.89-3,59.64-1.39,8.06-3.4,16-7.37,23.24-4.57,8.36-11.11,12.8-21.17,12.66-25.79-.35-51.58-.24-77.38,0-7.93.05-13.62-3.34-17.9-9.59-4.88-7.12-7.12-15.3-8.76-23.62A209.27,209.27,0,0,1,8.25,69.69c-.05-7.59,0-15.18.11-22.76.1-5.88,2.14-7.78,8.07-7.79H53.16a22.82,22.82,0,0,0,.26-2.3c0-3.8,0-7.6,0-11.39C53.47,16.73,58,10,66.23,7.16a34.44,34.44,0,0,1,24,.23c7.83,3,12.16,9.45,12.18,17.79C102.43,29.73,102.42,34.28,102.42,39.14ZM20,51c-.5,18.63-.09,36.88,3.31,55,1.28,6.81,3,13.49,6.72,19.47a9.77,9.77,0,0,0,9.15,5q38.85-.23,77.7,0c4.15,0,7.2-1.44,8.85-4.89a84.59,84.59,0,0,0,5.8-14.86C135,97.38,135.69,83.75,136,70.11c.14-6.3,0-12.61,0-19.07h-33.6c0,4.77,0,9.34,0,13.91S100,72.32,96,72.35s-6.46-2.68-6.47-7.28,0-9.29,0-14H66.28c0,5.14.06,10,0,14.87a6.25,6.25,0,0,1-7.59,6.33c-3.17-.56-5.2-3.23-5.24-7.08,0-4.73,0-9.45,0-14.27ZM89.56,39c0-4.59,0-9,0-13.47,0-3.42-1.26-5.32-4.5-6.3a24.63,24.63,0,0,0-14.33,0c-2.29.68-4.14,2.22-4.25,4.67-.23,5-.07,10-.07,15.08Z"/></svg>
`;

export const svgCrueltyFree = `
<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 156.73 147.23"><defs><style>.cls-1,.cls-3{fill:none;}.cls-3{stroke:${themeDefaultColor};stroke-miterlimit:10;stroke-width:7.93px;}</style><clipPath id="clip-path"><rect class="cls-1" width="156.73" height="147.23"/></clipPath></defs><title>Cruelty free</title><g><path class="cls-3" d="M106.59,53.61S84.88-4.5,97.65,8.59a115.06,115.06,0,0,1,20,29.05s6.48-19.47,15.91-26.81,5.57,16,0,31.6L128,58.08S153.2,68,146.81,87.45c0,0-13.3,25.22-36.07,1S133.51,124,133.51,124s15.75,29.58-17,9.79c0,0-16.39-15.75-18.3-19.37,0,0-14.47-19.58-26.18-18.73s-32.35,21.07-32.35,21.07S29,127.78,20.71,125,22,101.18,22,101.18L35.82,73.62,13,66.91s-9.36-7.24,0-9.79S34.75,58,34.75,58,60.29,25,106.59,53.61Z"/></g></svg>
`;

export const svgNatural = `
<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><title>hojita</title><path fill=${themeDefaultColor} d="M19.72,7.73a26.86,26.86,0,0,1-.46,6.45c-.08.39-.19.77-.31,1.16a4.26,4.26,0,0,1-2.89,3.05c-1.3.38-2.61.74-3.92,1.06a.58.58,0,0,0-.52.52,17.43,17.43,0,0,0-.52,5.38,1,1,0,0,1-.93,1.17,1,1,0,0,1-1.16-1,14.69,14.69,0,0,1,.37-5,4.43,4.43,0,0,0-.28-3.11,6.86,6.86,0,0,1,.58-7.5,31,31,0,0,1,2.4-2.47c1.5-1.53,3-3.06,4.5-4.6A7.07,7.07,0,0,0,17.25,2a1.12,1.12,0,0,1,1.13-.5,1,1,0,0,1,.88.78A10.65,10.65,0,0,1,19.55,4C19.63,5.26,19.66,6.49,19.72,7.73Zm-9,7.9a10.31,10.31,0,0,1,.72-1.45,24.16,24.16,0,0,1,1.9-2.47,1,1,0,0,1,1.45.07,1,1,0,0,1,0,1.46A9.31,9.31,0,0,0,12.49,17a1.22,1.22,0,0,0-.05.34c.68-.19,1.29-.4,1.92-.52a3.42,3.42,0,0,0,2.91-3.28.11.11,0,0,1,0-.05,33.07,33.07,0,0,0,.3-7c0-.44,0-.87-.07-1.32l-2.2,2.11c-1.24,1.22-2.5,2.43-3.72,3.68A4.51,4.51,0,0,0,10.72,15.63Z"/></svg>
`;

export const svgCircleCheck = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 13.2941L12.4444 18L20 10M27 14C27 21.1797 21.1797 27 14 27C6.8203 27 1 21.1797 1 14C1 6.8203 6.8203 1 14 1C21.1797 1 27 6.8203 27 14Z" stroke=${themeDefaultColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
