import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback
} from "react";
import Slider from "react-slick";
import Modal from "react-responsive-modal";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

import "../common/index.scss";
import { CartContext } from "../../contexts/cart";
import { getProduct, getProductSection } from "../../services/products";
import { getRelatedProducts } from "../../services/products";
import { slug_business } from "../../constants";
import ProductsApi from "../../services/ProductsApi";

// import custom Components
import Service from "./common/service";
import NewProduct from "../common/new-product";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import ImageZoom from "./common/product/image-zoom";
import SmallImages from "./common/product/small-image";
import HeaderTwo from "../common/headers/header-two";
import FooterOne from "../common/footers/footer-one";
import { toast } from "react-toastify";
import ProductDetailLoader from "../collection/common/product-detail-loader";

const productsApi = new ProductsApi();

const LeftSideBar = props => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [product, setProduct] = useState(null);
  const [productDetails, setProductsDetails] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [prices, setPrices] = useState({
    price: 0,
    symbol: "S/",
    discount: 0,
    compare_to: 0,
    currencyName: "PEN"
  });
  const [pathname, setPathname] = useState(null);
  const [loading, setLoading] = useState(false);

  // Variants
  const [variants, setVariants] = useState([]);
  const [groupVariants, setGroupVariants] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState({});

  const {
    handleAddProduct,
    productAddedToCartModal,
    setProductAddedToCartModal
  } = useContext(CartContext);

  // const slider1 = useRef(null);
  // const slider2 = useRef(null);
  const { slug } = props.match.params;

  const getSingleProduct = async () => {
    setLoading(true);
    const res = await getProduct(slug);

    if (res) {
      const { data } = res.data;
      setProduct(data);

      if (data.has_variants) {
        setGroupVariants(data.variant_groups || []);
        setVariants(data.variants || []);

        // Selected default position variant
        if (data.variant_groups.length > 0) {
          // eslint-disable-next-line no-unused-expressions
          data.variant_groups.forEach(group => {
            setSelectedVariants(state => ({
              ...state,
              [group.name]: group.values[0].uuid
            }));
          });
        }
      } else {
        setGroupVariants([]);
        setVariants([]);
        setSelectedVariants([]);
      }
    }

    setLoading(false);
  };

  const getSingleProductDetails = async () => {
    const res = await getProductSection(slug);
    if (res) {
      setProductsDetails(res.data);
    }
  };

  const getRelatedProductsFunc = async () => {
    const res = await getRelatedProducts(slug);
    if (res) {
      setRelatedProducts(res.data.data);
    }
  };

  const setProductPrices = () => {
    if (product.prices.data.length > 0) {
      const data = product.prices.data[0];
      const diff = data.compare_to > 0 ? data.compare_to - data.price : 0;
      const discount = diff > 0 ? (diff * 100) / data.compare_to : 0;

      setPrices({
        price: data.price,
        currencyName: data.currency && data.currency.name,
        symbol: data.currency && data.currency.symbol,
        compare_to: data.compare_to,
        discount
      });

      const eventExists = window.dataLayer.some(
        layer => layer.event === "view_item"
      );
      if (!eventExists) {
        window.dataLayer.push({
          event: "view_item",
          category: "Ecommerce",
          label: `Detalle del producto: ${props.history.location.pathname}`,
          ecommerce: {
            detail: {
              products: [
                {
                  id: product.uuid,
                  name: product.name,
                  brand: product.vendor,
                  category: product.category.name,
                  price: data.price,
                  currency: data.currency && data.currency.name
                }
              ]
            }
          }
        });

        ReactPixel.track("ViewContent", {
          content_name: product.name,
          content_category: product.category.name,
          content_ids: [product.uuid],
          content_type: "product",
          value: data.price,
          currency: data.currency && data.currency.name
        });
      }
    }
  };

  useEffect(() => {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }, []);

  useEffect(() => {
    if (product) {
      setProductPrices();
    }
  }, [product]);

  useEffect(() => {
    getStockFunc();
  }, [product && product.uuid]);

  useEffect(() => {
    if (slug) {
      getSingleProduct();
      getSingleProductDetails();
      getRelatedProductsFunc();
      setPathname(props.location.pathname);
    }
  }, [slug]);

  const filterClick = () => {
    document.getElementById("filter").style.left = "-15px";
  };

  const backClick = () => {
    document.getElementById("filter").style.left = "-365px";
  };

  const onCloseModal = () => {
    setProductAddedToCartModal(false);
  };

  const addProduct = (item, quantity) => {
    if (!item.is_available) return;

    if (
      !!item.max_per_order &&
      Number(item.max_per_order) === Number(quantity)
    ) {
      return toast.warn("Llegaste al máximo permitido por orden");
    }

    if (item.stock <= 0 && item.inventory_policy === "deny") {
      return toast.warn(`El producto ${item.name} se encuentra fuera de stock`);
    }

    getStockFunc();
    handleAddProduct(
      { ...item, selected_variants: selectedVariants },
      quantity,
      false,
      true
    );
  };

  const getStockFunc = async () => {
    if (product) {
      if (
        product.inventory_policy === "deny" &&
        product.has_variants === false
      ) {
        const response = await productsApi.getStock({
          slug_business,
          product_uuid: product.uuid
        });

        setProduct(state => ({ ...state, stock: response.stock }));
      }
    }
  };

  const getMainImage = product => {
    if (product && product.media && product.media.length > 0) {
      return product.media[0].conversions.original;
    } else {
      return `${process.env.PUBLIC_URL}/assets/images/default/default_logo.png`;
    }
  };

  // LOGIC FOR VARIANTS
  useEffect(() => {
    async function get() {
      const objectArray = Object.values(selectedVariants).filter(
        val => val !== "" && val
      );

      if (objectArray.length > 0) {
        const match = variants.map(variant => {
          const f = variant.variant_groups.filter(
            v => objectArray.includes(v.value.uuid) === true
          );

          return (
            f && f.length > 0 && objectArray.length === f.length && variant
          );
        });

        if (match.length > 0) {
          const auxVariants = match.filter(f => !!f);
          if (
            auxVariants &&
            auxVariants.length > 0 &&
            objectArray.length === groupVariants.length
          ) {
            let stock = {};

            if (auxVariants[0].inventory_policy === "deny") {
              // get stock variant
              const newStock = await productsApi.getStock({
                slug_business,
                product_uuid: auxVariants[0].uuid
              });

              stock = newStock;
            }

            setProduct(state => ({
              ...state,
              name: auxVariants[0].name,
              description: auxVariants[0].description,
              prices: auxVariants[0].prices,
              media:
                auxVariants[0].media.filter(
                  e => e.collection === "photo" || e.collection === "gallery"
                ) || "",
              stock: stock.stock || -1,
              inventory_policy: auxVariants[0].inventory_policy,
              uuidVariant: auxVariants[0].uuid,
              is_available: auxVariants[0].is_available
            }));

            const variantPrice = auxVariants[0].prices.data[0];
            const diff =
              variantPrice.compare_to > 0
                ? variantPrice.compare_to - variantPrice.price
                : 0;
            const discount =
              diff > 0 ? (diff * 100) / variantPrice.compare_to : 0;
            setPrices({
              price: variantPrice.price,
              currencyName: variantPrice.currency && variantPrice.currency.name,
              symbol: variantPrice.currency && variantPrice.currency.symbol,
              compare_to: variantPrice.compare_to,
              discount
            });
          }
        }
      }
    }
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariants, variants]);

  const selectVariant = useCallback((field, value) => {
    setSelectedVariants(state => ({ ...state, [field]: value }));
  }, []);
  // END LOGIC FOR VARIANTS

  var products = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    fade: true
  };
  var productsnav = {
    slidesToShow: 3,
    swipeToSlide: true,
    arrows: false,
    dots: false,
    focusOnSelect: true
  };

  return (
    <div>
      <Helmet>
        <title>{`${product && product.name} | Pasiflora`}</title>
        <meta name="description" content={product && product.description} />
        <meta
          property="og:title"
          content={`${product && product.name} | Pasiflora`}
        />
        <meta
          property="og:description"
          content={product && product.description}
        />
        <meta
          property="og:url"
          content={`http://pasiflora.pe/producto/${product && product.slug}`}
        />
        <meta property="og:image" content={getMainImage(product)} />
      </Helmet>
      <HeaderTwo logoName={"layout3/logo.png"} />
      <Breadcrumb
        parent={product && product.category && product.category.name}
        title={product && product.name}
      />
      <section className="section-b-space">
        <div className="collection-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 collection-filter" id="filter">
                <div className="collection-mobile-back pl-5">
                  <span onClick={backClick} className="filter-back">
                    <i className="fa fa-angle-left" aria-hidden="true" /> volver
                  </span>
                </div>
                <Service />
                <NewProduct relatedProducts={relatedProducts} />
              </div>
              <div className="col-lg-9 col-sm-12 col-xs-12">
                {product && !loading ? (
                  <div>
                    {/* <div className="row">
                    <div className="col-xl-12">
                      <div className="filter-main-btn mb-2">
                        <span onClick={filterClick} className="filter-btn">
                          <i className="fa fa-filter" aria-hidden="true" />{" "}
                          filter
                        </span>
                      </div>
                    </div>
                  </div> */}
                    <div className="row">
                      <div className="col-lg-6 product-thumbnail">
                        <Slider
                          {...products}
                          asNavFor={nav2}
                          // ref={slider1}
                          className="product-slick"
                        >
                          {product &&
                          product.media &&
                          product.media.length > 0 ? (
                            product.media.map((image, index) => {
                              return (
                                <div key={index}>
                                  <ImageZoom
                                    image={image.conversions.original}
                                    product={product}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              <ImageZoom
                                image={`${process.env.PUBLIC_URL}/assets/images/default/default_logo.png`}
                              />
                            </div>
                          )}
                        </Slider>
                        {/* <SmallImages
                          item={item}
                          settings={productsnav}
                          navOne={nav1}
                        /> */}
                      </div>
                      <DetailsWithPrice
                        item={product}
                        prices={prices}
                        navOne={nav1}
                        pathname={pathname}
                        addProduct={addProduct}
                        variantGroups={groupVariants}
                        selectVariant={selectVariant}
                        selectedVariants={selectedVariants}
                      />
                    </div>
                  </div>
                ) : (
                  <ProductDetailLoader />
                )}
                <DetailsTopTabs productDetails={productDetails} />
                <Modal
                  open={productAddedToCartModal}
                  onClose={onCloseModal}
                  center
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content quick-view-modal">
                      <div className="modal-body">
                        <div className="modal-add-to-cart-header">
                          <h3>¡Producto agregado al carrito!</h3>
                          <small>¿Cuál es tu siguiente paso?</small>
                        </div>
                        <div className="modal-add-to-cart-buttons">
                          <Link
                            to={`${process.env.PUBLIC_URL}/`}
                            className="btn btn-solid white-btn"
                          >
                            Seguir comprando
                          </Link>
                          <Link
                            to={`${process.env.PUBLIC_URL}/cart`}
                            className="btn btn-solid"
                          >
                            Ir al carrito
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* *****Comments***** */}
                {/* <section className="blog-detail-page section-b-space">
                      <div className="row section-b-space">
                        <div className="col-sm-12">
                          <ul className="comment-section">
                            <li>
                              <div className="media">
                                <img
                                  src={`${
                                    process.env.PUBLIC_URL
                                  }/assets/images/avtar.jpg`}
                                  alt="Generic placeholder"
                                />
                                <div className="media-body">
                                  <h6>
                                    Mark Jecno{" "}
                                    <span>( 12 Jannuary 2018 at 1:30AM )</span>
                                  </h6>
                                  <p>
                                    Donec rhoncus massa quis nibh imperdiet
                                    dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie
                                    ac nisi eu laoreet. Integer faucibus enim
                                    nec ullamcorper tempor. Aenean nec felis
                                    dui. Integer tristique odio mi, in volutpat
                                    metus posuere eu. Aenean suscipit ipsum
                                    nunc, id volutpat lorem hendrerit ac. Sed id
                                    elit quam. In ac mauris arcu. Praesent eget
                                    lectus sit amet diam vestibulum varius.
                                    Suspendisse dignissim mattis leo, nec
                                    facilisis erat tempor quis. Vestibulum eu
                                    vestibulum ex.{" "}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="media">
                                <img
                                  src={`${
                                    process.env.PUBLIC_URL
                                  }/assets/images/2.jpg`}
                                  alt="Generic placeholder"
                                />
                                <div className="media-body">
                                  <h6>
                                    Mark Jecno{" "}
                                    <span>( 12 Jannuary 2018 at 1:30AM )</span>
                                  </h6>
                                  <p>
                                    Donec rhoncus massa quis nibh imperdiet
                                    dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie
                                    ac nisi eu laoreet. Integer faucibus enim
                                    nec ullamcorper tempor. Aenean nec felis
                                    dui. Integer tristique odio mi, in volutpat
                                    metus posuere eu. Aenean suscipit ipsum
                                    nunc, id volutpat lorem hendrerit ac. Sed id
                                    elit quam. In ac mauris arcu. Praesent eget
                                    lectus sit amet diam vestibulum varius.
                                    Suspendisse dignissim mattis leo, nec
                                    facilisis erat tempor quis. Vestibulum eu
                                    vestibulum ex.{" "}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="media">
                                <img
                                  src={`${
                                    process.env.PUBLIC_URL
                                  }/assets/images/20.jpg`}
                                  alt="Generic placeholder"
                                />
                                <div className="media-body">
                                  <h6>
                                    Mark Jecno{" "}
                                    <span>( 12 Jannuary 2018 at 1:30AM )</span>
                                  </h6>
                                  <p>
                                    Donec rhoncus massa quis nibh imperdiet
                                    dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie
                                    ac nisi eu laoreet. Integer faucibus enim
                                    nec ullamcorper tempor. Aenean nec felis
                                    dui. Integer tristique odio mi, in volutpat
                                    metus posuere eu. Aenean suscipit ipsum
                                    nunc, id volutpat lorem hendrerit ac. Sed id
                                    elit quam. In ac mauris arcu. Praesent eget
                                    lectus sit amet diam vestibulum varius.
                                    Suspendisse dignissim mattis leo, nec
                                    facilisis erat tempor quis. Vestibulum eu
                                    vestibulum ex.{" "}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                {/* *****add Comments****** */}
                {/* <div className="row blog-contact">
                        <div className="col-sm-12">
                          <h2>Leave Your Comment</h2>
                          <form className="theme-form">
                            <div className="form-row">
                              <div className="col-md-12">
                                <label htmlFor="name">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  placeholder="Enter Your name"
                                  required=""
                                />
                              </div>
                              <div className="col-md-12">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  placeholder="Email"
                                  required=""
                                />
                              </div>
                              <div className="col-md-12">
                                <label htmlFor="exampleFormControlTextarea1">
                                  Comment
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="Write Your Comment"
                                  id="exampleFormControlTextarea1"
                                  rows="6"
                                />
                              </div>
                              <div className="col-md-12">
                                <button className="btn btn-solid" type="submit">
                                  Post Comment
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </section> */}
                {/* ********Comments - end******** */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne logoName={"layout3/logo.png"} />
    </div>
  );
};

export default LeftSideBar;

// export default LeftSideBar;
