import React, { Component } from "react";
// components:
import Marker from "./GoogleMarker";

// examples:
import GoogleMap from "./GoogleMap";
import SearchBox from "./SearchBox";

// consts
const defaultUbi = [-12.1245726, -77.0266616];
const GOOGLE_MAPS_KEY = "AIzaSyAVMuVmXC6BSD0xnHC8ynEIWYgXMdQy0YU";

class Searchbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      places: [],
      direction: props.ubication.direction || "",
      coordinates: {
        lat: props.ubication.lat || -12.1245726,
        lng: props.ubication.lng || -77.0266616,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.ubication.lat !== prevState.coordinates.lat) {
      return {
        coordinates: {
          lat: nextProps.ubication.lat,
          lng: nextProps.ubication.lng,
        },
      };
    } else return null;
  }

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
  };

  formattedAddressForResultSearch = (place) => {
    const dataFormated = place[0].address_components.map((component) => {
      return {
        long_name: component.long_name,
        short_name: component.short_name,
        type: component.types[0],
      };
    });
    const route = dataFormated.find((item) => item.type === "route");
    const street_number = dataFormated.find(
      (item) => item.type === "street_number"
    );
    const locality = dataFormated.find((item) => item.type === "locality");
    const country = dataFormated.find((item) => item.type === "country");

    const addressLocation = `${
      !!street_number && !!street_number.long_name
        ? `${route.short_name} ${street_number && street_number.long_name}`
        : `${route.short_name}`
    }, ${locality.long_name}, ${country.long_name}`;

    if (street_number === undefined) {
      this.props.setBolNumber(false);
    } else {
      this.props.setBolNumber(true);
    }

    return addressLocation;
  };

  addPlace = (place) => {
    const addressLocation = this.formattedAddressForResultSearch(place);
    this.setState(
      {
        direction: addressLocation,
        coordinates: {
          lat: place[0].geometry.location.lat(),
          lng: place[0].geometry.location.lng(),
        },
        places: place,
      },
      () => {
        this.props.handleDirection({
          lat: place[0].geometry.location.lat(),
          lng: place[0].geometry.location.lng(),
          direction: addressLocation,
        });
      }
    );
  };

  mapClicked = (mapProps, map, event) => {
    this.setState({
      coordinates: {
        lat: mapProps.lat,
        lng: mapProps.lng,
      },
    });
    this.getGeocoding(mapProps.lat, mapProps.lng);
    this.props.handleDirection({
      direction: this.state.direction,
      lat: mapProps.lat,
      lng: mapProps.lng,
    });
  };

  getGeocoding = (latitude, longitude) => {
    const { mapApi, mapInstance } = this.state;

    const geocoder = new mapApi.Geocoder();

    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const geolocation = results[0];

          const addressLocation = this.formattedAddressForResultSearch(results);

          this.setState({
            // direction: geolocation.formatted_address,
            direction: addressLocation,
            coordinates: latlng,
          });

          this.props.handleDirection({
            direction: this.state.direction,
            lat: latlng.lat,
            lng: latlng.lng,
          });

          if (!geolocation.geometry) return;
          if (geolocation.geometry.viewport) {
            mapInstance.fitBounds(geolocation.geometry.viewport);
          } else {
            mapInstance.setCenter(geolocation.geometry.location);
            mapInstance.setZoom(17);
          }
        } else {
          // window.alert('No results found');
        }
      } else {
        // window.alert(`Geocoder failed due to: ${status}`);
      }
    });
  };

  activeGeologation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        this.getGeocoding(latitude, longitude);
      },
      (error) => {
        console.log(error);
      },
      {
        timeout: 30000,
        enableHighAccuracy: true,
      }
    );
  };

  render() {
    const { places, mapApiLoaded, mapInstance, mapApi } = this.state;
    return (
      <>
        {mapApiLoaded && (
          <SearchBox
            map={mapInstance}
            mapApi={mapApi}
            addplace={this.addPlace}
            direction={this.props.ubication.direction || ""}
            activeGeologation={this.activeGeologation}
          />
        )}
        <GoogleMap
          defaultZoom={15}
          defaultCenter={[
            this.state.coordinates.lat || defaultUbi[0],
            this.state.coordinates.lng || defaultUbi[1],
          ]}
          bootstrapURLKeys={{
            key: GOOGLE_MAPS_KEY,
            libraries: ["places", "geometry"],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
          onClick={(mapProps, map, event) =>
            this.mapClicked(mapProps, map, event)
          }
        >
          {/* {places.map((place) => ( */}
          <Marker
            // key={places[0].id}
            text="Location"
            lat={this.state.coordinates.lat}
            lng={this.state.coordinates.lng}
          />
          {/* ))} */}
        </GoogleMap>
      </>
    );
  }
}
export default React.memo(Searchbox);
