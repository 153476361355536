import React, { useCallback } from "react";

const ProductVariants = props => {
  const { variantGroups, selectVariant, selectedVariants } = props;

  const sanitizerVariantsFunc = useCallback(value => {
    return value.map(v => {
      return { value: v.uuid, label: v.display_value };
    });
  }, []);

  return (
    <div>
      {variantGroups.length > 0 &&
        variantGroups.map(group => {
          if (group.input_type === "select") {
            return (
              <div className="variant-select" key={group.uuid}>
                <label>{group.name}</label>
                <select
                  onChange={e => selectVariant(`${group.name}`, e.target.value)}
                  value={`${selectedVariants[group.name]}`}
                >
                  {sanitizerVariantsFunc(group.values).map(value => {
                    return (
                      <option key={value.value} value={value.value}>
                        {value.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          }

          if (group.input_type === "color") {
            return (
              <>
                <label>{group.name}</label>
                <ul className="variant-color">
                  {group.values.map(val => {
                    return (
                      <li key={val.value}>
                        <input
                          type="radio"
                          name="radio"
                          value={val.uuid}
                          onChange={() => {
                            selectVariant(`${group.name}`, val.uuid);
                          }}
                          checked={
                            val.uuid === `${selectedVariants[`${group.name}`]}`
                          }
                        />
                        <span
                          style={{ backgroundColor: val.value }}
                          className="radio__control"
                        />
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          }
        })}
    </div>
  );
};

export default ProductVariants;
