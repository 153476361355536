import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext
} from "react";
import { Helmet } from "react-helmet";
import SimpleReactValidator from "simple-react-validator";
import ReactPixel from "react-facebook-pixel";
import store from "store";
import Modal from "react-responsive-modal";
import "dayjs/locale/es";
import calendar from "dayjs/plugin/calendar";
import dayjs from "dayjs";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import EnterCard from "./enter-card";
import FooterOne from "../common/footers/footer-one";
import HeaderTwo from "../common/headers/header-two";
import Breadcrumb from "../common/breadcrumb";
import OutOfStockModal from "../common/out-stock-modal";

import Show4CardNumber from "../Show4CardNumbers";
import CheckoutCoupon from "./coupon";
import SearchUbiGoogle from "./search-ubi-google";

import BinsApi from "../../services/Bins";
import BusinessApi from "../../services/BusinessApi";
import UbicationApi from "../../services/UbicationApi";
import ProductsApi from "../../services/ProductsApi";
import { useMap } from "../../contexts/map";
import { useMercadoPago } from "../../contexts/mercado-pago";
import { useBusiness } from "../../contexts/business";
import { CartContext } from "../../contexts/cart";
import { useMaxTimeOfBasket } from "../../hooks/useMaxTimeOfBasket";
import { useOrderType } from "../../contexts/orderType";
import { delivery, ontable, pickup, slug_business } from "../../constants";
import {
  getBrandInfoEndpoint,
  getContactInfoEndpoint
} from "../../services/endpoints";
import {
  createOrderClient,
  makePaymentOrder
} from "../../services/order/endpoints";
import {
  getOptionsIdentification,
  getLast4Number,
  getFirst6Numbers,
  removeSpaces,
  getValueIDToMP,
  getObjectFromArrayMap,
  formatEstimatedTime,
  getErrorFromError,
  formatScheludePikup
} from "../../utils/global_functions";
import {
  DATA_VERIFY_WORKING_AREA,
  COUPON_APPLIED,
  CATALOGS_STORE,
  CONSULTING_CARD,
  LAST_ORDER_TYPE,
  USER_INFO_CART,
  BRAND_INFO,
  MAIN_STORE,
  CHANNEL_STORE
} from "../../constants/ActionTypes";

const binsApi = new BinsApi();
const businessApi = new BusinessApi();

dayjs().format();
dayjs.locale("es");
dayjs.extend(calendar);

const CheckOutPage = props => {
  const {
    userInfo,
    setUserInfo,
    getPrices,
    prices,
    productsCartArray,
    applicateBinInProducts,
    resetProductToLocalStorage,
    handleClearAll,
    updateCartForOutOfStock,
    setFreeShippingDelivery
  } = useContext(CartContext);

  const {
    setUbicationAndVerifyCoverageAndPrice,
    isInCoverage,
    methodsDelivery,
    setShowMap,
    ubication
  } = useMap();

  const {
    mainStore,
    channelStore,
    credentials,
    getCredentialsByStoreFunc,
    setChannelStore,
    setMainStore
  } = useBusiness();

  const { extractMaxTime, maxSeconds } = useMaxTimeOfBasket();

  const { orderType, setOrderType } = useOrderType();

  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [createOrderIsLoading, setCreateOrderIsLoading] = useState(false);
  const [scheduleSelected, setScheduleSelected] = useState({});
  const [consultingCard, setConsultingCard] = useState(false);
  const [schedules, setSchedules] = useState(
    methodsDelivery && methodsDelivery.schedules
  );
  const [isActivePM, setIsActivePM] = useState(false);
  const [openUbication, setOpenUbication] = useState(false);

  const [valueIdentification, setValueIdentification] = useState(() => {
    const id = store.get("@IDBI:identification");
    if (id) {
      return id;
    }
    return "dni";
  });

  const [isLoadingEnterCard, setIsLoadingEnterCard] = useState(false);
  const [payPayload, setPayPayload] = useState({ token: "" });

  const [coupon, setCoupon] = useState(() => {
    const c_a = store.get(COUPON_APPLIED);
    if (c_a) {
      return c_a;
    }
    return {};
  });

  const [cardData, setCardData] = useState({
    lastNumbers: 0,
    type: "",
    firstNumbers: ""
  });

  const [productsOutOfStock, setProductsOutOfStock] = useState([]);
  const [outOfStockModal, setOutOfStockModal] = useState(false);
  const [address, setAddress] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);

  // Pickup
  const [fullSchedules, setFullSchedules] = useState([]);
  const [currentDayWeek, setCurrentDayWeek] = useState(0);
  const [scheduleISO, setScheduleISO] = useState("");

  const [orderTypes, setOrderTypes] = useState([]);

  const { loadMercadoPago } = useMercadoPago();
  const ubicationApi = new UbicationApi();
  const apiProductService = new ProductsApi();

  const validator = new SimpleReactValidator();

  const getContactInfo = async () => {
    const res = await getContactInfoEndpoint();
    const { emails, phones } = res.data.data;

    setEmail(emails.length > 0 ? emails[0] : []);
    setPhone(phones.length > 0 ? phones[0] : []);
  };

  const getBrandInfo = async () => {
    const res = await getBrandInfoEndpoint();
    if (res) {
      const { address } = res.data.data.stores && res.data.data.stores[0].venue;
      setAddress(address && address.street);

      store.set(BRAND_INFO, {
        ...res.data.data,
        petition_date: new Date()
      });

      const responseMainStore = res.data.data.stores.find(
        brandItem => brandItem.is_main
      );

      const channelECommerce =
        responseMainStore &&
        responseMainStore.channels.find(item => item.slug === "e-commerce");

      if (channelECommerce) setChannelStore(channelECommerce);
      if (responseMainStore) setMainStore(responseMainStore);

      store.set(MAIN_STORE, responseMainStore);
      store.set(CHANNEL_STORE, channelECommerce);
    }
  };

  useEffect(() => {
    async function get() {
      setLoadingSchedules(true);
      store.set("@IDBI:schedule", null);
      const res = await ubicationApi.verifyCoverageAndPrice({
        business_slug: slug_business,
        channel: "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5",
        payload: ubication,
        reload: true
      });
      if (res) {
        setSchedules(res.price_delivery && res.price_delivery.schedules);
        setScheduleSelected(
          res.price_delivery && res.price_delivery.schedules[0]
        );
        store.set(
          "@IDBI:schedule",
          res.price_delivery && res.price_delivery.schedules[0]
        );
        setLoadingSchedules(false);
      }
    }
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ubication, slug_business]);

  useEffect(() => {
    getContactInfo();
    getBrandInfo();
    getCredentialsByStoreFunc();
  }, []);

  useEffect(() => {
    getPrices(orderType);
  }, [getPrices]);

  useEffect(() => {
    getPrices(orderType);
  }, [orderType]);

  useEffect(() => {
    if (credentials) {
      loadMercadoPago();
    }
  }, [credentials, loadMercadoPago]);

  useEffect(() => {
    const c_a = store.get(COUPON_APPLIED);
    if (c_a) {
      applicateBinInProducts(c_a);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function get() {
      if (payPayload.token) {
        // const c_a = store.get(COUPON_APPLIED);
        try {
          if ((coupon && !coupon.uuid) || consultingCard) {
            try {
              const response = await binsApi.binBBVAforDolce({
                type: "bin",
                bin: getFirst6Numbers(cardData.firstNumbers)
              });
              if (response) {
                applicateBinInProducts(response);
                setCoupon(response);
                // store.set(COUPON_APPLIED, response);
                store.set(CONSULTING_CARD, true);
                setConsultingCard(true);
              }
            } catch (error) {
              setCoupon();
              resetProductToLocalStorage();
              setConsultingCard(false);
              store.remove(CONSULTING_CARD);
            }
          }
        } catch (error) {
          resetProductToLocalStorage();
          setCoupon();
          throw new Error("Not search coupon");
        } finally {
          setIsLoadingEnterCard(false);
          setCreateOrderIsLoading(false);
          setIsActivePM(false);
        }
      }
    }
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData.firstNumbers, payPayload.token]);

  const handleIdentification = useCallback(value => {
    setValueIdentification(value);
  }, []);

  const handleChangeSelect = useCallback(
    e => {
      handleIdentification(e.target.value);
      store.set("@IDBI:identification", e.target.value);
    },
    [handleIdentification]
  );

  const handleSelectedSchedule = useCallback(
    e => {
      const selected = schedules.find((element, i) => {
        if (i === Number(e.target.value)) {
          return {
            starts_at: element.starts_at,
            ends_at: element.ends_at,
            hash: element.hash
          };
        }
      });

      if (selected) {
        store.set("@IDBI:schedule", selected);
        setScheduleSelected(selected);
      }
    },
    [schedules]
  );

  const handleChangeOrderType = e => {
    const { value } = e.target;
    setOrderType(value);
    store.set(LAST_ORDER_TYPE, value);
  };

  const handleSetUserInfo = v => {
    setUserInfo(v.target.name, v.target.value);
  };

  const handleSetDirection = useCallback(
    direction => {
      setLoadingSchedules(true);
      setUbicationAndVerifyCoverageAndPrice({
        store_id: mainStore && mainStore.shipping_provider_uuid,
        locate: direction
      });
    },
    [
      setUbicationAndVerifyCoverageAndPrice,
      mainStore && mainStore.shipping_provider_uuid
    ]
  );

  const handleSetUbi = useCallback(
    direction => {
      setShowMap(false);
      handleSetDirection(direction);
      setOpenUbication(false);
    },
    [setShowMap, handleSetDirection]
  );

  const selectDataTypeIdenfification = useMemo(() => {
    return getOptionsIdentification();
  }, []);

  const createOrderClientFunc = async allProducts => {
    setCreateOrderIsLoading(true);
    const data = store.get(DATA_VERIFY_WORKING_AREA);
    const sch = store.get("@IDBI:schedule");
    const catalogs = store.get(CATALOGS_STORE);

    const payload = {
      details: {
        delivery_method: orderType,
        catalog: {
          uuid: catalogs[0].uuid
        },
        channel: {
          uuid: "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5"
        }
      },
      store: {
        uuid: mainStore && mainStore.uuid
      },
      customer: {
        forename: userInfo.names,
        surname: userInfo.surnames,
        email: userInfo.email,
        phone: userInfo.phone,
        identification: {
          type: valueIdentification,
          value: userInfo.identificationValue
        }
      },
      products: allProducts,
      type: {
        uuid: orderType === "delivery" ? delivery : pickup
      },
      extras: {
        pickup_schedule: scheduleISO
      }
    };

    if (orderType === "delivery") {
      payload.delivery = {
        address: {
          latitude: ubication && ubication.lat,
          longitude: ubication && ubication.lng,
          label: ubication && ubication.direction,
          instructions: ubication && ubication.instructions,
          city: data.city,
          country: data.country,
          department: data.department,
          district: data.district
        },
        contact: {
          name: `${userInfo.names} ${userInfo.surnames}`,
          email: userInfo.email,
          phone: userInfo.phone,
          identification: {
            type: valueIdentification,
            value: userInfo.identificationValue
          }
        },
        schedule: {
          hash: sch.hash
        },
        provider: {
          uuid: mainStore && mainStore.shipping_provider_uuid
        },
        working_area: {
          uuid: data.working_area
        },
        shipping_method: {
          uuid: data.shipping_method
        }
      };
    } else {
      payload.tags = [{ uuid: "41e5f2f6-7afc-4695-827e-ceb620c12cc8" }];
    }

    if (coupon && coupon.uuid) {
      payload.coupon = {
        uuid: coupon.uuid
      };
    }
    try {
      const r = await createOrderClient(
        payload,
        "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5"
      );
      return { order: r.headers.order, uuid: r.headers.uuid };
    } catch (e) {
      const err = getErrorFromError(e);
      toast.error(`${err.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setCreateOrderIsLoading(false);
    }
  };

  const makePaymentOrderFunc = async (
    orderCode,
    orderUuid,
    paymentPayload,
    products
  ) => {
    setCreateOrderIsLoading(true);
    const sch = store.get("@IDBI:schedule");

    const payload = {
      order: {
        uuid: orderUuid
      },
      client: {
        emails: [userInfo.email]
      },
      gateway: {
        identifier: "mercado-pago",
        token: paymentPayload.token,
        method: {
          name: paymentPayload.payment_method_id
        },
        issuer: {
          id: paymentPayload.issuer_id
        },
        // installments: paymentPayload.installments
        installments: 1
      },
      payment: {
        type: "total"
      }
    };
    try {
      const r = await makePaymentOrder(payload);
      if (r.status === 201 || r.status === 200) {
        const newProductsGTM = products.map(prod => {
          return {
            ...prod,
            id: prod.uuid,
            price:
              prod.prices && prod.prices.data[0] && prod.prices.data[0].price,
            brand: prod.vendor,
            category: prod.category.name
          };
        });

        window.dataLayer.push({
          event: "transaction",
          label: `La orden ${orderCode} ha sido completada`,
          ecommerce: {
            purchase: {
              actionField: {
                id: orderCode,
                affiliation: "Pasiflora",
                revenue: Number(prices.total - prices.discount),
                shipping: prices.delivery,
                coupon: coupon ? coupon.code : null
              },
              products: newProductsGTM
            }
          }
        });

        ReactPixel.track("Purchase", {
          value: Number(prices.total - prices.discount),
          currency: "PEN"
        });

        handleClearAll();
        props.history.push(`/orden-finalizada/${orderCode}`);
      }
    } catch (e) {
      const err = getErrorFromError(e);
      toast.error(`${err.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } finally {
      setCreateOrderIsLoading(false);
    }
  };

  //PAGO
  const sdkResponseHandler = (cardNumber, status, response) => {
    const bin = cardNumber.substring(0, 6);
    if (status === 200) {
      const func = (s, r) => {
        if (s === 200) {
          const res = r[0];
          const payload = {
            payment_method_id: res.payment_method_id,
            issuer_id: res.issuer.id,
            token: response.id
          };
          setPayPayload(payload);
        }
      };
      window.Mercadopago.getInstallments(
        {
          bin,
          amount: parseFloat(`${1}`)
        },
        func
      );
      window.dataLayer.push({
        event: "addPaymentInfo",
        label: `Se ha ingresado la información de pago.`
      });
      ReactPixel.track("AddPaymentInfo", {
        value: Number(prices.total - prices.discount),
        currency: "PEN"
      });
    } else {
      toast.error(`${response.cause[0].description}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setIsLoadingEnterCard(false);
      setCreateOrderIsLoading(false);
    }
  };

  const onTokenizeCard = async payload => {
    setIsLoadingEnterCard(true);
    setCardData({
      type: payload.cardType,
      lastNumbers: getLast4Number(payload.cardNumber),
      firstNumbers: getFirst6Numbers(payload.cardNumber)
    });

    const newCN = removeSpaces(payload.cardNumber);
    const date = payload.expiryDate.split("/");

    const cardToken = await window.mercadoPagoIntance.createCardToken({
      cardNumber: newCN,
      cardholderName: payload.cardName,
      cardExpirationMonth: date[0],
      cardExpirationYear: date[1],
      securityCode: payload.cardCVV,
      identificationType: getValueIDToMP(valueIdentification),
      identificationNumber: userInfo.identificationValue
        ? userInfo.identificationValue
        : ""
    })

    const paymentMethods = await window.mercadoPagoIntance.getPaymentMethods({ bin: newCN.substr(0, 6) })

    setPayPayload({
      token: cardToken.id,
      payment_method_id: paymentMethods.results[0].id,
      issuer_id: paymentMethods.results[0].issuer.id.toString()
    });
  };

  const handleSendOrder = async () => {
    const allProducts = getObjectFromArrayMap(productsCartArray);
    const productVariants = allProducts.filter(v => v.has_variants);
    const productNotVariants = allProducts.filter(
      v => Boolean(v.has_variants) === false
    );

    const match = [];
    productVariants.forEach(variant => {
      const selectedVariantsUuid = Object.values(variant.selected_variants).filter(
        val => val !== "" && val
      );

      const variantProduct = variant.variants && variant.variants.filter(v => {
        const d = v.variant_groups.filter(
          vv => selectedVariantsUuid.includes(vv.value.uuid) === true
        );
        return d && d.length > 0 && selectedVariantsUuid.length === d.length && variant;
      });

      Object.assign(variantProduct[0], {
        quantity: variant.quantity
      });

      match.push(...variantProduct);
    });
    const newAllProducts = [...match, ...productNotVariants];

    const stockval = await getAllStock();
    if (stockval) return;

    const res = await createOrderClientFunc(newAllProducts);
    if (res.order) {
      makePaymentOrderFunc(res.order, res.uuid, payPayload, newAllProducts);
    }
  };

  const getAllStock = async () => {
    const requests = [];

    productsCartArray.forEach(async (product, index) => {
      if (product.inventory_policy === "deny") {
        requests.push(
          apiProductService.getStock({
            product_uuid:
              product.has_variants === true
                ? product.uuidVariant
                : product.uuid,
            slug_business,
            indexCart: index
          })
        );
      }
    });

    const result = await Promise.all(requests);
    const outOfStock = result
      .map(valueStock => {
        const productsWithUuid = productsCartArray.filter(p =>
          p.has_variants
            ? valueStock.uuid === p.uuidVariant
            : valueStock.uuid === p.uuid
        );

        const sumProducts = productsWithUuid.reduce(
          (acc, item) => Number(item.quantity) + acc,
          0
        );

        const productSelect = productsCartArray.find(
          (p, index) => index === valueStock.indexCart
        );

        if (
          productsWithUuid.length === 1 &&
          (Number(valueStock.stock) !== -1 || Number(valueStock.stock) > 0) &&
          productSelect.quantity > Number(valueStock.stock) &&
          productSelect.inventory_policy === "deny"
        ) {
          return {
            ...productSelect,
            stock: valueStock.stock,
            indexCart: valueStock.indexCart
          };
        } else if (
          productsWithUuid.length > 1 &&
          (Number(valueStock.stock) !== -1 || Number(valueStock.stock) > 0) &&
          sumProducts > Number(valueStock.stock) &&
          productSelect.inventory_policy === "deny"
        ) {
          return {
            ...productSelect,
            stock: valueStock.stock,
            indexCart: valueStock.indexCart
          };
        }
        return null;
      })
      .filter(value => value !== null);

    const sanitizerDuplicate = outOfStock.reduce((acc, current) => {
      const x = acc.find(item =>
        item.has_variants
          ? item.uuidVariant === current.uuidVariant
          : item.uuid === current.uuid
      );
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);

    if (outOfStock.length > 0) {
      setProductsOutOfStock(sanitizerDuplicate);
      setOutOfStockModal(true);
    }
  };

  const handleAcceptChangeStockToCart = useCallback(() => {
    updateCartForOutOfStock(productsOutOfStock);
    setOutOfStockModal(false);
    setProductsOutOfStock([]);
  }, [productsOutOfStock, setOutOfStockModal, updateCartForOutOfStock]);

  useEffect(() => {
    window.dataLayer.push({
      event: "checkout",
      label: `Se ha iniciado el proceso de pago.`,
      ecommerce: {
        checkout: {
          products: productsCartArray.map((prod, index) => {
            return {
              id: prod.uuid,
              name: prod.name,
              brand: prod.vendor,
              category: prod.category && prod.category.name,
              position: index,
              quantity: prod.quantity
            };
          })
        }
      }
    });

    ReactPixel.track("InitiateCheckout", {
      value: Number(prices.total - prices.discount),
      currency: "PEN",
      contents: productsCartArray.map(product => {
        return {
          id: product.uuid,
          ...product
        };
      }),
      content_ids: productsCartArray.map(product => product.uuid),
      content_type: "product",
      num_items: productsCartArray.length
    });
    getAllStock();
    extractMaxTime(productsCartArray);
    setCurrentDayWeek(dayjs().day());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createOrderIsLoading) {
      document.querySelector(".loader-wrapper").style =
        "display: block; background-color: rgba(255,255,255,.3);";
    } else {
      document.querySelector(".loader-wrapper").style = "display: none";
    }
  }, [createOrderIsLoading]);

  useEffect(() => {
    async function getSchedules() {
      const response = await businessApi.getSchedulesOfPickupForSlug(
        slug_business
      );
      setFullSchedules(response);
    }
    getSchedules();
  }, []);

  useEffect(() => {
    async function getOrderTypes() {
      if (mainStore && mainStore.uuid) {
        const response = await businessApi.getOrderTypesForUuid(mainStore.uuid);
        const filterWithoutOnTable = response.filter(
          res => res.uuid !== ontable
        );
        setOrderTypes(filterWithoutOnTable);
      }
    }
    getOrderTypes();
  }, [mainStore]);

  const updateCurrentDay = useCallback(() => {
    if (currentDayWeek > 6) {
      setCurrentDayWeek(0);
    } else {
      setCurrentDayWeek(state => state + 1);
    }
  }, [currentDayWeek]);

  const calculatePickupTime = useMemo(() => {
    const currentDay = dayjs().day();
    const currentHour = dayjs().hour();
    const currentMinutes = dayjs().minute();

    if (fullSchedules.length > 0) {
      const currentSchedule = fullSchedules.find(
        schedule => schedule.day_of_week === currentDayWeek
      );

      if (!currentSchedule && currentSchedule === undefined) {
        updateCurrentDay();
        return;
      }

      if (currentSchedule && !!currentSchedule.day_of_week) {
        const starts_at = currentSchedule.starts_at.split(":") || [];
        const starts_at_hour = Number(starts_at[0]);
        const ends_at = currentSchedule.ends_at.split(":") || [];
        const ends_at_hour = Number(ends_at[0]);
        const ends_at_minutes = Number(ends_at[1]);

        if (
          currentHour >= ends_at_hour &&
          currentMinutes >= ends_at_minutes &&
          currentDay === currentDayWeek
        ) {
          updateCurrentDay();
          return;
        }

        const currentHourInSeconds = currentHour * 3600;
        const currentMinutesInSeconds = currentMinutes * 60;

        const sumCurrentTimeAndMaxItemTime =
          currentHourInSeconds + currentMinutesInSeconds + (maxSeconds || 0);

        const hour = Math.floor(sumCurrentTimeAndMaxItemTime / 3600);
        const minutes = Math.floor(
          (sumCurrentTimeAndMaxItemTime - hour * 3600) / 60
        );
        const s = Math.floor(
          sumCurrentTimeAndMaxItemTime - (hour * 3600 + minutes * 60)
        );

        if (
          hour > starts_at_hour &&
          hour < ends_at_hour &&
          currentDay === currentDayWeek
        ) {
          setScheduleISO(
            dayjs()
              .day(currentDayWeek)
              .hour(hour)
              .minute(minutes)
              .toISOString()
          );
          return formatScheludePikup({
            start: dayjs()
              .day(currentDayWeek)
              .hour(hour)
              .minute(minutes)
              .toDate()
          });
        }

        setScheduleISO(
          dayjs()
            .day(currentDayWeek)
            .hour(starts_at_hour)
            .minute(minutes)
            .toISOString()
        );
        return formatScheludePikup({
          start: dayjs()
            .day(currentDayWeek)
            .hour(starts_at_hour)
            .minute(minutes)
            .toDate()
        });
      }
    }
  }, [maxSeconds, currentDayWeek, fullSchedules, updateCurrentDay]);

  const verificationInfoClientToAddCard = useCallback(() => {
    const info = store.get(USER_INFO_CART);
    if (!info) {
      toast.error("Por favor complete todos los Datos de Facturación");
      return;
    }
    if (
      !info.names ||
      !info.surnames ||
      !info.identificationValue ||
      !info.phone ||
      !info.email
    ) {
      toast.error("Por favor complete todos los Datos de Facturación");
      return;
    }

    setIsActivePM(true);
  }, []);

  //FREE DELIVERY
  const freeDelivery = useMemo(() => {
    return Number(channelStore.min_order_free_delivery).toFixed(2);
  }, [channelStore.min_order_free_delivery]);

  const calculatePriceTotal = useMemo(() => {
    const subTotal = Number(prices.subtotal) - Number(prices.discount);
    if (subTotal >= Number(freeDelivery)) return true;
    return false;
  }, [freeDelivery, prices]);

  useEffect(() => {
    if (calculatePriceTotal && !!channelStore.min_order_free_delivery) {
      setFreeShippingDelivery(true);
    } else {
      setFreeShippingDelivery(false);
    }
  }, [
    calculatePriceTotal,
    channelStore.min_order_free_delivery,
    setFreeShippingDelivery
  ]);

  const deliverySchedulesText = useCallback(() => {
    if (!ubication) {
      return (
        <span style={{ color: "red" }}>
          <i
            className="fa fa-exclamation-triangle"
            style={{ marginRight: "5px" }}
          />
          Ingresa una dirección para el envío
        </span>
      );
    } else if (ubication && loadingSchedules) {
      return (
        <span className="loading-schedules">
          <i className="fa fa-spinner" />
          Verificando cobertura y calculando días de entrega
        </span>
      );
    } else if (ubication && !isInCoverage && !loadingSchedules) {
      return (
        <span style={{ color: "red" }}>
          <i
            className="fa fa-exclamation-triangle"
            style={{ marginRight: "5px" }}
          />
          Te encuentras fuera de la cobertura
        </span>
      );
    } else if (ubication && isInCoverage && !loadingSchedules) {
      return (
        <select onChange={handleSelectedSchedule}>
          {schedules &&
            schedules.length > 0 &&
            schedules.map(({ starts_at, ends_at }, i) => (
              <option
                key={i}
                value={i}
                selected={scheduleSelected.starts_at === starts_at}
              >
                {formatEstimatedTime({
                  start: starts_at,
                  end: ends_at
                })}
              </option>
            ))}
        </select>
      );
    }
  }, [
    ubication,
    isInCoverage,
    loadingSchedules,
    schedules,
    handleSelectedSchedule
  ]);

  return (
    <div>
      <Helmet>
        <title>Pasiflora | Checkout</title>
      </Helmet>
      <HeaderTwo logoName={"layout3/logo.png"} />
      <div>
        <Breadcrumb title={"Checkout"} />
        <section className="section-b-space">
          <div className="container">
            <div className="checkout-page">
              <div className="checkout-form">
                <form>
                  <div className="checkout row">
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                      <label>Selecciona el tipo de orden</label>
                      <select
                        onChange={handleChangeOrderType}
                        value={orderType}
                      >
                        {orderTypes.map(orderType => (
                          <option key={orderType.uuid} value={orderType.slug}>
                            {orderType.slug === "delivery"
                              ? "Envio"
                              : "Recojo en tienda"}
                          </option>
                        ))}
                      </select>
                      {orderType === "delivery" ? (
                        <>
                          <div className="checkout-title mt-3">
                            <h3>Datos de Envío</h3>
                          </div>
                          <div className="row check-out mb-5">
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                              <input
                                type="text"
                                onFocus={() => setOpenUbication(true)}
                                placeholder="Ingresa tu dirección"
                                value={
                                  ubication
                                    ? `${ubication.direction
                                    } ${ubication.instructions && "|"} ${ubication.instructions
                                    }`
                                    : ""
                                }
                              />
                              <Modal
                                open={openUbication}
                                onClose={() => setOpenUbication(false)}
                                className="modal fade"
                              >
                                <SearchUbiGoogle
                                  title="Seleccione su dirección"
                                  handleSelectDirection={handleSetUbi}
                                  ubication={ubication}
                                />
                              </Modal>
                            </div>
                          </div>
                          <div className="row check-out mb-5">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <h4>Entrega estimada</h4>
                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                              {deliverySchedulesText()}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="mt-2" style={{ lineHeight: "18px" }}>
                            Los recojos se podrán hacer previa coordinación por
                            Whatsapp {phone.phone} o a nuestro mail{" "}
                            {email.email}.
                          </p>
                          <dl>
                            <dt>Horario de Recojo:</dt>
                            <dd>{calculatePickupTime}</dd>
                            <dt>Dirección de Recojo:</dt>
                            <dd>{address}</dd>
                          </dl>
                        </>
                      )}
                      <div className="checkout-title">
                        <h3>Datos de Facturación</h3>
                      </div>
                      <div className="row check-out">
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                          <div className="field-label">Nombre</div>
                          <input
                            type="text"
                            name="names"
                            value={userInfo && userInfo.names}
                            onChange={handleSetUserInfo}
                          />
                          {validator.message(
                            "names",
                            userInfo && userInfo.names,
                            "required|alpha"
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                          <div className="field-label">Apellido</div>
                          <input
                            type="text"
                            name="surnames"
                            value={userInfo && userInfo.surnames}
                            onChange={handleSetUserInfo}
                          />
                          {validator.message(
                            "surnames",
                            userInfo && userInfo.surnames,
                            "required|alpha"
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                          <div className="field-label">
                            Tipo de identificación
                          </div>
                          <select
                            defaultValue="dni"
                            onChange={handleChangeSelect}
                          >
                            {selectDataTypeIdenfification &&
                              selectDataTypeIdenfification.map(
                                identification => (
                                  <option
                                    key={identification.value}
                                    value={
                                      identification && identification.value
                                    }
                                    defaultValue="dni"
                                    selected={
                                      identification.value ===
                                      valueIdentification
                                    }
                                  >
                                    {identification.label}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                          <div className="field-label">Identificación</div>
                          <input
                            type="text"
                            name="identificationValue"
                            value={userInfo && userInfo.identificationValue}
                            onChange={handleSetUserInfo}
                          />
                          {validator.message(
                            "identificationValue",
                            userInfo && userInfo.identificationValue,
                            "required"
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                          <div className="field-label">Celular</div>
                          <input
                            type="text"
                            name="phone"
                            value={userInfo && userInfo.phone}
                            onChange={handleSetUserInfo}
                          />
                          {validator.message(
                            "phone",
                            userInfo && userInfo.phone,
                            "required|phone"
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                          <div className="field-label">Email</div>
                          <input
                            type="text"
                            name="email"
                            value={userInfo && userInfo.email}
                            onChange={handleSetUserInfo}
                          />
                          {validator.message(
                            "email",
                            userInfo && userInfo.email,
                            "required|email"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                      <div className="checkout-details">
                        <div className="order-box">
                          <ul className="sub-total">
                            <li className="payment-method-btn">
                              Método de pago
                              <span>
                                <div
                                  className="btn btn-solid white-btn"
                                  onClick={verificationInfoClientToAddCard}
                                // () => {
                                //   setIsActivePM(true);
                                // }
                                >
                                  Agregar Tarjeta
                                  <i className="fa fa-chevron-right" />
                                </div>
                              </span>
                            </li>
                            {cardData && cardData.type && payPayload.token && (
                              <div className="stripe-section mb-4">
                                <div>
                                  <h5 className="checkout_class">
                                    MercadoPago
                                  </h5>
                                  <Show4CardNumber
                                    numbers={cardData.lastNumbers}
                                    cardType={cardData.type}
                                  />
                                </div>
                              </div>
                            )}
                          </ul>
                          <ul className="sub-total">
                            <li
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              Cupón
                              <CheckoutCoupon
                                coupon={coupon}
                                setCoupon={setCoupon}
                                applicateBinInProducts={applicateBinInProducts}
                                resetProductToLocalStorage={
                                  resetProductToLocalStorage
                                }
                              />
                            </li>
                          </ul>
                          <table className="qty">
                            <tbody>
                              <tr>
                                <th>Producto</th>
                                <th style={{ textAlign: "center" }}>Cant</th>
                                <th />
                                <th style={{ textAlign: "right" }}>Total</th>
                              </tr>
                              {productsCartArray.map(item => {
                                return (
                                  <tr key={item.uuid}>
                                    <td className="td-name">
                                      {item.vendor && (
                                        <span>{item.vendor}</span>
                                      )}
                                      {item.name}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.quantity}
                                    </td>
                                    <td>
                                      {item.prices &&
                                        item.prices.data[0] &&
                                        item.prices.data[0].compare_to > 0 && (
                                          <del>
                                            {
                                              item.prices.data[0].currency
                                                .symbol
                                            }
                                            {Number(
                                              item.prices.data[0].compare_to
                                            ).toFixed(2)}
                                          </del>
                                        )}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {item.prices &&
                                        item.prices.data[0] &&
                                        item.prices.data[0].currency.symbol}
                                      {item.prices &&
                                        item.prices.data[0] &&
                                        (
                                          item.prices.data[0].price *
                                          item.quantity
                                        ).toFixed(2)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <ul className="sub-total">
                            <li>
                              Subtotal
                              <span className="count">
                                S/
                                {prices.subtotal &&
                                  Number(prices.subtotal).toFixed(2)}
                              </span>
                            </li>
                          </ul>

                          {isInCoverage && orderType === "delivery" && (
                            <ul className="sub-total">
                              <li>
                                Delivery
                                <span className="count">
                                  S/
                                  {prices.delivery &&
                                    Number(prices.delivery).toFixed(2)}
                                  {channelStore.min_order_free_delivery &&
                                    calculatePriceTotal && (
                                      <div className="free-delivery">
                                        ¡DELIVERY GRATIS!
                                      </div>
                                    )}
                                </span>
                              </li>
                            </ul>
                          )}

                          {prices.discount > 0 && (
                            <ul className="sub-total">
                              <li>
                                Descuento
                                <span className="count">
                                  - S/
                                  {Number(prices.discount).toFixed(2)}
                                </span>
                              </li>
                            </ul>
                          )}

                          <ul className="total">
                            <li>
                              Total
                              <span className="count">
                                S/
                                {prices && Number(prices.total).toFixed(2)}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="payment-box">
                          <p className="checkout-note">
                            Al realizar tu compra, aceptas nuestros{" "}
                            <Link to="/terminos-y-condiciones">
                              Términos y Condiciones.
                            </Link>
                          </p>
                          {prices.total > 0 && (
                            <div className="text-right">
                              <button
                                type="button"
                                className="btn-solid btn"
                                onClick={handleSendOrder}
                                disabled={
                                  orderType === "delivery"
                                    ? createOrderIsLoading ||
                                    !payPayload.token ||
                                    !isInCoverage
                                    : createOrderIsLoading || !payPayload.token
                                }
                              >
                                Finalizar orden
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal
                    open={isActivePM}
                    onClose={() => setIsActivePM(false)}
                    className="modal fade"
                    center
                  >
                    <EnterCard
                      isMobile
                      onValidCardAvailable={onTokenizeCard}
                      isLoading={isLoadingEnterCard}
                    />
                  </Modal>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <OutOfStockModal
        openModal={outOfStockModal}
        setOpenModal={setOutOfStockModal}
        handleAcceptChangeStockToCart={handleAcceptChangeStockToCart}
        productsOutOfStock={productsOutOfStock}
      />
      <FooterOne logoName={"layout3/logo.png"} />
    </div>
  );
};

export default withRouter(CheckOutPage);
