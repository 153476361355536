import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import HeaderTwo from "../common/headers/header-two";
import FooterOne from "../common/footers/footer-one";
import Breadcrumb from "../common/breadcrumb";
import {
  svgComercio,
  svgNatural,
  svgCrueltyFree,
  svgCompra,
} from "../../services/svgIcons";

const AboutUs = () => {
  useEffect(() => {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Pasiflora | Nosotros</title>
      </Helmet>
      <HeaderTwo logoName={"layout3/logo.png"} />
      <Breadcrumb title="Nosotros" />
      <section className="about-page  section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-section">
                <img
                  src={`${
                    process.env.PUBLIC_URL
                  }/assets/images/about/about%20us.jpg`}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div id="quienes-somos">
                <h4>Quiénes somos</h4>
                <p>
                  Como tienda, Pasiflora es un espacio para el cambio, que busca
                  acoger en armonía a todas las personas que se están adaptando
                  a una nueva vida, más preocupada por la naturaleza. La casa de
                  una nueva comunidad de marcas comprometidas y consumidores
                  conscientes, donde podrás encontrar los mejores exponentes de
                  la cosmética ecológica peruana. Como marca, Pasiflora se ha
                  comprometido en ser impulsora del cambio, compartir
                  información y cultura, visibilizar a los productores orgánicos
                  y generar empleo e impulsar el desarrollo sostenible a través
                  del comercio justo.
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <div id="nuestra-historia">
                <h4>Nuestra historia</h4>
                <p>
                  La historia de Pasilfora es la historia de un viaje.
                  Concretamente, del viaje de redescubrimiento personal de
                  nuestra fundadora, Paloma. El punto de partida fue su propia
                  curiosidad e inconformismo. Durante sus primeros años en la
                  universidad, Paloma se veía todo el tiempo cuestionando
                  aquello que los demás asumían como establecido. Eso la llevó a
                  cambiar aspectos fundamentales de su vida. Poco a poco
                  transformó su alimentación y sus costumbres. Viajó mucho,
                  cambió de carrera y empezó a trabajar en proyectos
                  medioambientales y de turismo sostenible. Así llegó a aprender
                  conceptos como comercio justo y sostenibilidad, y así también
                  se encontró con el mundo de la producción orgánica en el Perú.
                  Conoció a Dina Soldevilla, fundadora de La Calandria y la
                  Feria Ecológica de Barranco, y comenzó a trabajar en ambos
                  proyectos. Un tiempo después, ambas desarrollaron la primera
                  feria de cosmética ecológica del Perú, llamada Un Día de
                  Cosmética Ecológica. La feria fue un éxito, llegó a convocar
                  más de 25 marcas y comprobó las sospechas de Paloma: había
                  todo un mundo nuevo gestándose.{" "}
                </p>
                <p>
                  Después de 6 ediciones, Paloma decidió abrir un pequeño
                  espacio dentro de La Calandria, dedicado exclusivamente a la
                  cosmética ecológica peruana y lo llamó igual que la feria. Un
                  Día de Cosmética Ecológica se convirtió también en la primera
                  tienda especializada en cosmética ecológica peruana del país.
                  Luego de dos años, Paloma decidió independizar la tienda de la
                  feria, que seguirá llamándose Un Día de Cosmética Ecológica
                  (proyecto que ha prometido retomar). La tienda fue rebautizada
                  como Pasiflora y lanzó este e-comerce para albergar a más
                  marcas y llegar a más clientes.{" "}
                </p>
                <p>
                  Los tiempos actuales exigen cambios constantes, pero tanto
                  Paloma como Pasiflora están acostumbradas a ello. Ellas saben
                  que el viaje en realidad empieza.
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <div id="vision-y-mision">
                <h4>Visión y misión</h4>
                <div className="row">
                  <div className="col-lg-6">
                    <h5>Visión</h5>
                    <p>
                      Convertirnos en el espacio más completo de cosmética
                      natural peruana, donde los clientes conozcan y valoren
                      todo lo que hay detrás de cada una de las marcas que
                      promovemos. Ser parte de la revolución, del cambio de
                      paradigma con respecto al concepto de belleza, de consumo
                      y de moda. Poner en primer lugar al productor, la cultura
                      y las prácticas ancestrales, dando paso a una conciencia
                      integral de respeto y sostenibilidad.
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <h5>Misión</h5>
                    <p>
                      Ofrecemos una experiencia donde se comparte cultura e
                      información, además de nuestra variada oferta de productos
                      de cosmética ecológica, en un espacio seguro, sin
                      vergüenzas ni tabúes. En Pasiflora el cliente puede
                      aprender y enseñar, escuchar y ser escuchado. Puede dejar
                      hablar a su cuerpo y conectarse con la naturaleza de su
                      ser. Puede hacer una selección más conciente de productos
                      de origen natural, saludables, sostenibles y que tienen un
                      efecto positivo en el mundo que lo rodea. Puede encontrar
                      la mejor selección de marcas peruanas responsables que
                      comparte de manera transparente sus procesos de
                      producción, insumos y orígenes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div id="nuestro-concepto">
                <h4>Nuestro concepto</h4>
                <h5>"Revolución natural"</h5>
                <p>
                  El cambio necesita una revolución y la revolución un
                  movimiento. Nosotrxs queremos ser parte de ese movimiento.
                  Detrás de las marcas o clientes, encontramos personas con el
                  mismo sueño: "Queremos cambiar las cosas". Lo estamos
                  haciendo. Estamos cambiando el concepto de consumo, de moda,
                  de tendencias, de hacer empresa, de éxito, de desarrollo, de
                  comunidad. Y con eso cambiamos la economía y cambiamos el
                  mundo.
                </p>
                <p>
                  Todos somos parte de esta revolución. Desde el agricultor
                  hasta el influencer. Por mucho tiempo hemos sido reductos
                  aislados, cada uno combatiendo en su trinchera. Es momento de
                  unirnos, sumar esfuerzos, confirmar que somos un movimiento y
                  que no estamos solos en este camino.
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <div id="nuestra-filosofia">
                <h4>Nuestra filosofía</h4>
                <p>
                  Como marca buscamos ser transparentes y decimos sin temor (y
                  en voz alta) lo que pensamos y en lo que creemos:
                </p>
                <ul>
                  <li>
                    Creemos en el cambio. En el de cada persona y en el del
                    mundo entero. Por eso somos una marca comprometida con la
                    preservación y protección del medio ambiente.
                  </li>
                  <li>
                    Pensamos que es urgente unir y reunir. Por eso impulsamos
                    todo lo que sirva para generar comunidad.
                  </li>
                  <li>
                    Valoramos los saberes ancestrales y cultura popular que la
                    rodea.
                  </li>
                  <li>
                    Compartimos lo aprendido. Buscamos educar con nuestros
                    conocimientos y aprender al mismo tiempo de las marcas y el
                    público.
                  </li>
                  <li>Proponemos revalorar el concepto de belleza</li>
                  <li>
                    Escuchamos al consumidor. Confiamos en su instinto y en el
                    poder de sus decisiones
                  </li>
                </ul>
                <p>
                  En nuestra empresa y el ámbito laboral, buscamos respetar los
                  mismos valores que respetamos como personas:
                </p>
                <ul>
                  <li>
                    <strong>Equidad:</strong>Que todos reciban lo justo por su
                    esfuerzo. Que nadie crezca en base a las limitaciones de los
                    demás.
                  </li>
                  <li>
                    <strong>Ecología:</strong> Buscar y respetar el equilibrio
                    natural en todo lo que hacemos. Proteger la naturaleza y
                    promover su cuidado.
                  </li>
                  <li>
                    <strong>Solidaridad:</strong> Escucharnos, ayudarnos,
                    protegernos. Entendernos como una comunidad.
                  </li>
                  <li>
                    <strong>Honestidad:</strong> Ser siempre transparentes.
                    Compartir lo que sabemos y lo que hemos aprendido.
                  </li>
                  <li>
                    <strong>Responsabilidad:</strong> Todas nuestras acciones
                    generan reacciones y debemos entendernos como parte activa
                    de lo que sucede a nuestro alrededor. Nuestra conciencia
                    social y medioambiental provienen de ese pensamiento
                    responsable.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container about-cls section-b-space">
        <section className="service border-section small-section ">
          <div className="row service">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgNatural }} />
              <h5>Natural</h5>
              <p>
                Insumos naturales, <br />
                productos eco-amigables.
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgCrueltyFree }} />
              <h5>Cruelty Free</h5>
              <p>No se testea en animales.</p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgCompra }} />
              <h5>Compra Local</h5>
              <p>
                Apoya emprendimientos <br />y mano de obra peruanos.
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 service-block1">
              <div
                dangerouslySetInnerHTML={{
                  __html: svgComercio,
                }}
              />
              <h5>Comercio Justo</h5>
              <p>
                Beneficia a los agricultores, productores y sus poblaciones.
              </p>
            </div>
          </div>
        </section>
      </div>
      <FooterOne logoName={"layout3/logo.png"} />
    </div>
  );
};

export default AboutUs;
