import React from "react";
import styled from "styled-components";

import visa from "../assets/images/visa.png";
import mastercard from "../assets/images/mastercard.png";
import americanExpress from "../assets/images/americanExpressLogo.png";
import dinersClubLogo from "../assets/images/dinersClubLogo.png";

export const Container = styled.div`
  display: flex;
  margin-top: 5px;
  align-items: center;
`;

export const ImgContainer = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const Text = styled.p`
  font-size: 13px;
  font-family: Poppins;
  letter-spacing: 0.3px;
  margin: 0;
`;

const Show4CardNumber = ({ numbers, cardType }) => {
  const getInfoByCardType = (type) => {
    let res = [];
    // eslint-disable-next-line default-case
    switch (type) {
      case "visa":
        res = [{ width: "30px", height: "11px" }, visa];
        break;
      case "master":
        res = [{ width: "23px", height: "14px" }, mastercard];
        break;
      case "amex":
        res = [{ width: "35px", height: "25px" }, americanExpress];
        break;
      case "diners":
        res = [{ width: "35px", height: "25px" }, dinersClubLogo];
        break;
    }
    return res;
  };

  const [style, img] = getInfoByCardType(cardType);

  return (
    <Container>
      <ImgContainer src={img} style={style} />
      <Text>{"•••• " + numbers}</Text>
    </Container>
  );
};

export default Show4CardNumber;
