import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { svgBars } from "../../../../services/svgIcons";

const NavBar = (props) => {
  const [navClose, setNavClose] = useState({ right: "0px" });

  const { categories } = props;

  const closeNavMobile = () => {
    if (window.innerWidth < 750) {
      setNavClose({ right: "-410px" });
    }

    if (window.innerWidth < 1199) {
      setNavClose({ right: "-300px" });
    }
  };

  useEffect(() => {
    closeNavMobile();
  }, []);

  const openNav = () => {
    setNavClose({ right: "0px" });
  };

  const closeNav = () => {
    setNavClose({ right: "-410px" });
  };

  const onClickLink = () => {
    closeNavMobile();
  };

  return (
    <div>
      <div className="main-navbar">
        <div id="mainnav">
          <div className="toggle-nav" onClick={openNav}>
            <div
              dangerouslySetInnerHTML={{
                __html: svgBars,
              }}
            />
          </div>
          <ul className="nav-menu" style={navClose}>
            <li className="back-btn" onClick={closeNav}>
              <div className="mobile-back text-right">
                <span>Cerrar</span>
                <i className="fa fa-angle-right pl-2" aria-hidden="true" />
              </div>
            </li>
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                className="nav-link"
                onClick={onClickLink}
              >
                Inicio
              </Link>
            </li>
            {categories &&
              categories.map((category) => {
                return (
                  <li key={category.uuid}>
                    <Link
                      to={`${process.env.PUBLIC_URL}/categoria/${
                        category.slug
                      }`}
                      className="nav-link"
                      onClick={onClickLink}
                    >
                      {category.name}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
