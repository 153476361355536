import axios from "axios";
import { baseUrlAPIV5 } from "../../constants";

class BinsApi {
  async binBBVAforDolce({ bin, type }) {
    const response = await axios.post(`${baseUrlAPIV5}/coupons/search`, {
      bin,
      type,
    });

    return response.data.data;
  }
}

export default BinsApi;
