import store from "store";
import dayjs from "dayjs";

import {
  getCatalogsEndpoint,
  getCategoriesEndpoint,
  getProductEndpoint,
  getFeaturedProductsEndpoint,
  getProductByCategoryEndpoint,
  getCategoryEndpoint,
  getRelatedProductsEndpoint,
  getProductSectionEndpoint,
} from "./endpoints";

import { slug_business } from "../../constants";
import { CATALOGS_STORE, CATEGORIES_MENU } from "../../constants/ActionTypes";

export const getCatalogBySlug = async () => {
  const infoCatalog = await store.get(CATALOGS_STORE);

  const dateLS = !infoCatalog
    ? dayjs(new Date()).format("YYYY-MM-DD")
    : dayjs(infoCatalog[0].petition_date).format("YYYY-MM-DD");
  const dateCurrent = dayjs(new Date()).format("YYYY-MM-DD");

  if (
    !infoCatalog ||
    !infoCatalog[0].belongs_to ||
    infoCatalog[0].belongs_to !== slug_business ||
    dayjs(dateLS).isBefore(dateCurrent)
  ) {
    const responseCatalog = await getCatalogsEndpoint();

    const { data } = responseCatalog.data;

    const responsedata = data.map((item) =>
      Object.assign(item, {
        belongs_to: slug_business,
        petition_date: new Date(),
      })
    );

    store.set(CATALOGS_STORE, responsedata);
    return responsedata;
  }

  return infoCatalog;
};

export const getCategories = async () => {
  try {
    const cat = await getCatalogBySlug();
    const res = await getCategoriesEndpoint(cat);

    const { data } = res.data;

    store.set(CATEGORIES_MENU, data);
    return data;
  } catch (error) {
    console.log({ error });
  }
};

export const getProduct = async (productId) => {
  try {
    const cat = await getCatalogBySlug();
    const res = await getProductEndpoint(cat, productId);

    return res;
  } catch (error) {
    console.log({ error });
  }
};

// Custom properties
export const getProductSection = async (productId) => {
  try {
    const res = await getProductSectionEndpoint(productId);

    return res;
  } catch (error) {
    console.log({ error });
  }
};

export const getFeaturedProducts = async (page) => {
  try {
    const cat = await getCatalogBySlug();
    const res = await getFeaturedProductsEndpoint(cat, page);

    return res;
  } catch (error) {
    console.log({ error });
  }
};

export const getRelatedProducts = async (productSlug) => {
  try {
    const cat = await getCatalogBySlug();
    const res = await getRelatedProductsEndpoint(productSlug, cat);

    return res;
  } catch (error) {
    console.log({ error });
  }
};

export const getProductByCategory = async (slug_category, page) => {
  try {
    const cat = await getCatalogBySlug();
    const res = await getProductByCategoryEndpoint(slug_category, cat, page);

    return res;
  } catch (error) {
    console.log({ error });
  }
};

export const getCategory = async (slug_category) => {
  try {
    const cat = await getCatalogBySlug();
    const res = await getCategoryEndpoint(slug_category, cat);

    return res;
  } catch (error) {
    console.log({ error });
  }
};
