import React, { useState, useCallback, useEffect } from "react";
import store from "store";

import CouponApi from "../../services/Coupon";
import { COUPON_APPLIED } from "../../constants/ActionTypes";
import { slug_business } from "../../constants";

const couponApi = new CouponApi();

const CheckoutCoupon = ({
  setCoupon,
  coupon,
  applicateBinInProducts,
  resetProductToLocalStorage,
}) => {
  const [inputCoupon, setInputCoupon] = useState("");
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [errorCoupon, setErrorCoupon] = useState(false);
  const [messageError, setMessageError] = useState("");

  useEffect(
    () => {
      setInputCoupon((coupon && coupon.code) || "");
      setMessageError("");
    },
    [coupon && coupon.code]
  );

  const handleSubmitCoupon = useCallback(
    async (event) => {
      const c_a = store.get(COUPON_APPLIED);
      event.preventDefault();
      if (!inputCoupon.trim()) {
        setErrorCoupon(true);
        setMessageError("Por favor, ingrese un código");
        return;
      }
      if (!c_a) {
        setLoadingCoupon(true);
        setErrorCoupon(false);
        // setMessageError('');
        try {
          const response = await couponApi.findCouponForText(
            inputCoupon.trim()
          );
          if (response && response.stores[0].slug === slug_business) {
            setLoadingCoupon(false);
            setCoupon(response);
            applicateBinInProducts(response);
            store.set(COUPON_APPLIED, response);
          } else {
            setLoadingCoupon(false);
            setErrorCoupon(true);
            setMessageError("El código no existe");
          }
        } catch (error) {
          setLoadingCoupon(false);
          setErrorCoupon(true);
          setMessageError("El código no existe");
        }
      }
    },
    [applicateBinInProducts, inputCoupon, setCoupon, slug_business]
  );

  const handleDeleteBinds = useCallback(
    () => {
      setCoupon({});
      store.remove(COUPON_APPLIED);
      setInputCoupon("");
      resetProductToLocalStorage();
    },
    [applicateBinInProducts, setCoupon]
  );

  return (
    <div
      className="checkout-form"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        alignItems: "flex-end",
        fontSize: "12px",
      }}
    >
      <form
        onSubmit={handleSubmitCoupon}
        applied={!!coupon && !!coupon.uuid}
        style={{ display: "flex", alignItems: "center" }}
      >
        {!!coupon && !!coupon.uuid && (
          <div
            onClick={handleDeleteBinds}
            style={{
              marginRight: "15px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            <i className="fa fa-trash-o" />
          </div>
        )}
        <div
          className="form-group"
          style={{ display: "flex", marginBottom: 0 }}
        >
          <input
            placeholder="Ingrese un código"
            autoComplete="off"
            value={inputCoupon}
            name="couponInput"
            onChange={(e) => setInputCoupon(e.target.value)}
            disabled={!!coupon && !!coupon.uuid}
            type="text"
          />
          <div className="input-group-append">
            <button
              className={`btn ${
                coupon && coupon.uuid ? "btn-success" : "btn-light"
              }`}
              type="submit"
              disabled={(!!coupon && !!coupon.uuid) || loadingCoupon}
            >
              {loadingCoupon ? (
                <>
                  {/* <LoadingCss /> */}
                  Verificando...
                </>
              ) : coupon && coupon.uuid ? (
                "Código aplicado"
              ) : (
                "Aplicar"
              )}
            </button>
          </div>
        </div>
      </form>
      {errorCoupon && (
        <span
          style={{
            fontSize: "12px",
            color: "#e74c3c",
            width: "100%",
            display: "block",
            textAlign: "right",
          }}
        >
          {messageError}
        </span>
      )}
      {!!coupon && !!coupon.uuid && (
        <span
          style={{
            fontSize: "12px",
            color: "#888",
            width: "100%",
            display: "block",
            textAlign: "right",
          }}
          dangerouslySetInnerHTML={{
            __html:
              coupon.conditions &&
              coupon.conditions.replace(/(<? *script)/gi, "illegalscript"),
          }}
        />
      )}
    </div>
  );
};

export default CheckoutCoupon;
