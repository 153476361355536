import React, { createContext, useState, useCallback } from "react";
import ReactPixel from "react-facebook-pixel";
import store from "store";
import { useMap } from "../map";
import { toast } from "react-toastify";

import {
  PRODUCTS_MENU,
  PRODUCTS_SHOPPING_CART,
  USER_INFO_CART,
  COUPON_APPLIED
} from "../../constants/ActionTypes";
import { slug_business } from "../../constants";
import ProductsApi from "../../services/ProductsApi";

const productsApi = new ProductsApi();

export const CartContext = createContext();

const getProductsFromLocalStorage = () => {
  let localShoppingCart = store.get(PRODUCTS_SHOPPING_CART);

  let shoppingCartProducts =
    typeof localShoppingCart == "string"
      ? new Map(JSON.parse(localShoppingCart))
      : localShoppingCart && typeof localShoppingCart != "undefined"
      ? localShoppingCart
      : new Map();
  return shoppingCartProducts;
};

const Provider = ({ children }) => {
  const { ubication, price } = useMap();

  const [prices, setPrices] = useState({});
  const [freeShippingDelivery, setFreeShippingDelivery] = useState(false);

  const [discountCoupon, setDiscountCoupon] = useState(0);
  const [typeCoupon, setTypeCoupon] = useState("");
  const [productAddedToCartModal, setProductAddedToCartModal] = useState(false);

  const [productsCart, setProductsCart] = useState(() => {
    return getProductsFromLocalStorage() || new Map();
  });
  const [userInfo, setUserInfo] = useState(() => {
    const infoUser = store.get(USER_INFO_CART);
    if (infoUser) {
      return infoUser;
    }
    return null;
  });

  const [categoriesWProducts, setCategoriesWProducts] = useState(
    store.get(PRODUCTS_MENU) || new Map()
  );

  const resetProductToLocalStorage = () => {
    setProductsCart(new Map(getProductsFromLocalStorage()));
    setDiscountCoupon(0);
  };

  const applicateBinInProducts = data => {
    let localProductsCart = new Map(getProductsFromLocalStorage());
    const products = getProductsCartArray();

    if (data.products.length === 0) {
      setDiscountCoupon(data.amount);
      setTypeCoupon(data.type);
      // const cat = products.map((item) => {
      //   return item.category.uuid;
      // });

      // const uniqCategories = cat.filter(
      //   (value, index, self) => self.indexOf(value) === index
      // );

      // uniqCategories.forEach((cat) => {
      //   const productsLocal = localProductsCart.get(cat);
      //   if (productsLocal) {
      //     const newProducts = productsLocal.map((product) => {
      //       return {
      //         ...product,
      //         prices: {
      //           data: [
      //             {
      //               ...product.prices.data[0],
      //               price:
      //                 Number(data.amount) >=
      //                 Number(product.prices.data[0].price)
      //                   ? 0
      //                   : product.prices.data[0].price - data.amount,
      //               compare_to: product.prices.data[0].price,
      //             },
      //           ],
      //         },
      //       };
      //     });

      //     localProductsCart.set(cat, newProducts);
      //   }
      // });
    } else {
      const categories = products.map(item => {
        const [newData] = data.products.map(product => {
          if (item.uuid === product.uuid) {
            return item.category.uuid;
          }
        });
        if (newData !== undefined) {
          return newData;
        }
      });

      const sanitizerCategory = categories.filter(
        (value, index, self) => value !== undefined
      );

      data.products.forEach(product => {
        sanitizerCategory.forEach(cat => {
          let products = localProductsCart.get(cat);

          if (products) {
            const index = products.findIndex(e => e.uuid === product.uuid);

            if (products[index]) {
              products[index] = {
                ...products[index],
                prices: {
                  data: [
                    {
                      ...products[index].prices.data[0],
                      price:
                        data.type === "percentage"
                          ? (
                              products[index].prices.data[0].price -
                              (data.amount / 100) *
                                products[index].prices.data[0].price
                            ).toFixed(2)
                          : Number(data.amount) >=
                            Number(products[index].prices.data[0].price)
                          ? 0
                          : products[index].prices.data[0].price - data.amount,
                      compare_to: products[index].prices.data[0].price
                    }
                  ]
                }
              };
            }
            localProductsCart.set(cat, products);
            // setProductsCartParse(localProductsCart);
          }
        });
      });
    }

    setProductsCart(localProductsCart);
  };

  const calculateSubTotal = () => {
    let totalPrice = 0;
    for (let category of productsCart.values()) {
      category.forEach(e => {
        let sumToppings = 0;
        if (e.children_toppings_selected) {
          sumToppings = e.children_toppings_selected.reduce(
            (acc, item) => (acc + Number(item.price)) * item.quantity,
            0
          );
        }

        const price =
          e.prices &&
          e.prices.data &&
          e.prices.data[0] &&
          e.prices.data[0].compare_to
            ? e.prices.data[0].compare_to
            : e.prices.data[0].price
            ? e.prices.data[0].price
            : 0;
        totalPrice += e.quantity * (Number(price) + sumToppings);
      });
    }
    return totalPrice;
  };

  const calculateTotal = () => {
    let totalPrice = 0;
    for (let category of productsCart.values()) {
      category.forEach(e => {
        let sumToppings = 0;
        if (e.children_toppings_selected) {
          sumToppings = e.children_toppings_selected.reduce(
            (acc, item) => (acc + Number(item.price)) * item.quantity,
            0
          );
        }

        const price =
          e.prices && e.prices.data && e.prices.data[0]
            ? e.prices.data[0].price
            : 0;
        totalPrice += e.quantity * (Number(price) + sumToppings);
      });
    }
    return totalPrice;
  };

  const calculateDiscount = () => {
    let discount = 0;
    for (let category of productsCart.values()) {
      category.forEach(e => {
        const price =
          e.prices &&
          e.prices.data &&
          e.prices.data[0] &&
          e.prices.data[0].compare_to > 0
            ? Number(e.prices.data[0].compare_to) -
              Number(e.prices.data[0].price)
            : 0;
        discount += e.quantity * Number(price);
      });
    }
    return discount;
  };

  const calculateQuantity = () => {
    let quantityProducts = 0;
    for (let category of productsCart.values()) {
      category.forEach(e => {
        quantityProducts += 1;
      });
    }
    return quantityProducts;
  };

  const handleAddProduct = async (
    product,
    number,
    update = false,
    showModal = false
  ) => {
    const productsCartArray = getProductsCartArray();
    const productUuid = product.has_variants
      ? product.uuidVariant
      : product.uuid;

    const productExistsOnCart = productsCartArray.find(el =>
      el.has_variants ? el.uuidVariant === productUuid : el.uuid === productUuid
    );

    let stock = -1;
    let sumProducts = 0;

    // if (
    //   !!product.max_per_order &&
    //   Number(product.max_per_order) === product.quantity
    // ) {
    //   toast.warn("Llegaste al máximo permitido por orden");
    //   return false;
    // }

    if (productExistsOnCart) {
      if (
        (!!productExistsOnCart.max_per_order &&
          Number(productExistsOnCart.max_per_order) ===
            productExistsOnCart.quantity) ||
        (!!productExistsOnCart.max_per_order &&
          Number(productExistsOnCart.quantity) + number >
            Number(productExistsOnCart.max_per_order))
      ) {
        return toast.warn("Llegaste al máximo permitido por orden");
      }
    }

    if (product.inventory_policy === "deny") {
      stock = await productsApi.getStock({
        product_uuid: productUuid,
        slug_business
      });

      sumProducts = productsCartArray
        .filter(item =>
          item.has_variants
            ? item.uuidVariant === productUuid
            : item.uuid === productUuid
        )
        .reduce((acc, item) => Number(item.quantity) + acc, 0);

      sumProducts += number
        ? Number(number)
        : Number(product.increase_multiplier);
    }

    if (
      (Number(stock.stock) === 0 || Number(stock.stock) < 0) &&
      stock.stock !== null
    ) {
      return toast.warn(
        `El producto ${product.name} se encuentra fuera de stock`
      );
    }

    if (sumProducts > Number(stock.stock)) {
      return toast.warn(
        `El producto que intentas agregar ya se agotó el stock`
      );
    }

    let localProductsCart = new Map(productsCart);
    let products = localProductsCart.get(product.category.uuid);
    //exists category
    if (products) {
      const index = products.findIndex(item =>
        item.has_variants
          ? item.uuidVariant === productUuid
          : item.uuid === productUuid
      );
      let newProduct = {};

      if (update === true) {
        products[index] = {
          ...product,
          quantity:
            products[index].quantity + number ||
            products[index].increase_multiplier,
          stock: stock ? stock.stock : -1
        };
      } else if (number) {
        const cartHasProduct = products.some(item =>
          item.has_variants
            ? item.uuidVariant === productUuid
            : item.uuid === productUuid
        );

        if (cartHasProduct) {
          products[index] = {
            ...product,
            quantity:
              products[index].quantity + number ||
              products[index].increase_multiplier,
            stock: stock ? stock.stock : -1
          };
        } else {
          newProduct = {
            ...product,
            quantity: number || product.increase_multiplier,
            stock: stock ? stock.stock : -1
          };

          products.push(newProduct);
        }
      } else {
        const index = products.findIndex(item =>
          item.has_variants
            ? item.uuidVariant === productUuid
            : item.uuid === productUuid
        );

        products[index] = {
          ...product,
          quantity:
            products[index].quantity + products[index].increase_multiplier,
          stock: stock ? stock.stock : -1
        };
      }
      localProductsCart.set(product.category.uuid, products);
      //dont exists category
    } else {
      let newProduct = {
        ...product,
        quantity: number || product.increase_multiplier,
        stock: stock ? stock.stock : -1
      };
      localProductsCart.set(product.category.uuid, [newProduct]);
    }

    setProductsCartParse(localProductsCart);

    if (showModal) {
      setProductAddedToCartModal(true);
    } else {
      toast(`Producto agregado al carrito: ${product.name}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }

    const productAddToCart = {
      ...product,
      id: productUuid,
      price:
        product.prices &&
        product.prices.data[0] &&
        product.prices.data[0].price,
      totalPrice:
        product.prices &&
        product.prices.data[0] &&
        product.prices.data[0].price * number,
      brand: product.vendor,
      category: product.category.name,
      quantity: number
    };

    window.dataLayer.push({
      event: "addToCart",
      label: `Producto ${product.name} agregado al carrito`,
      ecommerce: {
        add: {
          actionField: {
            list: product.name
          },
          products: [productAddToCart]
        }
      }
    });

    ReactPixel.track("AddToCart");
  };

  const updateCartForOutOfStock = async productsArray => {
    let newProducts;
    let localProductsCart = new Map(productsCart);

    productsArray.forEach(product => {
      let products = localProductsCart.get(product.category.uuid);

      const uuidConsulting = product.has_variants
        ? product.uuidVariant
        : product.uuid;

      let productsWithUuid = products.filter(p =>
        p.has_variants
          ? p.uuidVariant === uuidConsulting
          : p.uuid === uuidConsulting
      );
      let otherProducts = products.filter(p =>
        p.has_variants
          ? p.uuidVariant !== uuidConsulting
          : p.uuid !== uuidConsulting
      );

      if (product.stock <= 0) {
        newProducts = products.filter((p, i) =>
          p.has_variants
            ? p.uuidVariant !== uuidConsulting
            : p.uuid !== uuidConsulting
        );
      } else if (product.stock > 0 && product.stock !== -1) {
        const sumProducts = productsWithUuid.reduce(
          (acc, item) => Number(item.quantity) + acc,
          0
        );

        if (productsWithUuid.length === 1) {
          newProducts = products.map(p => {
            const uuid = p.has_variants ? p.uuidVariant : p.uuid;
            if (
              product.quantity > product.stock &&
              product.stock !== -1 &&
              product.stock > 0 &&
              uuidConsulting === uuid
            ) {
              return { ...p, quantity: product.stock, stock: product.stock };
            }
            return { ...p };
          });
        } else if (
          productsWithUuid.length > 1 &&
          product.stock <= sumProducts
        ) {
          productsWithUuid.pop();

          newProducts = [...otherProducts, ...productsWithUuid];
        }
      }
      localProductsCart.set(
        product.category.uuid,
        newProducts === null ? [] : newProducts
      );
    });
    setProductsCartParse(localProductsCart);
  };

  const handleRemoveProduct = (product, number) => {
    let localProductsCart = new Map(productsCart);
    let products = localProductsCart.get(product.category.uuid);
    const productUuid = product.has_variants
      ? product.uuidVariant
      : product.uuid;

    if (products) {
      const index = products.findIndex(item =>
        item.has_variants
          ? item.uuidVariant === productUuid
          : item.uuid === productUuid
      );

      console.log({ products, index });

      if (number) {
        products[index] = {
          ...products[index],
          quantity:
            products[index].quantity - products[index].increase_multiplier
        };
      } else {
        products.splice(index, 1);
      }
    }

    const productRemoveFromCart = {
      ...product,
      id: productUuid,
      price:
        product.prices &&
        product.prices.data[0] &&
        product.prices.data[0].price,
      brand: product.vendor,
      category: product.category.name
    };

    window.dataLayer.push({
      event: "removeFromCart",
      label: `Producto ${product.name} eliminado del carrito`,
      ecommerce: {
        remove: {
          products: [productRemoveFromCart]
        }
      }
    });

    localProductsCart.set(product.category.uuid, products);
    setProductsCartParse(localProductsCart);

    toast.warn(`Producto eliminado del carrito: ${product.name}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const setProductsCartParse = newProducts => {
    setProductsCart(newProducts);
    store.set(
      PRODUCTS_SHOPPING_CART,
      JSON.stringify(Array.from(newProducts.entries()))
    );
  };

  const handleClearEmpty = () => {
    setProductsCartParse(new Map());
  };

  const getProductsCartArray = useCallback(() => {
    let newProducts = [];
    let counter = 0;
    for (var value of productsCart.values()) {
      counter = 0;
      for (var value2 of value.values()) {
        newProducts.push({ ...value2, indexCart: counter });
        counter++;
      }
    }
    return newProducts;
  }, [productsCart]);

  const setUserInfoParse = (key, value) => {
    let newUserInfo = { ...userInfo };
    newUserInfo[key] = value;
    setUserInfo(newUserInfo);
    store.set(USER_INFO_CART, newUserInfo);
  };

  const getPrices = useCallback(
    orderType => {
      const subtotal = calculateSubTotal();
      const total = calculateTotal();
      const discount = calculateDiscount();

      let discountCalc = 0;

      if (discountCoupon > 0 && typeCoupon === "fixed") {
        discountCalc =
          discount + discountCoupon > subtotal
            ? subtotal
            : discount + discountCoupon;
      } else if (discountCoupon > 0 && typeCoupon === "percentage") {
        discountCalc = Number(
          Number((discountCoupon * subtotal) / 100) + discount
        ).toFixed(2);
      } else if (discountCoupon === 0) {
        discountCalc = Number(discount).toFixed(2);
      }
      const basePrice = Number(price && price.base ? price.base : 0);

      if (orderType === "delivery") {
        if (freeShippingDelivery) {
          setPrices({
            subtotal: (subtotal - discountCalc).toFixed(2),
            delivery: Number(0).toFixed(2),
            total: (Number(subtotal) - discountCalc).toFixed(2),
            discount: discountCalc
          });
        } else {
          setPrices({
            subtotal: (subtotal - discountCalc).toFixed(2),
            delivery: basePrice.toFixed(2),
            total: (Number(subtotal) + basePrice - discountCalc).toFixed(2),
            discount: discountCalc
          });
        }
      } else {
        setPrices({
          subtotal: (subtotal - discountCalc).toFixed(2),
          delivery: 0,
          total: (subtotal - discountCalc).toFixed(2),
          discount: discountCalc
        });
      }
    },
    [ubication, productsCart, price, freeShippingDelivery]
  );

  const handleClearAll = () => {
    store.remove(PRODUCTS_SHOPPING_CART);
    store.remove(USER_INFO_CART);
    store.remove(COUPON_APPLIED);
    setProductsCart(new Map());
    setUserInfo({});
  };

  const value = {
    productsCart,
    setProductsCart: setProductsCartParse,
    categoriesWProducts,
    setCategoriesWProducts,
    handleAddProduct,
    handleRemoveProduct,
    handleClearEmpty,
    quantity: calculateQuantity(),
    total: calculateTotal(),
    productsCartArray: getProductsCartArray(),
    userInfo,
    setUserInfo: setUserInfoParse,
    prices,
    getPrices,
    handleClearAll,
    applicateBinInProducts,
    resetProductToLocalStorage,
    updateCartForOutOfStock,
    setFreeShippingDelivery,
    productAddedToCartModal,
    setProductAddedToCartModal
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default {
  Provider,
  Consumer: CartContext.Consumer
};
