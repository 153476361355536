var module = {
  es: {
    locale: "es",
    messages: {
      topbar_title: "Welcome to Our store Multikart",
      call_us: "Call Us",
      home: "inicio",
      features: "features",
      new: "new",
      blog: "blog",
      blog_left_sidebar: "left sidebar",
      blog_right_sidebar: "right sidebar",
      blog_detail: "blog detail",
      category_left_sidebar: "left sidebar",
      category_right_sidebar: "right sidebar",
      category_no_sidebar: "no sidebar",
      category_metro: "metro",
      category_full_width: "full width",
      products: "productos",
      left_sidebar: "left sidebar",
      right_sidebar: "right sidebar",
      no_sidebar: "no sidebar",
      three_col_thumbnail_left: "Thumbnail left",
      three_col_thumbnail_right: "Thumbnail right",
      thumbnail_below: "thumbnail below",
      accordian_details: "accordian details",
      thumbnail_left: "image left",
      thumbnail_right: "image right",
      vertical_tab: "vertical tab",
      pages: "páginas",
      about_us: "about us",
      lookbook: "lookbook",
      login: "login",
      register: "register",
      logout: "Logout",
      search: "search",
      wishlist: "wishlist",
      cart: "cart",
      collection: "collection",
      forget_password: "forget password",
      contact: "contact",
      checkout: "checkout",
      compare: "compare",
      order_success: "order_success",
      dashboard: "Dashboard",
      FAQ: "FAQ",
      shop: "Tienda",
      mens_fashion: "mens fashion",
      top: "top",
      bottom: "bottom",
      ethic_wear: "ethic wear",
      sports_wear: "sports wear",
      shirts: "shirts",
      women_fashion: "womens fashion",
      dresses: "dresses",
      skirts: "skirts",
      westarn_wear: "westarn wear",
      ethnic_wear: "ethnic wear",
      sport_wear: "aport wear",
      bottom_wear: "bottom wear",
      kids_fashion: "kidss fashion",
      accessories: "Accessories",
      fashion_jewellery: "Fashion Jewellery",
      caps_and_hats: "caps and hats",
      precious_jewellery: "precious jewellery",
      necklaces: "necklaces",
      earrings: "earrings",
      rings_wrist_wear: "rings & wrist wear",
      men_accessories: "mens accessories",
      ties: "ties",
      cufflinks: "cufflinks",
      pockets_squares: "pockets squares",
      helmets: "helmets",
      scarves: "scarves",
      phone_cases: "phone cases",
      my_account: "My Account",
      fashion: "Fashion",
      beauty: "Beauty",
      electronic: "Electronic",
      furniture: "Furniture",
      kids: "Kids",
      pets: "Pets",
      vegetables: "Vegetables",
      watch: "Watch",
      theme_elements: "theme elements",
      element_title: "element title",
      collection_banner: "collection banner",
      home_slider: "home slider",
      category: "category",
      service: "service",
      image_size_ratio: "image size ratio",
      product_elements: "product elements",
      product_box: "product box",
      product_slider: "product slider",
      no_slider: "no slider",
      multi_slider: "multi slider",
      tab: "tab",
      email_template: "email template",
      portfolio: "portfolio",
      portfolio_grid_2: "portfolio grid 2",
      portfolio_grid_3: "portfolio grid 3",
      portfolio_grid_4: "portfolio grid 4",
      portfolio_masonary_2: "masonary 2",
      portfolio_masonary_3: "masonary 3",
      portfolio_masonary_4: "masonary 4",
      portfolio_masonary_full: "masonary full width",
    },
  },
  en: {
    locale: "en_US",
    messages: {
      topbar_title: "Welcome to Our store Multikart",
      call_us: "Call Us",
      home: "home",
      features: "características",
      new: "new",
      blog: "blog",
      blog_left_sidebar: "left sidebar",
      blog_right_sidebar: "right sidebar",
      blog_detail: "blog detail",
      category_left_sidebar: "left sidebar",
      category_right_sidebar: "right sidebar",
      category_no_sidebar: "no sidebar",
      category_metro: "metro",
      category_full_width: "full width",
      products: "products",
      left_sidebar: "left sidebar",
      right_sidebar: "right sidebar",
      no_sidebar: "no sidebar",
      three_col_thumbnail_left: "Thumbnail left",
      three_col_thumbnail_right: "Thumbnail right",
      thumbnail_below: "thumbnail below",
      accordian_details: "accordian details",
      thumbnail_left: "image left",
      thumbnail_right: "image right",
      vertical_tab: "vertical tab",
      pages: "pages",
      about_us: "about us",
      lookbook: "lookbook",
      login: "login",
      register: "register",
      logout: "Logout",
      search: "search",
      wishlist: "wishlist",
      cart: "cart",
      collection: "collection",
      forget_password: "forget password",
      contact: "contact",
      checkout: "checkout",
      compare: "compare",
      order_success: "order_success",
      dashboard: "Dashboard",
      FAQ: "FAQ",
      shop: "Shop",
      mens_fashion: "mens fashion",
      top: "top",
      bottom: "bottom",
      ethic_wear: "ethic wear",
      sports_wear: "sports wear",
      shirts: "shirts",
      women_fashion: "womens fashion",
      dresses: "dresses",
      skirts: "skirts",
      westarn_wear: "westarn wear",
      ethnic_wear: "ethnic wear",
      sport_wear: "aport wear",
      bottom_wear: "bottom wear",
      kids_fashion: "kidss fashion",
      accessories: "Accessories",
      fashion_jewellery: "Fashion Jewellery",
      caps_and_hats: "caps and hats",
      precious_jewellery: "precious jewellery",
      necklaces: "necklaces",
      earrings: "earrings",
      rings_wrist_wear: "rings & wrist wear",
      men_accessories: "mens accessories",
      ties: "ties",
      cufflinks: "cufflinks",
      pockets_squares: "pockets squares",
      helmets: "helmets",
      scarves: "scarves",
      phone_cases: "phone cases",
      my_account: "My Account",
      fashion: "Fashion",
      beauty: "Beauty",
      electronic: "Electronic",
      furniture: "Furniture",
      kids: "Kids",
      pets: "Pets",
      vegetables: "Vegetables",
      watch: "Watch",
      theme_elements: "theme elements",
      element_title: "element title",
      collection_banner: "collection banner",
      home_slider: "home slider",
      category: "category",
      service: "service",
      image_size_ratio: "image size ratio",
      product_elements: "product elements",
      product_box: "product box",
      product_slider: "product slider",
      no_slider: "no slider",
      multi_slider: "multi slider",
      tab: "tab",
      email_template: "email template",
      portfolio: "portfolio",
      portfolio_grid_2: "portfolio grid 2",
      portfolio_grid_3: "portfolio grid 3",
      portfolio_grid_4: "portfolio grid 4",
      portfolio_masonary_2: "masonary 2",
      portfolio_masonary_3: "masonary 3",
      portfolio_masonary_4: "masonary 4",
      portfolio_masonary_full: "masonary full width",
    },
  },
};

export default module;
