import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const NewProduct = props => {
  const { relatedProducts } = props;
  const [formattedRelatedProducts, setFormattedRelatedProducts] = useState([]);

  useEffect(() => {
    if (relatedProducts.length > 0) {
      const arrays = [];
      while (relatedProducts.length > 0) {
        arrays.push(relatedProducts.splice(0, 3));
      }

      setFormattedRelatedProducts(arrays);
    }
  }, [relatedProducts]);

  return (
    <div className="theme-card">
      {formattedRelatedProducts.length > 0 && (
        <h5 className="title-border">productos relacionados</h5>
      )}
      <Slider className="offer-slider slide-1">
        {formattedRelatedProducts.map((products, index) => (
          <div key={index}>
            {products.map(product => {
              const prices = product.prices.data[0];
              return (
                <div className="media" key={product.uuid}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/producto/${product.slug}`}
                  >
                    <img
                      className="img-fluid"
                      src={
                        product.media && product.media[0]
                          ? product.media[0].conversions.original
                          : `${process.env.PUBLIC_URL}/assets/images/default/default_logo.png`
                      }
                      alt=""
                    />
                  </Link>
                  <div className="media-body align-self-center">
                    <Link
                      to={`${process.env.PUBLIC_URL}/producto/${product.slug}`}
                    >
                      <h6>{product.name}</h6>
                    </Link>
                    <h4>
                      {`${prices.currency.symbol} ${prices.price}`}
                      {prices.compare_to > 0 && (
                        <del>
                          <span className="money">
                            {`${prices.currency.symbol} ${prices.compare_to}`}
                          </span>
                        </del>
                      )}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NewProduct;
