import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from "react";
import store from "store";

import { LAST_ORDER_TYPE } from "../../constants/ActionTypes.js";

const TypeContext = createContext({});

const TypeProvider = ({ children }) => {
  const [orderType, setOrderType] = useState(() => {
    const value = store.get(LAST_ORDER_TYPE);
    if (value) {
      return value;
    }
    return "delivery";
  });

  useEffect(() => {
    store.set(LAST_ORDER_TYPE, orderType);
  }, [orderType]);

  return (
    <TypeContext.Provider
      value={{
        orderType,
        setOrderType,
      }}
    >
      {children}
    </TypeContext.Provider>
  );
};

function useOrderType() {
  const context = useContext(TypeContext);

  if (!context) {
    throw new Error("useOrderType must be used within an TypeProvider");
  }
  return context;
}

export { TypeProvider, useOrderType };
