import axios from "axios";
import store from "store";
import dayjs from "dayjs";

import {
  METHODS_DELIVERY,
  PRICE_DELIVERY,
  DATA_VERIFY_WORKING_AREA,
} from "../../constants/ActionTypes";

import BusinessApi from "../BusinessApi";
import { baseUrlV1 } from "../../constants";

const businesApi = new BusinessApi();

class UbicationApi {
  async verifyCoverageAndPrice({
    payload,
    business_slug,
    channel,
    reload = false,
  }) {
    const lsDataVefiry = store.get(DATA_VERIFY_WORKING_AREA);

    const dateLS = !lsDataVefiry
      ? dayjs(new Date()).format("YYYY-MM-DD")
      : dayjs(lsDataVefiry.petition_date).format("YYYY-MM-DD");
    const dateCurrent = dayjs(new Date()).format("YYYY-MM-DD");

    if (
      !lsDataVefiry ||
      lsDataVefiry.belong_to !== business_slug ||
      dayjs(dateLS).isBefore(dateCurrent) ||
      (lsDataVefiry.current_position && lsDataVefiry.current_position.lat) !==
        payload.lat ||
      (lsDataVefiry.current_position && lsDataVefiry.current_position.lng) !==
        payload.lng ||
      reload === true
    ) {
      const business = await businesApi.getBrandInformations({
        business_slug,
        channel,
      });

      if (business) {
        const mainStore = business.stores.find((item) => item.is_main);

        const responseUbication = {};
        try {
          const response = await axios.post(
            `${baseUrlV1}/providers/${mainStore &&
              mainStore.shipping_provider_uuid}/working-area`,
            {
              latitude: payload.lat,
              longitude: payload.lng,
            }
          );

          const detailsResponse = await axios.post(
            `${baseUrlV1}/places/details`,
            {
              latitude: payload.lat,
              longitude: payload.lng,
            },
          );

          const { city, country, department, district } = detailsResponse.data.data;

          const w_a = response.data.data.uuid;

          Object.assign(responseUbication, {
            is_coverage: true,
            working_area: w_a,
            city,
            country,
            department,
            district,
          });

          const response2 = await axios.get(
            `${baseUrlV1}/working-areas/${w_a}/methods`
          );

          const s_m = response2.data.data[0].uuid;
          store.set(METHODS_DELIVERY, response2.data.data);

          Object.assign(responseUbication, {
            shipping_method: s_m,
            methods_delivery: response2.data.data,
          });

          const payloadPrices = {
            working_area: {
              uuid: w_a,
            },
            shipping_method: {
              uuid: s_m,
            },
          };

          const response3 = await axios.post(
            `${baseUrlV1}/providers/${mainStore &&
              mainStore.shipping_provider_uuid}/prices/calculator`,
            payloadPrices
          );

          Object.assign(responseUbication, {
            price_delivery: response3.data.data,
            petition_date: new Date(),
            belong_to: mainStore && mainStore.slug,
            current_position: payload,
          });

          store.set(PRICE_DELIVERY, response3.data.data);

          store.set(DATA_VERIFY_WORKING_AREA, responseUbication);
          return responseUbication;
        } catch (error) {
          Object.assign(responseUbication, {
            is_coverage: false,
            working_area: null,
            shipping_method: null,
          });
          store.set(DATA_VERIFY_WORKING_AREA, responseUbication);

          return responseUbication;
        }
      }
    }

    return lsDataVefiry;
  }
}

export default UbicationApi;
