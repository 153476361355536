// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

export const SLUG_DEFAULT = "";
export const USER_DATA_KEY = "user";
export const TOKEN_KEY = "token";
export const IS_AUTH_KEY = "is_auth";

export const PRODUCTS_SHOPPING_CART = "products_shopping_cart";
export const VENUE_DETAILS_SHOPPING_CART = "venue_details_shopping_cart";
export const PAYMENT_PAYLOAD = "PAYMENT_PAYLOAD";
export const ORDER_INFORMATION = "ORDER_INFORMATION";

export const LOGIN_MODE = "login_mode";
export const REGISTER_MODE = "register_mode";
export const VERSION = "version";
export const IS_NEW_VERSION = "is_new_version";
export const KEYBOARD_ACTIVE = "keyboard_active";

export const UNAUTHORIZED = 401;

export const LAST_LOCATION = "last_location";
export const ACTIVE_LAST_LOCATION_FB = "active_last_location_fb";
export const ACTIVE_LAST_LOCATION_GOOGLE = "active_last_location_google";
export const FACEBOOK_LOGIN_SPAWN = "facebook_login_spawn";
export const LAST_FB_LOCATION = "last_facebook_location";

export const LAST_GOOGLE_LOCATION = "last_google_location";

export const MAX_PAGE_TOP_TO_CHANGE_TOOLBAR = 10;

export const CHECKOUT_VIEW = "checkout_view";
export const ADD_PAYMENT_VIEW = "add_payment_view";
export const SEARCH_PROMOTER_VIEW = "search_promoter_view";

export const INVITATION_TOKEN = "invitation_token";

export const SOCIAL_LOGIN_MODE = "social_login_mode";
export const SOCIAL_BIND_MODE = "social_bind_mode";
export const FACEBOOK_MODE = "facebook_mode";
export const GOOGLE_MODE = "google_mode";

export const STEP_ORDER_CREATION = "create_order_current_step";
export const DATA_BEFORE_ORDER = "data_before_order_created";
export const WAITER = "waiter";
export const PRODUCTS_BEFORE_ORDER = "products_before_order_created";
export const USER_ROLE = "user_role";
export const PLACE_OF_WORK = "place_of_work";
export const VENUE_ID = "venue_id";
export const VENUE_UUID = "venue_uuid";
export const ORDER_ID = "order_id";
export const DIVIDER_ORDER = "divider_order";
export const APPLY_DISCOUNT = "apply_discount";
export const PAYMENT_DETAILS = "order_payment_details";
export const SELECT_PAYMENT_METHOD_AND_COLLECT =
  "select_payment_method_and_collect";

export const FIRST_STEP_CREATE_ORDER = "first_step_create_order";
export const SELECT_PRODUCTS = "select_products";
export const SEARCH_PRODUCTS = "search_products";
export const CREATED_ORDER = "created_order";
export const FINAL_MESSAGE = "final_message";
export const SELECT_PRODUCTS_TO_PAY = "select_products_to_pay";
export const CURRENT_ORDER_VIEW = "current_order_view";
export const PAID_ORDER = "paid_order";
export const PROOF_OF_PAYMENT = "proof_of_payment";
export const BILL = "bill";
export const INVOICE = "invoice";
export const PAY_FOR_PARTS = "pay_for_parts";

export const PROGRESS_START = "progress_start";
export const PROGRESS_DONE = "progress_done";

export const SUNAT_NUMBER = 700;

export const PURCHASE_ORDER_DETAILS = "purchase_order_details";
export const PURCHASE_ORDER_CHOOSE_DATE = "purchase_order_choose_date";

export const SELECT_PROVIDER = "select_provider";
export const CONFIRM_ORDER = "confirm_order";

export const VERIFICATION_EVIDENCE = "verification_evidence";
export const VERIFICATION_LIST = "verification_list";

export const CATEGORIES_MENU = "pasiflora_categories_menu";
export const PRODUCTS_MENU = "products_menu";
export const BRAND_INFO = "BRAND_INFO";
export const MAIN_STORE = "MAIN_STORE";
export const CHANNEL_STORE = 'CHANNEL_STORE';
export const CATALOGS_STORE = "CATALOGS_STORE";
export const USER_INFO_CART = "USER_INFO_CART";
export const UBICATION_ORDER_CART = "UBICATION_ORDER_CART";
export const IS_NEW_VERSION_MENU = "is_new_version_menu";

export const USER_SPOTS = "user_spots";
export const PAYMENT_METHOD_BY_VENUE = "payment_method_by_venue";
export const WAITERS_BY_SPOTS = "waiters_by_spots";

export const RESERVATION_CONTACT_NUMBER = "reservation_contact_number";
export const RESERVATION_DATE_IN = "reservation_date_in";
export const RESERVATION_QUANTITY_PERSON = "reservation_quantity_person";
export const RESERVATION_EXTRA_INFORMATION = "reservation_extra_information";
export const RESERVATION_ACTIVE = "reservation_active";
export const RESERVATION_LANGUAGE_CATALOG = "RESERVATION_LANGUAGE_CATALOG";
export const RESERVATION_ALLERGIES = "RESERVATION_ALLERGIES";
export const RESERVATION_EMAIL = "RESERVATION_EMAIL";
export const RESERVATION_NAME = "RESERVATION_NAME";
export const RESERVATION_ANOTHER_ALLERGY = "RESERVATION_ANOTHER_ALLERGY";

export const COVERAGE_THE_STORE = "@IDBI:isInCoverage";
export const METHODS_DELIVERY = "@IDBI:methodsDelivery";
export const PRICE_DELIVERY = "@IDBI:priceDelivery";
export const STORE_SELECTED = "@IDBI:storeSelected";

export const WORKING_AREA = "w_a";
export const SHIPPING_METHOD = "s_m";
export const DATA_VERIFY_WORKING_AREA = "r_u_m";
export const COUPON_APPLIED = "c_a";
export const CONSULTING_CARD = "c_c";
export const LAST_ORDER_TYPE = "LAST_ORDER_TYPE";
