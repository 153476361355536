import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ScrollContext } from "react-router-scroll-4";
import { IntlProvider } from "react-redux-multilingual";
import { ToastContainer } from "react-toastify";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

// Import custom components
import store from "./store";
import translations from "./constants/translations";

// Context
import { MapProvider } from "./contexts/map";
import { MercadoPagoProvider } from "./contexts/mercado-pago";
import { BusinessProvider } from "./contexts/business";
import { TypeProvider } from "./contexts/orderType";
import CartContext from "./contexts/cart";

// Layouts
import Beauty from "./components/layouts/beauty/main";
import CustomLayout from "./components/Layout";

//Collection Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";

// Product Pages
import LeftSideBar from "./components/products/left-sidebar";

// Shop
import Cart from "./components/cart";
import CheckOutPage from "./components/checkout";
import OrderSuccess from "./components/order-success";

// Extra Pages
import AboutUs from "./components/pages/about-us";
import TermsAndConditions from "./components/pages/terms-and-conditions";
import ClaimsForm from "./components/pages/claims";
import ExchangeAndReturnPolicy from "./components/pages/exchange-and-return-policy";
import DeliveryConditions from "./components/pages/delivery-conditions";
import PrivacyPolicy from "./components/pages/privacy-policy";

const history = createBrowserHistory();

// GOOGLE TAG MANAGER
const tagManagerArgs = {
  gtmId: "GTM-TW9B527"
};

TagManager.initialize(tagManagerArgs);
window.dataLayer.push({
  event: "pageview",
  path: history.location.pathname
});

// FACEBOOK PIXEL
const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: false
};

ReactPixel.init("322773995810638", advancedMatching, options);
ReactPixel.pageView();

// LOG ROCKET
if (process.env.NODE_ENV === "production") {
  let storageVersion = localStorage.getItem("version");

  LogRocket.init("bj7l0j/passiflora", {
    release: storageVersion,
    console: {
      isEnabled: {
        log: false,
        debug: false
      }
    }
  });
  setupLogRocketReact(LogRocket);
}

const Root = () => {
  return (
    <Provider store={store}>
      <TypeProvider>
        <MapProvider>
          <CartContext.Provider>
            <IntlProvider translations={translations} locale="es">
              <Router basename={"/"} history={history}>
                <ScrollContext>
                  <Switch>
                    <BusinessProvider>
                      <ToastContainer />
                      <CustomLayout>
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/`}
                          component={Beauty}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/categoria/:slug`}
                          component={CollectionLeftSidebar}
                        />
                        <Route
                          exact
                          path={`${process.env.PUBLIC_URL}/producto/:slug`}
                          component={LeftSideBar}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/cart`}
                          component={Cart}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/orden-finalizada/:orderCode`}
                          component={OrderSuccess}
                        />
                        <MercadoPagoProvider>
                          <Route
                            path={`${process.env.PUBLIC_URL}/checkout`}
                            component={CheckOutPage}
                          />
                        </MercadoPagoProvider>
                        <Route
                          path={`${process.env.PUBLIC_URL}/terminos-y-condiciones`}
                          component={TermsAndConditions}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/politica-de-cambios-y-devoluciones`}
                          component={ExchangeAndReturnPolicy}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/condiciones-del-delivery`}
                          component={DeliveryConditions}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/politicas-de-privacidad`}
                          component={PrivacyPolicy}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/libro-de-reclamaciones/:slug_business`}
                          component={ClaimsForm}
                        />
                        <Route
                          path={`${process.env.PUBLIC_URL}/nosotros`}
                          component={AboutUs}
                        />
                      </CustomLayout>
                    </BusinessProvider>
                  </Switch>
                </ScrollContext>
              </Router>
            </IntlProvider>
          </CartContext.Provider>
        </MapProvider>
      </TypeProvider>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
