import React, { useEffect, useState } from "react";
import store from "store";

import { getCategories } from "../../../services/products";
import { slug_business } from "../../../constants";
import { CATEGORIES_MENU } from "../../../constants/ActionTypes";

// Import custom components
import NavBar from "./common/navbar";
import CartContainer from "./../../../containers/CartContainer";
import LogoImage from "./common/logo";
import Search from "../search";
import FreeDelivery from "../free-delivery";

const HeaderTwo = (props) => {
  const [parentCategories, setParentCategories] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);

  const getParentCategories = async () => {
    const categories = store.get(CATEGORIES_MENU);
    setParentCategories(categories)

    const data = await getCategories();
    setParentCategories(data)
  };

  useEffect(() => {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);

    getParentCategories()
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);      
    };
  }, [])

  const handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (number > 200) {
      if (window.innerWidth < 576) {
        document.getElementById("sticky").classList.remove("fixed");
      } else document.getElementById("sticky").classList.add("fixed");
    } else {
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  const handleOpenSearch = () => {
    setOpenSearch(true)
  };

  const handleCloseSearch = () => {
    setOpenSearch(false)
  };

    return (
      <div>
        <header id="sticky" className="sticky">
          <div className="mobile-fix-option" />
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu border-top-0">
                  <div className="menu-left">
                    <div className="navbar">
                      <FreeDelivery />
                    </div>
                  </div>
                  <div className="brand-logo layout2-logo py-0">
                    <LogoImage logo={props.logoName} />
                  </div>
                  <div className="menu-right pull-right">
                    <div>
                      <div className="icon-nav">
                        <ul>
                          <li className="onhover-div mobile-search">
                            <div className="tooltip-custom">
                              <img
                                src={`${
                                  process.env.PUBLIC_URL
                                }/assets/images/icons/search.svg`}
                                onClick={handleOpenSearch}
                                className="img-fluid"
                                alt="Buscar"
                              />
                              <span className="tooltiptext">Buscar</span>
                            </div>
                          </li>
                          <CartContainer />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-nav-center">
                  <NavBar categories={parentCategories} />
                </div>
              </div>
            </div>
          </div>
        </header>

        <Search
          slug_business={slug_business}
          openSearch={openSearch}
          closeSearch={handleCloseSearch}
        />
      </div>
    );
}

export default HeaderTwo;
