import axios from "axios";
import { baseUrlAPIV5 } from "../../constants";

class CouponApi {
  async findCouponForText(coupon) {
    const response = await axios.get(`${baseUrlAPIV5}/coupons/${coupon}`);
    return response.data.data;
  }
}

export default CouponApi;
