import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Breadcrumb from "../common/breadcrumb";
import HeaderTwo from "../common/headers/header-two";
import FooterOne from "../common/footers/footer-one";

const PrivacyPolicy = () => {
  useEffect(() => {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 2000);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Pasiflora | Políticas de privacidad</title>
      </Helmet>
      <HeaderTwo logoName={"layout3/logo.png"} />
      <Breadcrumb title="Políticas de privacidad" />
      {/*about section*/}
      <section className="about-page  section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 pt-4">
              <h2>Políticas de privacidad</h2>
              <p>
                On the other hand, we denounce with righteous indignation and
                dislike men who are so beguiled and demoralized by the charms of
                pleasure of the moment, so blinded by desire, that they cannot
                foresee the pain and trouble that are bound to ensue; and equal
                blame belongs to those who fail in their duty through weakness
                of will, which is the same as saying through shrinking from toil
                and pain. These cases are perfectly simple and easy to
                distinguish. In a free hour, when our power of choice is
                untrammelled and when nothing prevents our being able to do what
                we like best, every pleasure is to be welcomed and every pain
                avoided. But in certain circumstances and owing to the claims of
                duty or the obligations of business it will frequently occur
                that pleasures have to be repudiated and annoyances accepted.
                The wise man therefore always holds in these matters to this
                principle of selection: he rejects pleasures to secure other
                greater pleasures, or else he endures pains to avoid worse
                pains.
              </p>
              <h4>Subtitulo 1</h4>
              <p>
                On the other hand, we denounce with righteous indignation and
                dislike men who are so beguiled and demoralized by the charms of
                pleasure of the moment, so blinded by desire, that they cannot
                foresee the pain and trouble that are bound to ensue; and equal
                blame belongs to those who fail in their duty through weakness
                of will, which is the same as saying through shrinking from toil
                and pain. These cases are perfectly simple and easy to
                distinguish. In a free hour, when our power of choice is
                untrammelled and when nothing prevents our being able to do what
                we like best, every pleasure is to be welcomed and every pain
                avoided. But in certain circumstances and owing to the claims of
                duty or the obligations of business it will frequently occur
                that pleasures have to be repudiated and annoyances accepted.
                The wise man therefore always holds in these matters to this
                principle of selection: he rejects pleasures to secure other
                greater pleasures, or else he endures pains to avoid worse
                pains.
              </p>
            </div>
          </div>
        </div>
      </section>
      <FooterOne logoName={"layout3/logo.png"} />
    </div>
  );
};

export default PrivacyPolicy;
