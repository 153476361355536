import React, { useState, useEffect } from "react";
import store from "store";
import { Helmet } from "react-helmet";
import "../../common/index.scss";

// Import custom components
import {
  svgComercio,
  svgCompra,
  svgCrueltyFree,
  svgNatural
} from "../../../services/svgIcons";
import { getBannersCollection, getBrandInfo } from "../../../services";
import { getFeaturedProducts } from "../../../services/products";
import {
  BRAND_INFO,
  CHANNEL_STORE,
  MAIN_STORE
} from "../../../constants/ActionTypes";
import { useBusiness } from "../../../contexts/business";

import TopCollection from "../common/collection";
import FooterOne from "../../common/footers/footer-one";
import HeaderTwo from "../../common/headers/header-two";
import Carousel from "../../common/carousel";

const Home = () => {
  const [brand, setBrand] = useState();
  const [banners, setBanners] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);

  const { setMainStore, setChannelStore } = useBusiness();

  const getBanners = async () => {
    const res = await getBannersCollection();
    try {
      setBanners(res.data.data);
    } catch (error) {
      console.log({ error });
    }
  };

  const getFeaturedProductsFunc = async () => {
    const res = await getFeaturedProducts(1);

    const responseAllPages = [];
    if (res.data.links.next) {
      const numberOfPages = res.data.links.last.match("[?&]page=([^&]+).*$");
      for (let i = 2; i <= numberOfPages[1]; i++) {
        const res = await getFeaturedProducts(i);
        responseAllPages.push(res.data.data);
      }
    }

    const allFeaturedProducts = [...res.data.data, ...responseAllPages].flat();
    setFeaturedProducts(allFeaturedProducts);
  };

  const getBrand = async () => {
    const res = await getBrandInfo();

    if (res) {
      setBrand(res.data.data);
      store.set(BRAND_INFO, {
        ...res.data.data,
        petition_date: new Date()
      });

      const responseMainStore = res.data.data.stores.find(
        brandItem => brandItem.is_main
      );

      const channelECommerce =
        responseMainStore &&
        responseMainStore.channels.find(item => item.slug === "e-commerce");

      if (channelECommerce) setChannelStore(channelECommerce);
      if (responseMainStore) setMainStore(responseMainStore);

      store.set(MAIN_STORE, responseMainStore);
      store.set(CHANNEL_STORE, channelECommerce);
    }
  };

  useEffect(() => {
    getBanners();
    getFeaturedProductsFunc();
    getBrand();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Pasiflora</title>
        <meta name="description" content={brand && brand.description} />
      </Helmet>
      <HeaderTwo logoName={"layout3/logo.png"} />
      <div className="">
        <section className="p-0">
          {banners.length > 0 ? (
            <Carousel data={banners.length > 0 && banners} />
          ) : (
            <div>
              <div
                className="home"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner.png)`
                }}
              />
            </div>
          )}
        </section>
      </div>

      {/*About Section*/}
      <section className="beauty-about pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 offset-lg-1 text-center">
              <img
                src={
                  brand && brand.history_img
                    ? brand.history_img
                    : `${process.env.PUBLIC_URL}/assets/images/icon/layout3/Logo2.svg`
                }
                alt=""
                className="img-fluid blur-up lazyload"
              />
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="about-section">
                <div>
                  <h2>Revolución natural</h2>
                  <div className="about-text">
                    <p>
                      {brand && brand.history
                        ? brand.history
                        : "La belleza puede ser poderosa y empoderada. Puede ser también una búsqueda, en la que aprendemos más y a cuidar a nuestro planeta. De eso se trata nuestra Revolución Natural."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row service">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgNatural }} />
              <h5>Natural</h5>
              <p>
                Insumos naturales, <br />
                productos eco-amigables.
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgCrueltyFree }} />
              <h5>Cruelty Free</h5>
              <p>No se testea en animales.</p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 service-block1">
              <div dangerouslySetInnerHTML={{ __html: svgCompra }} />
              <h5>Compra Local</h5>
              <p>
                Apoya emprendimientos <br />y mano de obra peruanos.
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 service-block1">
              <div
                dangerouslySetInnerHTML={{
                  __html: svgComercio
                }}
              />
              <h5>Comercio Justo</h5>
              <p>
                Beneficia a los agricultores, productores y sus poblaciones.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*About Section End*/}

      {/*Product slider*/}
      {featuredProducts.length > 0 && (
        <TopCollection type={"beauty"} products={featuredProducts} />
      )}
      {/*Product slider End*/}

      {/*Video Section*/}
      <section className="video-section pt-0 mb-5">
        {/* <div className="title1">
          <h2 className="title-inner1">product tutorial</h2>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="video-img">
                <iframe
                  title="Pasiflora"
                  src="https://www.youtube.com/embed/36XeJq4OK7Y?rel=0&modestbranding=1&mute=1&showinfo=0&controls=0&autoplay=1&loop=1&playlist=36XeJq4OK7Y"
                  width="100%"
                  height="315"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Video Section End*/}

      <FooterOne logoName={"layout3/logo.png"} />
    </div>
  );
};

export default Home;
