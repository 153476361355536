import React, { createContext, useContext, useCallback } from "react";
import { useBusiness } from "../business";

const MercadoPagoContext = createContext();

const MercadoPagoProvider = ({ children }) => {
  const { credentials } = useBusiness();

  const setValueMercadoPago = useCallback(
    () => {
      const f = setInterval(() => {
        if (window.MercadoPago) {
          const mp = new window.MercadoPago(credentials.public_key);
          window.mercadoPagoIntance = mp
          clearInterval(f);
        }
      }, 1000);
    },
    [credentials]
  );

  const loadMercadoPago = useCallback(
    () => {
      const script = document.createElement("script");
      script.async = true;
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.dataset.transactionAmount = "0";
      document.body.appendChild(script);
      setValueMercadoPago();
    },
    [setValueMercadoPago]
  );

  return (
    <MercadoPagoContext.Provider
      value={{
        loadMercadoPago,
      }}
    >
      {children}
    </MercadoPagoContext.Provider>
  );
};

function useMercadoPago() {
  const context = useContext(MercadoPagoContext);

  if (!context) {
    throw new Error(
      "useMercadoPago must be used within an MercadoPagoProvider"
    );
  }
  return context;
}

export { MercadoPagoProvider, useMercadoPago };
