import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";

import {
  getDetailsOrder,
  getProductsOrderForOrderCode
} from "../../services/order/endpoints";
import {
  getBrandInfoEndpoint,
  getContactInfoEndpoint
} from "../../services/endpoints";
import { formatEstimatedTime } from "../../utils/global_functions";
import { svgCircleCheck } from "../../services/svgIcons";

import HeaderTwo from "../common/headers/header-two";
import FooterOne from "../common/footers/footer-one";
import OrderProduct from "./product";

const OrderSuccess = props => {
  const [address, setAddress] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [orderData, setOrderData] = useState(null);

  const { orderCode } = props.match.params;

  const getContactInfo = async () => {
    const res = await getContactInfoEndpoint();
    const { emails, phones } = res.data.data;

    setEmail(emails.length > 0 ? emails[0] : []);
    setPhone(phones.length > 0 ? phones[0] : []);
  };

  const getBrandInfo = async () => {
    const res = await getBrandInfoEndpoint();
    if (res) {
      const { address } = res.data.data.stores && res.data.data.stores[0].venue;
      setAddress(address && address.street);
    }
  };

  const getOrderData = async () => {
    const data = await Promise.all([
      getDetailsOrder(orderCode, "e1f5d0ca-48c6-4e37-80e1-757375636697"),
      getProductsOrderForOrderCode(orderCode)
    ]);

    const details = data[0].data.data;
    const products = data[1].data.data;

    const payloadInformation = {
      orderType: details.type ? details.type.slug : "",
      pickupSchedule: details.pickup_schedule
        ? dayjs(details.pickup_schedule).format("MMMM D, YYYY h:mm A")
        : "",
      estimated: formatEstimatedTime({
        start: details.delivery
          ? details.delivery.schedules[0].expected_from
          : null,
        end: details.delivery ? details.delivery.schedules[0].expected_to : null
      }),
      orderCode,
      productQuantity: products.length,
      products,
      total: details.total_amount + Number(details.delivery_amount),
      subtotal: details.total_amount,
      discount: details.discounted_amount,
      delivery: Number(details.delivery_amount).toFixed(2),
      ubication: {
        direction: details.delivery
          ? details.delivery.addresses[0].address
          : null,
        instructions: details.delivery
          ? details.delivery.addresses[0].instructions
          : null
      },
      extraMessage: ""
    };

    setOrderData(payloadInformation);
  };

  useEffect(() => {
    if (orderCode) {
      getOrderData();
    }

    getContactInfo();
    getBrandInfo();

    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Pasiflora | Gracias por tu compra</title>
      </Helmet>
      <HeaderTwo logoName={"layout3/logo.png"} />
      <section className="light-layout" style={{ padding: "40px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: svgCircleCheck
                  }}
                />
                <h2>Gracias por tu compra</h2>
                <p>
                  <strong>Orden #{orderData && orderData.orderCode}</strong>
                </p>
                {orderData &&
                orderData.orderType &&
                orderData.orderType === "delivery" ? (
                  <p>
                    Te notificaremos la confirmación de tu delivery programado
                    para <br />
                    <b>{orderData.estimated}</b> a través del correo.
                  </p>
                ) : (
                  <p>
                    Puedes recoger tu pedido con previa coordinación por
                    Whatsapp {phone.phone} o a nuestro mail {email.email}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-order">
                <h3>Detalles de tu orden</h3>
                <div className="row product-order-detail">
                  <div className="col-6 order_detail justify-content-start">
                    <div>
                      <h4>Producto</h4>
                    </div>
                  </div>
                  <div className="col-3 order_detail justify-content-end">
                    <div>
                      <h4>Cantidad</h4>
                    </div>
                  </div>
                  <div className="col-3 order_detail justify-content-end">
                    <div>
                      <h4>Precio</h4>
                    </div>
                  </div>
                </div>
                {orderData &&
                  orderData.products.map(product => {
                    return <OrderProduct product={product} />;
                  })}
                <div className="total-sec">
                  <ul>
                    <li>
                      subtotal{" "}
                      <span>
                        S/ {Number(orderData && orderData.total).toFixed(2)}
                      </span>
                    </li>
                    {orderData && orderData.discounted_amount > 0 && (
                      <li>
                        Descuento{" "}
                        <span>
                          - S/{" "}
                          {Number(
                            orderData && orderData.discounted_amount
                          ).toFixed(2)}
                        </span>
                      </li>
                    )}
                    {orderData &&
                      orderData.orderType &&
                      orderData.orderType === "delivery" && (
                        <li>
                          Delivery{" "}
                          <span>
                            S/{" "}
                            {Number(orderData && orderData.delivery).toFixed(2)}
                          </span>
                        </li>
                      )}
                  </ul>
                </div>
                <div className="final-total">
                  <h3>
                    total{" "}
                    <span>
                      S/ {Number(orderData && orderData.total).toFixed(2)}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row order-success-sec mb-5">
                <div className="col-sm-12">
                  {orderData &&
                  orderData.orderType &&
                  orderData.orderType === "delivery" ? (
                    <>
                      <h4>Dirección de entrega</h4>
                      <ul className="order-detail">
                        <li>{orderData && orderData.ubication.direction}</li>
                        <li>
                          {orderData &&
                            orderData.ubication.instructions &&
                            orderData.ubication.instructions}
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <h4>Dirección de Recojo</h4>
                      <ul className="order-detail mb-3">
                        <li>{address}</li>
                      </ul>
                      <h4>Horario de Recojo</h4>
                      <ul className="order-detail">
                        <li>{orderData && orderData.pickupSchedule}</li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne logoName={"layout3/logo.png"} />
    </div>
  );
};

export default OrderSuccess;
