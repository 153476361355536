import React, { useEffect } from "react";
import { useBusiness } from "../contexts/business";
import { slug_business } from "../constants";

const CustomLayout = ({ children }) => {
  const { getBrandInformations } = useBusiness();

  useEffect(() => {
    async function get() {
      try {
        await getBrandInformations({
          business_slug: slug_business,
          channel: "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5",
        });
      } catch (e) {
        console.log({ e });
      }
    }
    get();
  }, []);

  return <>{children}</>;
};

export default CustomLayout;