import React from "react";
import ContentLoader from "react-content-loader";

const FilterItemLoader = (props) => (
  <ContentLoader viewBox="0 0 200 110" width="100%" {...props}>
    <circle cx="9" cy="25" r="9" />
    <rect x="20" y="15" rx="5" ry="5" width="100%" height="20" />
    <circle cx="9" cy="50" r="9" />
    <rect x="20" y="40" rx="5" ry="5" width="100%" height="20" />
    <circle cx="9" cy="75" r="9" />
    <rect x="20" y="65" rx="5" ry="5" width="100%" height="20" />
    <circle cx="9" cy="100" r="9" />
    <rect x="20" y="90" rx="5" ry="5" width="100%" height="20" />
  </ContentLoader>
);

export default FilterItemLoader;
