import React, { Component } from 'react';

export default class ImageZoom extends Component {
    render() {
        const {image, product} = this.props;

        return (
            <img src={`${image}`}  className={`img-fluid image_zoom_cls-0 ${product && !product.is_available && "img-no-available"}`} />
        );
    }
}