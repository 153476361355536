import React from "react";
import ContentLoader from "react-content-loader";

const ProductListItemLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={300}
    viewBox="0 0 200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="10" y="275" rx="2" ry="2" width="165" height="10" />
    <rect x="10" y="290" rx="2" ry="2" width="66" height="10" />
    <rect x="10" y="15" rx="2" ry="2" width="200" height="250" />
  </ContentLoader>
);

export default ProductListItemLoader;
