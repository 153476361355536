import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import ReactPixel from "react-facebook-pixel";

import ProductsApi from "../../services/ProductsApi";
import ProductListItemLoader from "../collection/common/product-list-item-loader";
import ProductListItem from "../collection/common/product-list-item";

const productsApi = new ProductsApi();

const Search = (props) => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);
  const [showProducts, setShowProducts] = useState(false);
  const [term, setTerm] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const { slug_business, openSearch, closeSearch } = props;

  const searchProductsWithTerm = async () => {
    window.dataLayer.push({
      event: "search",
      term: term,
    });
    ReactPixel.track("Search");

    try {
      setLoadingSearch(true);
      const response = await productsApi.searchByTerm({
        slug_business,
        term,
      });

      if (response) {
        setLoadingSearch(false);
        setSearchProducts(response);
        setShowProducts(true);
      }
    } catch (error) {
      throw new Error("Products now found with term");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchProductsWithTerm();
    }
  };

  const handleSearch = (e) => {
    setTerm(e.target.value);
  };

  useEffect(
    () => {
      clearTimeout(searchTimeout);
      if (term.trim()) {
        const time = setTimeout(() => {
          searchProductsWithTerm({ slug_business, term });
        }, 1000);

        setSearchTimeout(time)
      }
    },
    [term]
  );

  useEffect(() => {
    return () => {
      setTerm("");
    };
  }, []);

  return (
    <Modal
      open={openSearch}
      onClose={closeSearch}
      className="modal fade video-modal"
      center
    >
      <div className="search-overlay">
        <div>
          <div className="overlay-content">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Buscar un producto"
                      onChange={handleSearch}
                      onKeyDown={handleKeyDown}
                      value={term}
                    />
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={searchProductsWithTerm}
                  >
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/icons/search.svg`}
                      className="img-fluid"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {showProducts && (
            <div>
              {loadingSearch ? (
                <div className="row">
                  <div className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3">
                    <ProductListItemLoader />
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3">
                    <ProductListItemLoader />
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3">
                    <ProductListItemLoader />
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3">
                    <ProductListItemLoader />
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3">
                    <ProductListItemLoader />
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3">
                    <ProductListItemLoader />
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3">
                    <ProductListItemLoader />
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3">
                    <ProductListItemLoader />
                  </div>
                </div>
              ) : (
                <div className="row">
                  {searchProducts.length > 0 &&
                    searchProducts.map((product) => (
                      <div
                        className="col-xl-4 col-md-4 col-sm-6 col-grid-box mb-3 mt-3"
                        key={product.uuid}
                        onClick={closeSearch}
                      >
                        <ProductListItem product={product} key={product.uuid} />
                      </div>
                    ))}
                  {searchProducts.length === 0 && (
                    <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                      <h3>Lo siento! No se encontraron productos</h3>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Search;
