import React, { Component } from "react";
import styled, { css } from "styled-components";

import GrayCardImg from "../assets/images/GrayCardImg.png";
import VisaCardImg from "../assets/images/VisaCardImg.png";
import AmexCardImg from "../assets/images/AmexCardImg.png";
import MasterCardImg from "../assets/images/MasterCardImg.png";
import DinersCardImg from "../assets/images/DinersCardImg.png";

const ImageContainerGeneral = styled.div`
  width: 280px;
  height: 180px;
  position: relative;
  overflow: hidden;
  border-radius: 17px;
`;

const ImageContainerGray = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const ImageContainerNormal = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0;
  height: 0;
  transition: all ease-out 0.3s;
  border-radius: 0 100% 100% 100%;
  overflow: hidden;
  z-index: 1;
  ${(props) => {
    if (props.active) {
      return css`
        top: 0;
        left: 0;
        width: 280px;
        height: 180px;
        border-radius: 0;
      `;
    } else if (!props.active) {
      return css`
        width: 0px;
        height: 0px;
      `;
    }
  }}
`;

const GrayCardImage = styled.img`
  width: 280px;
  height: 180px;
  z-index: 1;
`;

const CardImg = styled.img`
  width: 280px;
  height: 180px;
  z-index: 1;
`;

const CardNumber = styled.h4`
  position: absolute;
  left: 20px;
  top: 70px;
  font-weight: 300;
  letter-spacing: 1.3px;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  font-size: 19px;
  z-index: 3;
`;

const TitleCardName = styled.p`
  position: absolute;
  left: 20px;
  bottom: 32px;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  font-size: 10px;
  z-index: 3;
`;

const CardName = styled.p`
  position: absolute;
  left: 20px;
  bottom: 12px;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  font-size: 12px;
  z-index: 3;
  text-transform: uppercase;
`;

const TitleExpiryDate = styled.p`
  position: absolute;
  right: 20px;
  bottom: 32px;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  font-size: 10px;
  z-index: 3;
`;

const ExpiryDate = styled.p`
  position: absolute;
  right: 20px;
  bottom: 12px;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  font-size: 12px;
  z-index: 3;
`;

const TitleCardCVV = styled.p`
  position: absolute;
  right: 20px;
  top: 20px;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  font-size: 10px;
  z-index: 3;
`;

const CardCVV = styled.p`
  position: absolute;
  right: 20px;
  top: 35px;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  font-size: 12px;
  z-index: 3;
`;

class CardImage extends Component {
  state = {
    cardNumber: this.props.cardNumber,
    cardName: this.props.cardName,
    cardType: this.props.cardType,
    expiryDate: this.props.expiryDate,
    cardCVV: this.props.cardCVV,
    cardTypeCache: "",
    boolNumber: false,
    boolName: false,
    boolExpiryDate: false,
    boolCVV: false,
  };

  handleCirclesEffect = (value, circles) => {
    let rest = circles.substr(
      circles.length - (circles.length - value.length),
      circles.length - value.length
    );
    return value + rest;
  };

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        cardNumber: nextProps.cardNumber,
        cardName: nextProps.cardName,
        cardType: nextProps.cardType,
        expiryDate: nextProps.expiryDate,
        cardCVV: nextProps.cardCVV,
      },
      () => {
        if (nextProps.cardType !== "") {
          this.setState({
            cardTypeCache: nextProps.cardType,
          });
        }
        if (nextProps.cardNumber.length > 0) {
          let circles =
            this.props.cardType == "amex"
              ? "•••• •••••• •••••"
              : "•••• •••• •••• ••••";

          this.setState({
            boolNumber: true,
            cardNumber: this.handleCirclesEffect(nextProps.cardNumber, circles),
          });
        } else if (nextProps.cardNumber.length === 0) {
          this.setState({
            boolNumber: false,
          });
        }
        if (nextProps.cardName.length > 0) {
          this.setState({
            boolName: true,
          });
        } else if (nextProps.cardName.length === 0) {
          this.setState({
            boolName: false,
          });
        }
        if (nextProps.expiryDate.length > 0) {
          let circles = "••/••";
          this.setState({
            boolExpiryDate: true,
            expiryDate: this.handleCirclesEffect(nextProps.expiryDate, circles),
          });
        } else if (nextProps.expiryDate.length === 0) {
          this.setState({
            boolExpiryDate: false,
          });
        }
        if (nextProps.cardCVV.length > 0) {
          let circles = this.props.cardType == "amex" ? "••••" : "•••";
          this.setState({
            boolCVV: true,
            cardCVV: this.handleCirclesEffect(nextProps.cardCVV, circles),
          });
        } else if (nextProps.cardCVV.length === 0) {
          this.setState({
            boolCVV: false,
          });
        }
      }
    );
  }

  render() {
    return (
      <ImageContainerGeneral>
        <ImageContainerGray>
          <TitleCardName>Nombre del titular</TitleCardName>
          <TitleExpiryDate>Fecha de expiración</TitleExpiryDate>
          <TitleCardCVV>CVV</TitleCardCVV>
          <CardNumber>
            {this.state.boolNumber
              ? this.state.cardNumber
              : this.props.cardInitialNumber}
          </CardNumber>
          <CardName>
            {this.state.boolName
              ? this.state.cardName
              : this.props.cardInitialName}
          </CardName>
          <ExpiryDate>
            {this.state.boolExpiryDate
              ? this.state.expiryDate
              : this.props.cardInitialExpiryDate}
          </ExpiryDate>
          <CardCVV>
            {this.state.boolCVV
              ? this.state.cardCVV
              : this.props.cardInitialCVV}
          </CardCVV>

          <GrayCardImage src={GrayCardImg} />
        </ImageContainerGray>
        <ImageContainerNormal active={this.state.cardType !== ""}>
          {this.state.cardTypeCache === "visa" && <CardImg src={VisaCardImg} />}
          {this.state.cardTypeCache === "master" && (
            <CardImg src={MasterCardImg} />
          )}
          {this.state.cardTypeCache === "amex" && <CardImg src={AmexCardImg} />}
          {this.state.cardTypeCache === "diners" && (
            <CardImg src={DinersCardImg} />
          )}
        </ImageContainerNormal>
      </ImageContainerGeneral>
    );
  }
}

export default CardImage;
