import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";

const DetailsTopTabs = (props) => {
  const { productDetails } = props;
  const [formattedProductDetails, setFormattedProductDetails] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(
    () => {
      if (productDetails) {
        const { information, content } = productDetails;
        const newProductDetailsContent = Array.isArray(information) && information.map((info) => {
          const { display_name, key, type } = info;

          const value = Object.entries(content).find(
            ([contentKey, contentValue]) => contentKey === key
          );

          return {
            display_name,
            key,
            type,
            value: value[1],
          };
        });

        setFormattedProductDetails(newProductDetailsContent || []);
      }
    },
    [productDetails]
  );

  return (
    <section className="tab-product m-0">
      <div className="row">
        <div className="col-sm-12 col-lg-12">
          <Tabs
            className="tab-content nav-material"
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList className="nav nav-tabs nav-material">
              {formattedProductDetails.map((detail) => {
                return (
                  <Tab className="nav-item" key={detail.key}>
                    <span className="nav-link active">
                      {detail.display_name}
                    </span>
                    <div className="material-border" />
                  </Tab>
                );
              })}
            </TabList>
            {formattedProductDetails.map((element) => {
              return (
                <TabPanel key={element.key}>
                  <div
                    style={{ padding: "30px 0" }}
                    dangerouslySetInnerHTML={{
                      __html: element.value,
                    }}
                  />
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default DetailsTopTabs;
