import React from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";

const GOOGLE_MAPS_KEY = "AIzaSyAVMuVmXC6BSD0xnHC8ynEIWYgXMdQy0YU";

const Wrapper = styled.main`
  flex: 1;
`;

const GoogleMap = ({ children, ...props }) => {
  return (
    <Wrapper>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_MAPS_KEY,
        }}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </Wrapper>
  );
};

export default React.memo(GoogleMap);
