import React from "react";
import ContentLoader from "react-content-loader";

const ProductDetailLoader = props => (
  <ContentLoader
    speed={2}
    width={700}
    height={400}
    viewBox="0 0 700 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="5" y="13" rx="0" ry="0" width="283" height="376" />
    <rect x="109" y="207" rx="0" ry="0" width="12" height="0" />
    <rect x="303" y="17" rx="0" ry="0" width="292" height="18" />
    <rect x="303" y="51" rx="0" ry="0" width="151" height="18" />
    <rect x="303" y="159" rx="0" ry="0" width="250" height="8" />
    <rect x="303" y="327" rx="0" ry="0" width="155" height="40" />
    <rect x="303" y="90" rx="0" ry="0" width="110" height="33" />
    <rect x="303" y="175" rx="0" ry="0" width="250" height="8" />
    <rect x="303" y="190" rx="0" ry="0" width="250" height="8" />
    <rect x="303" y="239" rx="0" ry="0" width="140" height="30" />
  </ContentLoader>
);

export default ProductDetailLoader;
