import axios from "axios";
import { baseUrlAPIV5, baseUrlAPIV6 } from "../../constants";

export const createOrderClient = (payload, channel) =>
  axios.post(
    baseUrlAPIV5 + `/orders`,
    { ...payload },
    {
      headers: { "I-Channel": channel },
    }
  );

export const makePaymentOrder = (payload) =>
  axios.post(baseUrlAPIV5 + `/payments`, { ...payload });

export const getDetailsOrder = (orderCode, storeUuid) =>
  axios.get(`${baseUrlAPIV6}/orders/${orderCode}`, {
    headers: {
      'i-store': storeUuid,
    },
  });

export const getProductsOrderForOrderCode = (orderCode) =>
  axios.get(`${baseUrlAPIV6}/orders/${orderCode}/products`);