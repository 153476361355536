import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { CartContext } from "../../../../contexts/cart";

const ProductStyleFive = props => {
  const [prices, setPrices] = useState({
    price: 0,
    symbol: "S/",
    compare_to: 0,
    discount: 0
  });

  const { product, setOpen, setActiveProduct } = props;

  const { handleAddProduct } = useContext(CartContext);

  const productPrices = () => {
    const { product } = props;
    if (product.prices.data.length > 0) {
      const data = product.prices.data[0];
      const diff = data.compare_to > 0 ? data.compare_to - data.price : 0;
      const discount = diff > 0 ? (diff * 100) / data.compare_to : 0;

      setPrices({
        price: data.price,
        symbol: data.currency && data.currency.symbol,
        compare_to: data.compare_to,
        discount
      });
    }
  };

  useEffect(() => {
    if (product) {
      productPrices();
    }
  }, [product]);

  const onOpenModal = () => {
    setOpen(true);
    setActiveProduct(product);
    handleClickProduct();
  };

  const handleClickProduct = () => {
    if (product) {
      window.dataLayer.push({
        event: "productClick",
        ecommerce: {
          click: {
            actionField: {
              list: "Homepage - Productos destacados"
            },
            product: {
              id: product.uuid,
              name: product.name,
              price: prices.price,
              brand: product.vendor,
              category: product.category.name
            }
          }
        }
      });
    }
  };

  let RatingStars = [];
  for (var i = 0; i < product.rating; i++) {
    RatingStars.push(<i className="fa fa-star" key={i} />);
  }

  return (
    <div
      className={`product-box product-wrap ${!product.is_available &&
        "product-no-available"}`}
    >
      <div className="img-wrapper">
        <div className="lable-block">
          {prices.discount > 0 && (
            <span className="lable3">{prices.discount.toFixed(0)}%</span>
          )}
          {!product.is_available && (
            <span className="no-available-label">No disponible</span>
          )}
        </div>
        <div className="front">
          <Link
            to={`${process.env.PUBLIC_URL}/producto/${product.slug}`}
            onClick={handleClickProduct}
          >
            <img
              src={
                product.media && product.media[0]
                  ? product.media[0].conversions.original
                  : `${process.env.PUBLIC_URL}/assets/images/default/default_logo.png`
              }
              className="img-fluid"
              alt=""
            />
          </Link>
        </div>
        <div className="cart-box">
          {product.is_available && (
            <button
              title="Add to cart"
              onClick={() =>
                product.has_variants
                  ? onOpenModal()
                  : handleAddProduct(product, 1)
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icons/cart.svg`}
                style={{ width: "18px", margin: "0 8px" }}
                alt=""
              />
            </button>
          )}
          <a
            href="javascript:void(0)"
            data-toggle="modal"
            data-target="#quick-view"
            title="Quick View"
            onClick={onOpenModal}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icons/search.svg`}
              style={{ width: "18px", margin: "0 8px" }}
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="product-detail text-center">
        <div>
          {product.vendor && <span>{product.vendor}</span>}
          <Link
            to={`${process.env.PUBLIC_URL}/producto/${product.slug}`}
            onClick={handleClickProduct}
          >
            <h6>{product.name}</h6>
          </Link>
          <h4>{`${prices.symbol} ${prices.price}`}</h4>
        </div>
      </div>
    </div>
  );
};

export default ProductStyleFive;
