import axios from "axios";

import {
  slug_business,
  baseUrlAPIV5,
  baseUrlAPIV6,
} from "../../constants";
import { verifyVersion } from "../../utils/global_functions";

axios.interceptors.response.use((response) => {
  const version = response.headers["i-version"];
  if (version) {
    verifyVersion(version);
  }
  return response;
});

export const getCatalogsEndpoint = () =>
  axios.get(`${baseUrlAPIV5}/stores/${slug_business}/catalogs`, {
    headers: { "I-Channel": "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5" },
  });

export const getCategoriesEndpoint = (catalog) =>
  axios.get(`${baseUrlAPIV5}/stores/${slug_business}/categories/summary`, {
    headers: { "i-catalog": `${catalog[0].uuid}` },
  });

export const getProductByCategoryEndpoint = (slug_category, catalog, page) =>
  axios.get(
    `${baseUrlAPIV5}/stores/${slug_business}/categories/${slug_category}/products?page=${page}`,
    {
      headers: { "i-catalog": `${catalog[0].uuid}` },
    }
  );

export const getCategoryEndpoint = (slug_category, catalog) =>
  axios.get(
    `${baseUrlAPIV5}/stores/${slug_business}/categories/summary?parent=${slug_category}`,
    {
      headers: { "i-catalog": `${catalog[0].uuid}` },
    }
  );

export const getFeaturedProductsEndpoint = (catalog, page) =>
  axios.get(`${baseUrlAPIV5}/stores/${slug_business}/products/featured?page=${page}`, {
    headers: { "i-catalog": `${catalog[0].uuid}` },
  });

export const getRelatedProductsEndpoint = (productSlug, catalog) =>
  axios.get(
    `${baseUrlAPIV5}/stores/${slug_business}/products/${productSlug}/related`,
    {
      headers: { "i-catalog": `${catalog[0].uuid}` },
    }
  );

export const getProductEndpoint = (catalog, productId) =>
  axios.get(`${baseUrlAPIV5}/stores/${slug_business}/products/${productId}`, {
    headers: { "i-catalog": `${catalog[0].uuid}` },
  });

export const getProductSectionEndpoint = (productId) =>
  axios.get(`${baseUrlAPIV6}/products/${productId}/sections`);
