import React, { Component } from "react";

import CardImage from "../CardImage";
import BtnFinal from "../BtnFinal";
import logoMercagoPago from "../../assets/images/logo-mercadopago.png";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  font-family: "Krub", sans-serif;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const SubWrapper = styled.div`
  padding-bottom: 70px;
  position: relative;
  flex: 1;
`;

export const WrapperBody = styled.div`
  ${(props) => {
    if (!props.isMobile && !props.noPadding) {
      return css`
        padding: 0 20%;
      `;
    }
  }}
`;

export const FieldNormal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 15px 0px 15px;
  ${(props) => {
    if (props.isMobile == true && props.type === "two" && props.noPaddingTop) {
      return css`
        flex-direction: row;
        justify-content: space-between;
        padding-top: 0;
      `;
    }
    if (props.type === "two") {
      return css`
        flex-direction: row;
        justify-content: space-between;
      `;
    }
    if (props.noPaddingTop) {
      return css`
        padding-top: 0;
      `;
    }
  }}
`;

export const SubField = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => {
    if (props.isMobile) {
      return css`
        padding-top: 26px;
        width: 100%;
      `;
    } else {
      return css`
        width: 48%;
      `;
    }
  }}
`;

export const Header = styled.div`
  display: flex;
  padding: 10px 15px;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 1.2em;
  font-weight: 400;
`;

export const SubTitle = styled.h3`
  font-size: 13px;
  color: #4a4a4a;
  margin-bottom: 9px;
`;

export const Input = styled.input`
  font-family: "SFProDisplay", sans-serif;
  color: #4a4a4a;
  font-weight: 300;
  border-radius: 5px;
  border: 1px solid #b6c3d0;
  font-size: 16px;
  padding: 8px 12px;
  outline: none;
  letter-spacing: 1.3px;
  :hover {
    outline: none;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 30px 0;
`;

export const Errors = styled.div`
  color: #fa5752;
  font-weight: 300;
  text-align: center;
  font-size: 0.8em;
  ${(props) => {
    if (props.textAlignLeft) {
      return css`
        text-align: left;
      `;
    }
  }}
`;

export const LogoMercadoPagoImg = styled.img`
  width: 150px;
`;

class AddPayCard extends Component {
  state = {
    cardInitialNumber: "•••• •••• •••• ••••",
    cardInitialName: "TU NOMBRE",
    cardInitialExpiryDate: "••/••",
    cardInitialCVV: "•••",
    cardNumber: "",
    cardName: "",
    cardType: "",
    expiryDate: "",
    cardCVV: "",
    saveCard: true,
    errors: {
      cardNumber: "",
      cardName: "",
      expiryDate: "",
      cardCVV: "",
    },
    validators: {
      cardNumber: false,
      cardName: false,
      expiryDate: false,
      cardCVV: false,
    },
    isLoading: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isLoading !== state.isLoading) {
      return {
        isLoading: props.isLoading,
      };
    }
    return null;
  }

  validateAllFields = () => {
    this.validateCardNumber(this.state.cardNumber);
    this.validatorCardName(this.state.cardName);
    this.validatorExpiryDate(this.state.expiryDate);
    this.validatorCardCVV(this.state.cardCVV);

    if (
      this.state.validators.cardNumber &&
      this.state.validators.cardName &&
      this.state.validators.expiryDate &&
      this.state.validators.cardCVV
    ) {
      this.props.onValidCardAvailable({
        cardNumber: this.state.cardNumber,
        cardName: this.state.cardName,
        expiryDate: this.state.expiryDate,
        cardCVV: this.state.cardCVV,
        saveCard: this.state.saveCard,
        cardType: this.state.cardType,
      });
    }
  };

  validateCardNumber = (cardNumber) => {
    if (cardNumber.length === 0) {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardNumber: "Ingresa los dígitos de la tarjeta",
        },
        validators: {
          ...s.validators,
          cardNumber: false,
        },
      }));
    } else if (
      cardNumber.length <
      (this.state.cardType == "amex"
        ? 18
        : this.state.cardType == "diners"
        ? 17
        : 19)
    ) {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardNumber: "Ingresa todos los dígitos del número de la tarjeta",
        },
        validators: {
          ...s.validators,
          cardNumber: false,
        },
      }));
    } else if (cardNumber.length === 19 && this.state.cardType === "") {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardNumber: "Tipo de tarjeta no válida",
        },
        validators: {
          ...s.validators,
          cardNumber: false,
        },
      }));
    } else {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardNumber: "",
        },
        validators: {
          ...s.validators,
          cardNumber: true,
        },
      }));
    }
  };

  validatorCardName = (cardName) => {
    if (cardName.length === 0) {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardName: "Ingresa el nombre del titular de la tarjeta",
        },
        validators: {
          ...s.validators,
          cardName: false,
        },
      }));
    } else {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardName: "",
        },
        validators: {
          ...s.validators,
          cardName: true,
        },
      }));
    }
  };

  validatorExpiryDate = (expiryDate) => {
    if (expiryDate.length === 0) {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          expiryDate: "Ingresa la fecha de expiración de la tarjeta",
        },
        validators: {
          ...s.validators,
          expiryDate: false,
        },
      }));
    } else if (expiryDate.length < 5) {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          expiryDate: "Ingresa toda la fecha de expiración de la tarjeta",
        },
        validators: {
          ...s.validators,
          expiryDate: false,
        },
      }));
    } else {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          expiryDate: "",
        },
        validators: {
          ...s.validators,
          expiryDate: true,
        },
      }));
    }
  };

  validatorCardCVV = (cardCVV) => {
    if (cardCVV.length === 0) {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardCVV: "Ingresa el CVV de la tarjeta",
        },
        validators: {
          ...s.validators,
          cardCVV: false,
        },
      }));
    } else if (cardCVV.length < 3) {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardCVV: "Ingresa todos los digitos CVV de la tarjeta",
        },
        validators: {
          ...s.validators,
          cardCVV: false,
        },
      }));
    } else {
      this.setState((s) => ({
        errors: {
          ...s.errors,
          cardCVV: "",
        },
        validators: {
          ...s.validators,
          cardCVV: true,
        },
      }));
    }
  };

  handleCardNumberFormat = (value) => {
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches =
      this.state.cardType == "amex"
        ? v.match(/\d{4,15}/g)
        : v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  };

  handleCardNumber = (e) => {
    let number = e.target.value;

    if (
      !/^[0-9]$/.test(number.substr(-1, 1)) &&
      number.length > this.state.cardNumber.length
    ) {
      return null;
    }

    number = this.handleCardNumberFormat(number);
    if (
      (number.length === 4 || number.length === 9 || number.length === 14) &&
      this.state.cardNumber.length < number.length
    ) {
      number += " ";
    }
    if (number.length > 0) {
      const firstNumber = number.substr(0, 1);
      const secondNumber = number.substr(1, 1);
      if (firstNumber === "4") {
        this.setState({
          cardNumber: number,
          cardType: "visa",
          cardInitialCVV: "•••",
          cardCVV: "",
        });
      } else if (
        firstNumber === "3" &&
        (secondNumber === "4" || secondNumber === "7")
      ) {
        this.setState({
          cardNumber: number,
          cardType: "amex",
          cardInitialCVV: "••••",
        });
      } else if (firstNumber === "3") {
        this.setState({
          cardNumber: number,
          cardType: "diners",
          cardInitialCVV: "•••",
        });
      } else if (firstNumber === "5") {
        if (number.length > 1) {
          if (
            secondNumber === "1" ||
            secondNumber === "2" ||
            secondNumber === "3" ||
            secondNumber === "4" ||
            secondNumber === "5"
          ) {
            this.setState({
              cardNumber: number,
              cardType: "master",
              cardInitialCVV: "•••",
              cardCVV: "",
            });
          } else {
            this.setState({
              cardNumber: number,
              cardType: "",
            });
          }
        } else {
          this.setState({
            cardNumber: number,
            cardType: "",
          });
        }
      } else {
        this.setState({
          cardNumber: number,
          cardType: "",
        });
      }
    } else {
      this.setState({
        cardNumber: number,
        cardType: "",
      });
    }
    this.validateCardNumber(number);
  };

  handleCardName = (e) => {
    let name = e.target.value;
    name.toUpperCase();
    let expre = /^[a-zA-Z]|[" "]$/;
    if (
      name.length > 30 ||
      (!expre.test(name.substr(name.length - 1, 1)) &&
        this.state.cardName.length < name.length)
    ) {
      return false;
    }
    this.setState({
      cardName: name,
    });
    this.validatorCardName(name);
  };

  handleExpiryDate = (e) => {
    let expiry = e.target.value;
    let expre = /^[0-9]|["/"]$/;
    if (
      expiry.length > 5 ||
      (!expre.test(expiry.substr(expiry.length - 1, 1)) &&
        this.state.expiryDate.length < expiry.length)
    ) {
      return false;
    }
    if (
      expiry.length === 1 &&
      expiry.substr(0, 1) !== "1" &&
      expiry.substr(0, 1) !== "0" &&
      this.state.expiryDate < expiry.length
    ) {
      expiry = "0" + expiry.substr(0, 10);
    }
    if (expiry.length === 3 && this.state.expiryDate.length > expiry.length) {
      expiry = expiry.substr(0, 2);
    }
    if (expiry.length === 2 && this.state.expiryDate.length < expiry.length) {
      if (
        (expiry.substr(0, 1) == 1 && expiry.substr(-1, 1) > 2) ||
        (expiry.substr(0, 1) == 0 && expiry.substr(-1, 1) == 0)
      ) {
        return null;
      }
      expiry += "/";
    }
    if (
      expiry.length === 3 &&
      this.state.expiryDate.length < expiry.length &&
      expiry.substr(2, 1) !== "/"
    ) {
      let aux = expiry.substr(0, 2);
      aux += "/";
      expiry = aux + expiry.substr(2, 1);
    }
    if (expiry.length === 4) {
      if (expiry.substr(3, 1) < 1) {
        return null;
      }
    }
    if (expiry.length === 5) {
      if (expiry.substr(3, 1) == 1 && expiry.substr(4, 1) < 9) {
        return null;
      }
    }
    this.setState({
      expiryDate: expiry,
    });
    this.validatorExpiryDate(expiry);
  };

  handleCardCVV = (e) => {
    let cvv = e.target.value;
    let expre = /^[0-9]$/;
    if (
      cvv.length > (this.state.cardType == "amex" ? 4 : 3) ||
      (!expre.test(cvv.substr(cvv.length - 1, 1)) &&
        this.state.cardCVV.length < cvv.length)
    ) {
      return false;
    }
    this.setState({
      cardCVV: cvv,
    });
    this.validatorCardCVV(cvv);
  };

  handleChangeCheckbox = () => {
    this.setState((s) => {
      return {
        saveCard: !s.saveCard,
      };
    });
  };

  render() {
    return (
      <Wrapper data-private>
        <Header>
          <Title>Ingresa tu tarjeta</Title>
        </Header>
        <SubWrapper>
          <WrapperBody
            noPadding={this.props.noPadding}
            isMobile={this.props.isMobile}
          >
            <CardContainer>
              <CardImage
                cardInitialNumber={this.state.cardInitialNumber}
                cardInitialName={this.state.cardInitialName}
                cardInitialExpiryDate={this.state.cardInitialExpiryDate}
                cardInitialCVV={this.state.cardInitialCVV}
                cardNumber={this.state.cardNumber}
                cardName={this.state.cardName}
                expiryDate={this.state.expiryDate}
                cardCVV={this.state.cardCVV}
                cardType={this.state.cardType}
              />
            </CardContainer>
            <FieldNormal>
              <SubTitle>NÚMERO DE TARJETA</SubTitle>
              <Input
                placeholder="•••• •••• •••• ••••"
                onChange={this.handleCardNumber}
                value={this.state.cardNumber}
                data-private="redact"
              />
              {this.state.errors.cardNumber && (
                <Errors>{this.state.errors.cardNumber}</Errors>
              )}
            </FieldNormal>
            <FieldNormal>
              <SubTitle>TITULAR (NOMBRE DE LA TARJETA)</SubTitle>
              <Input
                placeholder="JOHN DOE"
                onChange={this.handleCardName}
                value={this.state.cardName}
                style={{ textTransform: "uppercase" }}
              />
              {this.state.errors.cardName && (
                <Errors>{this.state.errors.cardName}</Errors>
              )}
            </FieldNormal>
            <FieldNormal
              type={this.props.isMobile ? "" : "two"}
              isMobile={this.props.isMobile}
              noPaddingTop
            >
              <SubField isMobile={this.props.isMobile}>
                <SubTitle>FECHA DE EXPIRACIÓN (MM/YY)</SubTitle>
                <Input
                  placeholder="MM/YY"
                  onChange={this.handleExpiryDate}
                  value={this.state.expiryDate}
                  data-private="redact"
                />
                {this.state.errors.expiryDate && (
                  <Errors textAlignLeft={true}>
                    {this.state.errors.expiryDate}
                  </Errors>
                )}
              </SubField>
              <SubField isMobile={this.props.isMobile}>
                <SubTitle>CÓDIGO DE SEGURIDAD</SubTitle>
                <Input
                  placeholder="CVV"
                  onChange={this.handleCardCVV}
                  value={this.state.cardCVV}
                  data-private="redact"
                />
                {this.state.errors.cardCVV && (
                  <Errors textAlignLeft={true}>
                    {this.state.errors.cardCVV}
                  </Errors>
                )}
              </SubField>
            </FieldNormal>
            <FieldNormal
              type={this.props.isMobile ? "" : "two"}
              isMobile={this.props.isMobile}
              noPaddingTop
            >
              <SubField
                isMobile={this.props.isMobile}
                style={{ alignItems: "center", marginTop: "5px" }}
              >
                <SubTitle>Pago seguro con: </SubTitle>
                <LogoMercadoPagoImg src={logoMercagoPago} />
              </SubField>
            </FieldNormal>
          </WrapperBody>
          <BtnFinal
            onClick={this.validateAllFields}
            disabled={this.state.isLoading}
          >
            {this.state.isLoading ? "CARGANDO..." : "CONTINUAR"}
          </BtnFinal>
        </SubWrapper>
      </Wrapper>
    );
  }
}

export default AddPayCard;
