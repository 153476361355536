import axios from "axios";
import store from "store";
import dayjs from "dayjs";

import InfoBrand from "../BusinessApi";
import { CATALOGS_STORE } from "../../constants/ActionTypes";
import { baseUrlAPIV5, baseUrlAPIV6 } from "../../constants";

const infoBrand = new InfoBrand();

class ProductsApi {
  async getCatalogBySlug(slug_business) {
    const infoCatalog = await store.get(CATALOGS_STORE);

    const dateLS = !infoCatalog
      ? dayjs(new Date()).format("YYYY-MM-DD")
      : dayjs(infoCatalog[0].petition_date).format("YYYY-MM-DD");
    const dateCurrent = dayjs(new Date()).format("YYYY-MM-DD");

    if (
      !infoCatalog ||
      !infoCatalog[0].belongs_to ||
      infoCatalog[0].belongs_to !== slug_business ||
      dayjs(dateLS).isBefore(dateCurrent)
    ) {
      const responseCatalog = await axios.get(
        `${baseUrlAPIV5}/stores/${slug_business}/catalogs`,
        {
          headers: { "I-Channel": "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5" },
        }
      );

      const { data } = responseCatalog.data;

      const responsedata = data.map((item) =>
        Object.assign(item, {
          belongs_to: slug_business,
          petition_date: new Date(),
        })
      );

      store.set(CATALOGS_STORE, responsedata);
      return responsedata;
    }

    return infoCatalog;
  }

  async getAllProductForCategoriesBySlug(slug) {
    const cat = await this.getCatalogBySlug(slug);
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${slug}/categories/summary`,
      {
        headers: { "I-Catalog": cat[0].uuid },
      }
    );
    return response.data.data;
  }

  async getAllProductForCategoriesWithParent({ business_slug, category_uuid }) {
    const cat = await this.getCatalogBySlug(business_slug);
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${business_slug}/categories/summary?parent=${category_uuid}`,
      {
        headers: { "I-Catalog": cat[0].uuid },
      }
    );
    return response.data.data;
  }

  async getAllProductsOfChild({ business_slug, category_uuid }) {
    const cat = await this.getCatalogBySlug(business_slug);
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${business_slug}/categories/${category_uuid}/products`,
      {
        headers: { "I-Catalog": cat[0].uuid },
      }
    );
    const finalResponse = {
      products: response.data.data,
      link_next: response.data.links.next,
    };

    return finalResponse;
  }

  async getDetailsProductForStore({ business_slug, product_uuid }) {
    const cat = await this.getCatalogBySlug(business_slug);

    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${business_slug}/products/${product_uuid}`,
      {
        headers: { "I-Catalog": cat[0].uuid },
      }
    );
    const { data } = response.data;

    const related = await axios.get(
      `${baseUrlAPIV5}/stores/${business_slug}/products/${product_uuid}/related`,
      {
        headers: { "I-Catalog": cat[0].uuid },
      }
    );

    const finalResponse = {
      product: data,
      prices: data.prices.data,
      media: data.media.data,
      related: related.data.data.length > 0 ? related.data.data : null,
    };

    return finalResponse;
  }

  async getToppingsOfProduct({ business_slug, product_uuid }) {
    const cat = await this.getCatalogBySlug(business_slug);

    const response = await axios.get(
      `${baseUrlAPIV5}/products/${product_uuid}/group-toppings`,
      { headers: { "I-Catalog": cat[0].uuid } }
    );

    return response.data.data;
  }

  async searchByTerm({ slug_business, term }) {
    const cat = await this.getCatalogBySlug(slug_business);
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${slug_business}/products/search?name=${term}`,
      {
        headers: { "I-Catalog": cat[0].uuid },
      }
    );

    return response.data.data;
  }

  // TODO:
  async getProductsFeaturedForSlug(business_slug) {
    const cat = await this.getCatalogBySlug(business_slug);
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${business_slug}/products/featured`,
      {
        headers: { "I-Catalog": cat[0].uuid },
      }
    );

    console.log(response);
  }

  async getCategoriesListingForSlug(business_slug) {
    const cat = await this.getCatalogBySlug(business_slug);
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${business_slug}/categories/listing`,
      {
        headers: { "I-Catalog": cat[0].uuid },
      }
    );

    return response.data.data;
  }

  async getStock({ slug_business, product_uuid, indexCart }) {
    const brand = await infoBrand.getBrandInformations({
      business_slug: slug_business,
      channel: "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5",
    });

    const response = await axios.get(
      `${baseUrlAPIV6}/products/${product_uuid}/stock`,
      {
        headers: {
          "I-Store": brand && brand.uuid,
          "I-Channel": "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5",
        },
      }
    );

    const object = {
      uuid: product_uuid,
      stock: Number(response.data.stock),
    };

    if (indexCart === 0 || (indexCart && indexCart > 0)) {
      Object.assign(object, { indexCart });
    }
    return object;
  }
}

export default ProductsApi;
