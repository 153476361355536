import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 10px;
`;

const ContainerSearchBox = styled.div`
  display: flex;
  align-content: center;
  width: 100%;
  flex-direction: column;
  div {
    display: flex;
    flex: 1;
  }

  input {
    width: 100%;
    padding: 0 22px;
    height: 45px;
    border: 1px solid #dddddd;
    font-family: "Krub", sans-serif;
  }
`;

const ButtonGetLocation = styled.button`
  border: none;
  outline: none;
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background: #2ecc71;
  padding: 10px 24px;
  font-size: 12px;
  color: #ffffff;
  font-family: "Krub", sans-serif;
  svg {
    display: inline-block;
    margin-right: 10px;
    color: #ffffff;
  }
`;

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount({ map, mapApi } = this.props) {
    this.searchBox = new mapApi.places.SearchBox(this.searchInput);
    this.searchBox.addListener("places_changed", this.onPlacesChanged);
    this.searchBox.bindTo("bounds", map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlacesChanged = ({ map, addplace } = this.props) => {
    const selected = this.searchBox.getPlaces();
    const { 0: place } = selected;
    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addplace(selected);
    this.searchInput.blur();
  };

  clearSearchBox() {
    this.searchInput.value = "";
  }

  componentWillReceiveProps(newProps) {
    if (newProps.direction !== this.props.direction) {
      this.searchInput.value = newProps.direction;
    }
  }

  render() {
    return (
      <Wrapper>
        <ContainerSearchBox>
          <input
            ref={(ref) => {
              this.searchInput = ref;
            }}
            defaultValue={this.props.direction || ""}
            type="search"
            placeholder="Ej: Av. San Martin 154, Barranco, Lima"
            autoComplete="off"
          />
        </ContainerSearchBox>
        <p
          style={{
            fontSize: "11px",
            color: "#222",
            textAlign: "center",
            marginTop: "5px",
          }}
        >
          Puedes mejorar tu ubicación presionando en el mapa.
        </p>
      </Wrapper>
    );
  }
}

export default React.memo(SearchBox);
