import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-responsive-modal";
import "./style.css";

import env from "../../../constants/env";
const BASE_APIv5 = env.BASE_APIv5;
const BASE_APIv6 = env.BASE_APIv6;

const getBrandInfoEndpoint = (business) =>
  axios.get(`${BASE_APIv5}/brands/${business}`);

const createClaim = (payload, store) =>
  axios.post(`${BASE_APIv6}/claims`, payload, {
    headers: {
      "I-Store": store,
    },
  });

const ClaimsForm = (props) => {
  const [brand, setBrand] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [department, setDepartment] = useState(null);
  const [province, setProvince] = useState(null);
  const [district, setDistrict] = useState(null);
  const [inputs, setInputs] = useState({});
  const [isMinor, setIsMinor] = useState(false);
  const [parentName, setParentName] = useState(null);
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false);
  const [agreeServiceOwner, setAgreeServiceOwner] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)

  const { slug_business } = props.match.params;

  const getDepartments = () => {
    fetch("departments.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        setDepartments(data);
        setDepartment(data[0].nombre_ubigeo);
      });
  };

  const getProvinces = (department) => {
    fetch("provinces.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        const parent = departments.find(
          (dep) => dep.nombre_ubigeo === department
        );
        const filterData = Object.entries(data).find(
          ([key]) => key === parent.id_ubigeo
        );

        setProvinces(filterData[1]);
      });
  };

  const getDistricts = (province) => {
    fetch("districts.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        const parent = provinces.find(
          (prov) => prov.nombre_ubigeo === province
        );
        const filterData = Object.entries(data).find(
          ([key]) => key === parent.id_ubigeo
        );
        setDistricts(filterData[1]);
      });
  };

  const getBrandInfo = async () => {
    try {
      const res = await getBrandInfoEndpoint(slug_business);

      if (res) {
        setBrand(res.data.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    getBrandInfo();
    getDepartments();

    setInputs((state) => ({
      ...state,
      documentType: "dni",
    }));

    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }, []);

  useEffect(
    () => {
      if (department) {
        getProvinces(department);
      }
    },
    [department]
  );

  useEffect(
    () => {
      if (province) {
        getDistricts(province);
      }
    },
    [province]
  );

  useEffect(
    () => {
      if (provinces.length > 0) {
        setProvince(provinces[0].nombre_ubigeo);
      }
    },
    [provinces]
  );

  useEffect(
    () => {
      if (districts.length > 0) {
        setDistrict(districts[0].nombre_ubigeo);
      }
    },
    [districts]
  );

  useEffect(() => {
    if (loading) {
      document.querySelector(".loader-wrapper").style = "display: block; background-color: rgba(255,255,255,.3);";
    } else {
      document.querySelector(".loader-wrapper").style = "display: none";
    }
  }, [loading])

  const onChangeSelectDepartment = (e) => {
    setDepartment(e.target.value);
  };

  const onChangeSelectProvince = (e) => {
    setProvince(e.target.value);
  };

  const onChangeSelectDistrict = (e) => {
    setDistrict(e.target.value);
  };

  const onChangeInputs = (e) => {
    const { name, value } = e.target;

    setInputs((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onChangeMinor = (e) => {
    setIsMinor(e.target.checked);

    if (!e.target.checked) {
      setParentName("");
    }
  };

  const onChangeParentName = (e) => {
    setParentName(e.target.value);
  };

  const onChangeAgreeWithTerms = (e) => {
    setAgreeWithTerms(e.target.checked);
  };

  const onChangeAgreePrivacyPolicy = (e) => {
    setAgreePrivacyPolicy(e.target.checked);
  };

  const onChangeAgreeServiceOwner = (e) => {
    setAgreeServiceOwner(e.target.checked);
  };

  const onSendClaim = async () => {
    setLoading(true)
    const {
      name,
      last_name,
      email,
      phone,
      documentType,
      documentNumber,
      address,
      well_hired,
      date,
      reclaimed_amount,
      description,
      type,
      detail,
      request,
    } = inputs;

    const payload = {
      first_name: name,
      last_name,
      email,
      phone,
      identification_type: documentType,
      identification_value: documentNumber,
      is_minor: isMinor,
      department,
      province,
      district,
      address,
      well_hired,
      claim_date: date,
      reclaimed_amount,
      description,
      type,
      detail,
      claimant_request: request,
    };

    if (isMinor && parentName) {
      payload.parent_name = parentName;
    }

    try {
      await createClaim(payload, brand.uuid);
      setError(false);
      setModalTitle("Reclamo enviado con éxito");
      setModalMessage("En un plazo no mayor a 30 días, nos comunicaremos contigo por medio de correo electrónico.");
      setOpenModal(true);
    } catch (error) {
      setLoading(false)
      setError(true);
      setModalTitle(error.response.data.message);
      setModalMessage("Vuelve a intentarlo");
      setOpenModal(true);
    } finally {
      setInputs({
        name: "",
        last_name: "",
        email: "",
        phone: "",
        documentType: "",
        documentNumber: "",
        address: "",
        well_hired: "",
        date: "",
        reclaimed_amount: "",
        description: "",
        type: "",
        detail: "",
        request: "",
      })
      setParentName("")
      setIsMinor(false)
      setAgreePrivacyPolicy(false)
      setAgreeServiceOwner(false)
      setAgreeWithTerms(false)
      setDepartment(departments[0].nombre_ubigeo)

      setLoading(false)
    }
  };

  return (
    <div className="custom-container">
      <div className="custom-header">
        <div>
          <h1>Libro de reclamaciones</h1>
          <h2>{brand && brand.name}</h2>
          <div className="organization-info">
            <p>Razón social: {brand && brand.organization.name}</p>
            <p>
              {brand && brand.organization.identification.type}:{" "}
              {brand && brand.organization.identification.value}
            </p>
            <p>Dirección: {brand && brand.organization.description}</p>
          </div>
        </div>
        <div>
          <img src={brand && brand.logo} alt={brand && brand.name} />
        </div>
      </div>
      <div className="subtitle">
        1. Identificación del Consumidor Reclamante
      </div>
      <div className="form-double">
        <div className="custom-input-group">
          <label>Nombres*</label>
          <input
            name="name"
            type="text"
            onChange={onChangeInputs}
            value={inputs.name}
          />
        </div>
        <div className="custom-input-group">
          <label>Apellidos*</label>
          <input
            name="last_name"
            type="text"
            onChange={onChangeInputs}
            value={inputs.last_name}
          />
        </div>
        <div className="custom-input-group">
          <label>Correo*</label>
          <input
            name="email"
            type="email"
            onChange={onChangeInputs}
            value={inputs.email}
          />
        </div>
        <div className="custom-input-group">
          <label>Teléfono*</label>
          <input
            name="phone"
            type="tel"
            onChange={onChangeInputs}
            value={inputs.phone}
          />
        </div>
        <div className="custom-input-group">
          <label>Tipo de documento*</label>
          <select
            name="documentType"
            onChange={onChangeInputs}
            value={inputs.documentType}
          >
            <option value="dni">DNI</option>
            <option value="foreigners_card">Carné de Extranjería</option>
            <option value="passport">Pasaporte</option>
            <option value="ruc">RUC</option>
          </select>
        </div>
        <div className="custom-input-group">
          <label>Número de documento*</label>
          <input
            name="documentNumber"
            type="text"
            onChange={onChangeInputs}
            value={inputs.documentNumber}
          />
        </div>
      </div>
      <div className="form-single">
        <div className="checkbox-group">
          <div className="custom-input-group">
            <input
              name="isMinor"
              checked={isMinor}
              type="checkbox"
              onChange={onChangeMinor}
            />
            <label>¿Es menor de edad?</label>
          </div>
        </div>
        {isMinor &&
          <div className="custom-input-group">
            <label>Nombre de padre o madre</label>
            <input
              name="parentName"
              type="text"
              disabled={!isMinor}
              value={parentName}
              onChange={onChangeParentName}
            />
          </div>
        }
      </div>
      <div className="form-double">
        <div className="custom-input-group">
          <label>Departamento*</label>
          <select onChange={onChangeSelectDepartment} value={department}>
            {departments &&
              departments.map((dep) => {
                return (
                  <option key={dep.id_ubigeo} value={dep.nombre_ubigeo}>
                    {dep.nombre_ubigeo}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="custom-input-group">
          <label>Provincia*</label>
          <select onChange={onChangeSelectProvince} value={province}>
            {provinces &&
              provinces.map((prov) => {
                return (
                  <option key={prov.id_ubigeo} value={prov.nombre_ubigeo}>
                    {prov.nombre_ubigeo}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="custom-input-group">
          <label>Distrito*</label>
          <select onChange={onChangeSelectDistrict} value={district}>
            {districts &&
              districts.map((dist) => {
                return (
                  <option key={dist.id_ubigeo} value={dist.nombre_ubigeo}>
                    {dist.nombre_ubigeo}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="custom-input-group">
          <label>Dirección*</label>
          <input
            name="address"
            type="text"
            onChange={onChangeInputs}
            value={inputs.address}
          />
        </div>
      </div>

      <div className="subtitle">2. Identificación del Bien Contratado</div>
      <div className="form-single">
        <div className="radio-group">
          <div className="custom-input-group">
            <input
              type="radio"
              id="product"
              value="product"
              name="well_hired"
              onChange={onChangeInputs}
            />
            <label for="product">Producto</label>
          </div>
          <div className="custom-input-group">
            <input
              type="radio"
              id="service"
              value="service"
              name="well_hired"
              onChange={onChangeInputs}
            />
            <label for="service">Servicio</label>
          </div>
        </div>
      </div>
      <div className="form-double">
        <div className="custom-input-group">
          <label>Fecha*</label>
          <input
            name="date"
            type="date"
            onChange={onChangeInputs}
            value={inputs.date}
          />
        </div>
        <div className="custom-input-group">
          <label>Monto reclamado*</label>
          <input
            name="reclaimed_amount"
            type="number"
            onChange={onChangeInputs}
            value={inputs.reclaimed_amount}
          />
        </div>
      </div>
      <div className="form-single">
        <div className="custom-input-group">
          <label>Descripción*</label>
          <textarea
            name="description"
            value={inputs.description}
            onChange={onChangeInputs}
          />
        </div>
      </div>

      <div className="subtitle">
        3. Detalle de la Reclamación y Pedido del Consumidor
      </div>
      <div className="form-single">
        <div className="radio-group">
          <div className="custom-input-group custom-tooltip">
            <span className="custom-tooltip-text">
              Disconformidad relacionada a los productos o servicios.
            </span>
            <input
              type="radio"
              name="type"
              id="claim"
              value="claim"
              onChange={onChangeInputs}
            />
            <label for="claim">Reclamo</label>
          </div>
          <div className="custom-input-group custom-tooltip">
            <span className="custom-tooltip-text">
              Disconformidad no relacionada a los productos o servicios o
              malestar o descontento respecto a la atención al público. La
              formulación del reclamo no impide acudir a otras vías de solución
              de controversias ni es requisito previo para interponer una
              denuncia ante el INDECOPI.
            </span>
            <input
              type="radio"
              name="type"
              id="complaint"
              value="complaint"
              onChange={onChangeInputs}
            />
            <label for="complaint">Queja</label>
          </div>
        </div>
      </div>
      <div className="form-single">
        <div className="custom-input-group">
          <label>Detalle*</label>
          <textarea
            name="detail"
            value={inputs.detail}
            onChange={onChangeInputs}
          />
        </div>
      </div>
      <div className="form-single">
        <div className="custom-input-group">
          <label>Pedido del reclamante*</label>
          <textarea
            name="request"
            value={inputs.request}
            onChange={onChangeInputs}
          />
        </div>
      </div>
      <div className="form-single">
        <div className="checkbox-group">
          <div className="custom-input-group">
            <input
              type="checkbox"
              checked={agreeWithTerms}
              onChange={onChangeAgreeWithTerms}
            />
            <label>
              Me encuentro conforme con los términos de mi reclado o queja
            </label>
          </div>
        </div>
      </div>

      <div className="form-single">
        <div className="checkbox-group">
          <div className="custom-input-group">
            <input
              type="checkbox"
              checked={agreeServiceOwner}
              onChange={onChangeAgreeServiceOwner}
            />
            <label>
              Declaro ser el titular del servicio y acepto el contenido del
              presente formulario, manifestando bajo Declaración Jurada la
              veracidad de los hechos descritos.
            </label>
          </div>
        </div>
        <ul className="list">
          <li>
            La formulación del reclamo no impide acudir a otras vías de solución
            de controversias ni es requisito previo para interponer una denuncia
            ante Indecopi.
          </li>
          <li>
            El proveedor deberá dar respuesta al reclamo en un plazo no mayor a
            treinta (30) días calendario, pudiendo ampliar el plazo hasta por
            treinta días.
          </li>
          <li>
            Mediante la suscripción del presente documento, el cliente autoriza
            a que lo contacten luego de atendido el reclado a fin de evaluar la
            calidad y satisfacción con el proceso de atención de reclamos.
          </li>
        </ul>
      </div>

      <div className="form-single">
        <div className="checkbox-group">
          <div className="custom-input-group">
            <input
              type="checkbox"
              checked={agreePrivacyPolicy}
              onChange={onChangeAgreePrivacyPolicy}
            />
            <label>
              He Leído y acepto la Política de Privacidad y Seguridad y la
              Política de Cookies
            </label>
          </div>
        </div>
      </div>

      <div className="note">
        <p>
          El provedor deberá dar respuesta al reclamo en un plazo no mayor de
          treinta (30) días calendario, pudiendo ampliar el plazo hasta por
          treinta (30) días más, previa comunicación al consumidor.
        </p>
      </div>

      <div>
        <button
          type="submit"
          className="custom-button"
          disabled={
            !agreeWithTerms || !agreePrivacyPolicy || !agreeServiceOwner || loading
          }
          onClick={onSendClaim}
        >
          Enviar
        </button>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)} center>
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content quick-view-modal">
            <div className="modal-body">
              <div className="modal-add-to-cart-header">
                <h3>{modalTitle}</h3>
                <small>{modalMessage}</small>
              </div>
              {!error && (
                <div className="modal-add-to-cart-buttons">
                  <a
                    href={`${process.env.PUBLIC_URL}/`}
                    className="btn btn-solid white-btn"
                  >
                    Volver al inicio
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ClaimsForm;
