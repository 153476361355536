import React, { useState, useCallback, useEffect } from "react";

import styled from "styled-components";
import SimpleMap from "./SimpleMap/index.jsx";

export const CardSC = styled.div`
  background-color: #ffffff;
  width: 100%;
  // max-width: 540px;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  > svg {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    padding: 24px 0;
  }
`;

export const TitleSC = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 115%;
  color: #ce9cf0;
  font-family: "Krub", sans-serif;
  text-align: center;
`;

export const WrappMapSC = styled.div`
  width: 100%;
  min-width: 300px;
  max-width: 80vw;
  height: auto;
  border-radius: 13px;
  margin: 0px 0px 20px 0px;
  overflow: hidden;

  @media (max-width: 767px) {
    min-width: 100%;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  // width: 500px;
  max-width: 100%;
  height: 500px;
  max-height: 50vh;
`;

export const WrappMoreDetails = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px 15px;
  font-family: Poppins;
  font-size: 12px;

  div.number {
    width: 100%;

    p {
      margin: 0;
      font-family: "Krub", sans-serif;
    }

    input {
      margin-top: 10px;
      background-color: #f5f5f5;
      border: none;
      outline: none;
      width: 100%;
      max-width: 100px;
      color: #ce9cf0;
      padding: 12px 20px;
      border-radius: 10px;
    }
  }

  div.details {
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-family: "Krub", sans-serif;
    }

    input {
      flex: 1;
      margin-top: 10px;
      background-color: #f5f5f5;
      border: none;
      outline: none;
      color: #151e5a;
      padding: 12px 20px;
      border-radius: 10px;
    }
  }
`;

export const ContainerButton = styled.div`
  width: 50%;

  @media (max-width: 767px) {
    width: 80%;
  }

  button {
    padding: 12px 0;
    width: 100%;
    height: 44px;
  }
`;

const SearchUbiGoogle = (props) => {
  const { title, handleSelectDirection, ubication, active, setShowMap } = props;
  const [direction, setDirection] = useState({});
  const [details, setDetails] = useState("");
  const [number, setNumber] = useState("");
  const [bolNumber, setBolNumber] = useState(true);

  useEffect(
    () => {
      if (ubication && ubication.lat) {
        const ub = ubication && ubication.direction;
        const ins = ubication && ubication.instructions;
        const direc = {
          ...ubication,
          direction: ub,
          instructions: ins,
        };

        setDirection(direc);
        setDetails(ins);
      }
    },
    [ubication]
  );

  const handleSubmitChangeDirection = useCallback(
    () => {
      // if (!details) return;
      const splitDirection =
        direction && direction.direction && direction.direction.split(",");
      const newFomartDirection =
        splitDirection &&
        `${splitDirection[0]} ${number}, ${splitDirection[1]}, ${
          splitDirection[2]
        }`;

      const newDirection = {
        ...direction,
        direction:
          bolNumber === false ? newFomartDirection : direction.direction,
        instructions: details,
      };

      setBolNumber(true);
      setNumber("");
      handleSelectDirection(newDirection);
    },
    [direction, number, bolNumber, details, handleSelectDirection]
  );

  const handleBolNumber = useCallback((value) => {
    if (value === false) {
      setNumber("");
    }
    setBolNumber(value);
  }, []);

  return (
    <CardSC>
      <TitleSC>{title}</TitleSC>
      <WrappMapSC>
        <WrapperContent>
          <SimpleMap
            handleDirection={setDirection}
            ubication={direction}
            setBolNumber={handleBolNumber}
          />
        </WrapperContent>
        {bolNumber === false && <small>(*) Datos Obligatorios</small>}
        <WrappMoreDetails>
          {bolNumber === false && (
            <div className="number">
              <p>
                Número<strong>*</strong>
              </p>
              <input
                name="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                autoComplete="off"
              />
            </div>
          )}

          <div className="details">
            <p>Dpto / Interior / Referencias</p>
            <input
              name="details"
              placeholder="Opcional"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              autoComplete="off"
            />
          </div>
        </WrappMoreDetails>
      </WrappMapSC>
      <ContainerButton>
        <button
          type="button"
          className="btn-solid btn"
          onClick={handleSubmitChangeDirection}
          disabled={
            bolNumber
              ? !direction.direction
              : !direction.direction || !(number.trim() && bolNumber === false)
          }
        >
          Confirmar Ubicación
        </button>
      </ContainerButton>
    </CardSC>
  );
};

export default React.memo(SearchUbiGoogle);
