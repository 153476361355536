import React, { Component } from "react";

import {
  svgComercio,
  svgCompra,
  svgCrueltyFree,
  svgNatural,
} from "../../../services/svgIcons";

class Service extends Component {
  render() {
    return (
      <div className="collection-filter-block ">
        <div className="product-service">
          <div className="media">
            <div dangerouslySetInnerHTML={{ __html: svgNatural }} />
            <div className="media-body">
              <h4>Natural</h4>
              <p>Insumos naturales, productos eco-amigables.</p>
            </div>
          </div>
          <div className="media">
            <div dangerouslySetInnerHTML={{ __html: svgCrueltyFree }} />
            <div className="media-body">
              <h4>Cruelty Free</h4>
              <p>No se testea en animales.</p>
            </div>
          </div>
          <div className="media">
            <div dangerouslySetInnerHTML={{ __html: svgCompra }} />
            <div className="media-body">
              <h4>Compra Local</h4>
              <p>Apoya emprendimientos y mano de obra peruanos.</p>
            </div>
          </div>
          <div className="media border-0 m-0">
            <div dangerouslySetInnerHTML={{ __html: svgComercio }} />
            <div className="media-body">
              <h4>Comercio Justo</h4>
              <p>
                Beneficia a los agricultores, productores y sus poblaciones.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
