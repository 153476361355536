import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../common/breadcrumb";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import HeaderTwo from "../common/headers/header-two";
import FooterOne from "../common/footers/footer-one";
import StickyBox from "react-sticky-box";
import { getCategories, getProductByCategory } from "../../services/products";

const CollectionLeftSidebar = (props) => {
  const [layoutColumns, setLayoutColumns] = useState(3);
  const [limit, setLimit] = useState(28);
  const [category, setCategory] = useState({});
  const [childCategories, setChildCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterSortOption, setFilterSortOption] = useState("");
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const { slug } = props.match.params;

  const LayoutViewClicked = (columns) => {
    setLayoutColumns(columns);
  };

  const openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  useEffect(
    () => {
      setFilteredProducts([]);
      getCategoriesFunc();
    },
    [slug]
  );

  useEffect(() => {
    if (products.length > 0) {
      window.dataLayer.push({
        event: "impressions",
        category: "Ecommerce",
        label: `Se han cargado productos de la categoría: ${props.history.location.pathname}`,
        ecommerce: {
          impressions: products,
        }
      });
    }
  }, [products])

  const getCategoriesFunc = async () => {
    setLoadingCategories(true);
    setLoadingProducts(true);
    const res = await getCategories();
    const mainCategory = res.find((item) => item.slug === slug);

    setCategory(mainCategory);
    let newChildCategories = [];

    const data = mainCategory.child.map(async (subCategory) => {
      const response = await getProductByCategory(subCategory.slug, 1);

      const responseAllPages = []
      if (response.data.links.next) {
        const numberOfPages = response.data.links.last.match("[?&]page=([^&]+).*$");
        for (let i = 2; i <= numberOfPages[1]; i++) {
          const res = await getProductByCategory(subCategory.slug, i);
          responseAllPages.push(res.data.data)
        }
      }

      const subCategoryProducts = [...response.data.data, ...responseAllPages].flat();

      if (mainCategory.child.some((cat) => cat.uuid === subCategory.uuid)) {
        newChildCategories.push({
          ...subCategory,
          products: subCategoryProducts,
        });
      }

      return subCategoryProducts;
    });

    Promise.all(data).then((values) => {
      const filterNewChildCategories = newChildCategories.filter(
        (child) => child.products.length > 0
      );

      const flatValues = values.flat();

      setChildCategories(filterNewChildCategories);
      setProducts(flatValues);
      setFilteredProducts(flatValues);
      filterProducts(flatValues);
      setLoadingCategories(false);
      setLoadingProducts(false);
    });
  };

  const filterProducts = (filterProducts) => {
    if (filterProducts.length === 0) {
      if (filterSortOption) {
        const sortProducts = [...products].sort(selectSort(filterSortOption));
        setFilteredProducts(sortProducts);
      } else {
        setFilteredProducts(products);
      }
    } else {
      if (filterSortOption) {
        const filteredAndSortProducts = [...filterProducts].sort(
          selectSort(filterSortOption)
        );

        setFilteredProducts(filteredAndSortProducts);
      } else {
        setFilteredProducts(filterProducts);
      }
    }
  };

  const selectSort = (option) => {
    switch (option) {
      case "DescOrder":
        return (a, b) =>
          b.name.toLowerCase() > a.name.toLowerCase()
            ? 1
            : a.name.toLowerCase() == b.name.toLowerCase()
            ? 0
            : -1;
      case "AscOrder":
        return (a, b) =>
          b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : a.name.toLowerCase() == b.name.toLowerCase()
            ? 0
            : 1;
      case "HighToLow":
        return (a, b) =>
          (b.prices.data.length > 0 ? b.prices.data[0].price : 0) -
          (a.prices.data.length > 0 ? a.prices.data[0].price : 0);
      case "LowToHigh":
        return (a, b) =>
          (a.prices.data.length > 0 ? a.prices.data[0].price : 0) -
          (b.prices.data.length > 0 ? b.prices.data[0].price : 0);
      default:
        return null;
    }
  };

  const filterSort = (option) => {
    setFilterSortOption(option);

    if (option === "") {
      setFilteredProducts(filteredProducts);
    } else {
      const sortFunction = selectSort(option);
      const newProducts = [...filteredProducts].sort(sortFunction);

      setFilteredProducts(newProducts);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{`${category && category.name} | Pasiflora`}</title>
        <meta name="description" content={category && category.description} />
        <meta
          property="og:title"
          content={`${category && category.name} | Pasiflora`}
        />
        <meta
          property="og:description"
          content={category && category.description}
        />
        <meta
          property="og:url"
          content={`http://pasiflora.pe/categoria/${category && category.slug}`}
        />
      </Helmet>
      <HeaderTwo logoName={"layout3/logo.png"} />
      <Breadcrumb title={category.name} />
      <section className="section-b-space">
        <div className="collection-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 collection-filter">
                <StickyBox offsetTop={150} offsetBottom={20}>
                  <div>
                    <Filter
                      categories={childCategories}
                      filterProducts={filterProducts}
                      loadingCategories={loadingCategories}
                    />
                  </div>
                </StickyBox>
              </div>
              <div className="collection-content col">
                <div className="page-main-content ">
                  <div className="">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="collection-product-wrapper">
                          <div className="product-top-filter">
                            <div className="container-fluid p-0">
                              <div className="row">
                                <div className="col-xl-12">
                                  <div className="filter-main-btn">
                                    <span
                                      onClick={openFilter}
                                      className="filter-btn btn btn-theme"
                                    >
                                      <i
                                        className="fa fa-filter"
                                        aria-hidden="true"
                                      />{" "}
                                      Filtro
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <FilterBar
                                    limit={limit}
                                    products={filteredProducts}
                                    onLayoutViewClicked={(columns) =>
                                      LayoutViewClicked(columns)
                                    }
                                    filterSort={filterSort}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <ProductListing
                            colSize={layoutColumns}
                            products={filteredProducts}
                            limit={limit}
                            setLimit={setLimit}
                            loadingProducts={loadingProducts}
                            path={props.history.location.pathname}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne logoName={"layout3/logo.png"} />
    </div>
  );
};

export default CollectionLeftSidebar;
