import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";

import ProductsApi from "../../services/ProductsApi";
import { CartContext } from "../../contexts/cart";
import { slug_business } from "../../constants";
import ProductVariants from "../products/common/product/product-variants";
import ProductDetailLoader from "../collection/common/product-detail-loader";

const productsApi = new ProductsApi();

const ProductModal = props => {
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState(null);
  const [prices, setPrices] = useState({
    price: 0,
    symbol: "S/",
    discount: 0,
    compare_to: 0,
    currencyName: "PEN"
  });
  const [loading, setLoading] = useState(false);

  // Variants
  const [variants, setVariants] = useState([]);
  const [variantGroups, setVariantGroups] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState({});

  const { onCloseModal, open, uuid } = props;
  const { handleAddProduct } = useContext(CartContext);

  const minusQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const plusQty = () => {
    setQuantity(quantity + 1);
  };

  const changeQty = e => {
    setQuantity(parseInt(e.target.value) || quantity);
  };

  const addProduct = (item, quantity) => {
    if (
      !item.is_available ||
      (item.stock <= 0 && item.inventory_policy === "deny")
    ) {
      return;
    }

    getStockFunc();
    handleAddProduct(
      { ...item, selected_variants: selectedVariants },
      quantity
    );
  };

  const getStockFunc = async () => {
    if (product) {
      if (
        product.inventory_policy === "deny" &&
        product.has_variants === false
      ) {
        const response = await productsApi.getStock({
          slug_business,
          product_uuid: product.uuid
        });

        setProduct(state => ({ ...state, stock: response.stock }));
      }
    }
  };

  useEffect(
    () => {
      getStockFunc();
    },
    [product && product.uuid]
  );

  useEffect(
    () => {
      async function getDetailsProduct() {
        setLoading(true);
        try {
          const response = await productsApi.getDetailsProductForStore({
            business_slug: slug_business,
            product_uuid: uuid
          });

          if (response) {
            setProduct(response.product);

            const productPrices = response.prices[0];
            const diff =
              productPrices.compare_to > 0
                ? productPrices.compare_to - productPrices.price
                : 0;
            const discount =
              diff > 0 ? (diff * 100) / productPrices.compare_to : 0;

            setPrices({
              price: productPrices.price,
              currencyName:
                productPrices.currency && productPrices.currency.name,
              symbol: productPrices.currency && productPrices.currency.symbol,
              compare_to: productPrices.compare_to,
              discount
            });

            if (response.product.has_variants) {
              setVariantGroups(response.product.variant_groups || []);
              setVariants(response.product.variants || []);

              // Selected default position variant
              if (
                response.product.variant_groups &&
                response.product.variant_groups.length > 0
              ) {
                // eslint-disable-next-line no-unused-expressions
                response.product.variant_groups.forEach(group => {
                  setSelectedVariants(state => ({
                    ...state,
                    [group.name]: group.values[0].uuid
                  }));
                });
              }
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }

      getDetailsProduct();
    },
    [uuid, slug_business]
  );

  // LOGIC FOR VARIANTS
  useEffect(
    () => {
      async function get() {
        const objectArray = Object.values(selectedVariants).filter(
          val => val !== "" && val
        );

        if (objectArray.length > 0) {
          const match = variants.map(variant => {
            const f = variant.variant_groups.filter(
              v => objectArray.includes(v.value.uuid) === true
            );

            return (
              f && f.length > 0 && objectArray.length === f.length && variant
            );
          });

          if (match.length > 0) {
            const auxVariants = match.filter(f => !!f);
            if (
              auxVariants &&
              auxVariants.length > 0 &&
              objectArray.length === variantGroups.length
            ) {
              let stock = {};

              if (auxVariants[0].inventory_policy === "deny") {
                // get stock variant
                const newStock = await productsApi.getStock({
                  slug_business,
                  product_uuid: auxVariants[0].uuid
                });

                stock = newStock;
              }

              setProduct(state => ({
                ...state,
                name: auxVariants[0].name,
                description: auxVariants[0].description,
                prices: auxVariants[0].prices,
                media:
                  auxVariants[0].media.filter(
                    e => e.collection === "photo" || e.collection === "gallery"
                  ) || "",
                stock: stock.stock || -1,
                inventory_policy: auxVariants[0].inventory_policy,
                uuidVariant: auxVariants[0].uuid,
                is_available: auxVariants[0].is_available
              }));

              const variantPrice = auxVariants[0].prices.data[0];
              const diff =
                variantPrice.compare_to > 0
                  ? variantPrice.compare_to - variantPrice.price
                  : 0;
              const discount =
                diff > 0 ? (diff * 100) / variantPrice.compare_to : 0;
              setPrices({
                price: variantPrice.price,
                currencyName:
                  variantPrice.currency && variantPrice.currency.name,
                symbol: variantPrice.currency && variantPrice.currency.symbol,
                compare_to: variantPrice.compare_to,
                discount
              });
            }
          }
        }
      }
      get();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedVariants, variants]
  );

  const selectVariant = useCallback((field, value) => {
    setSelectedVariants(state => ({ ...state, [field]: value }));
  }, []);
  // END LOGIC FOR VARIANTS

  const checkMaxPerOrder = React.useMemo(
    () => {
      if (
        product &&
        !!product.max_per_order &&
        Number(product.max_per_order) === Number(quantity)
      ) {
        toast.warn("Llegaste al máximo permitido por orden");
        return true;
      }
      return false;
    },
    [quantity, product]
  );

  return (
    <Modal open={open} onClose={onCloseModal} center>
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content quick-view-modal">
          <div className="modal-body">
            {loading ? (
              <ProductDetailLoader />
            ) : (
              <div className="row">
                <div className="col-lg-6  col-xs-12">
                  <div className="quick-view-img">
                    <img
                      src={
                        product && product.media && product.media[0]
                          ? product.media[0].conversions.original
                          : `${
                              process.env.PUBLIC_URL
                            }/assets/images/default/default_logo.png`
                      }
                      alt=""
                      className={`img-fluid ${product &&
                        !product.is_available &&
                        "img-no-available"}`}
                    />
                  </div>
                </div>
                <div className="col-lg-6 rtl-text">
                  <div className="product-right">
                    <h2> {product && product.name} </h2>
                    <h3>
                      {`${prices.symbol} ${prices.price}`}
                      <del>
                        <span className="money">
                          {prices.compare_to > 0 &&
                            `${prices.symbol} ${prices.compare_to}`}
                        </span>
                      </del>
                    </h3>
                    {product && product.description && (
                      <div className="border-product">
                        <h6 className="product-title">Descripción</h6>
                        <p>{product.description}</p>
                      </div>
                    )}
                    {variantGroups && variantGroups.length > 0 && (
                      <div className="product-description border-product">
                        <ProductVariants
                          variantGroups={variantGroups}
                          selectVariant={selectVariant}
                          selectedVariants={selectedVariants}
                        />
                      </div>
                    )}
                    <div className="product-description border-product">
                      <span
                        className={
                          product &&
                          (product.stock === 0 || product.stock < quantity)
                            ? "outstock-cls"
                            : "instock-cls"
                        }
                      >
                        {product &&
                        (product.stock === 0 || product.stock < quantity)
                          ? "Producto fuera de stock"
                          : "En stock"}
                      </span>
                      <h6 className="product-title">Cantidad</h6>
                      <div className="qty-box">
                        <div className="input-group">
                          <span className="input-group-prepend">
                            <button
                              type="button"
                              className="btn quantity-left-minus"
                              onClick={minusQty}
                              data-type="minus"
                              data-field=""
                            >
                              <i className="fa fa-angle-left" />
                            </button>
                          </span>
                          <input
                            type="text"
                            name="quantity"
                            value={quantity}
                            onChange={changeQty}
                            readOnly={product && !product.is_available}
                            className="form-control input-number"
                          />
                          <span className="input-group-prepend">
                            <button
                              type="button"
                              className="btn quantity-right-plus"
                              onClick={
                                (product && product.stock) ===
                                  Number(quantity) || checkMaxPerOrder
                                  ? () => {}
                                  : plusQty
                              }
                              data-type="plus"
                              data-field=""
                              disabled={
                                (product && !product.is_available) ||
                                (product && product.stock) ===
                                  Number(quantity) ||
                                checkMaxPerOrder
                              }
                            >
                              <i className="fa fa-angle-right" />
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="product-buttons">
                      <button
                        className={`btn btn-solid with-icon ${product &&
                          !product.is_available &&
                          "btn-disabled"}`}
                        onClick={() => addProduct(product, quantity)}
                        disabled={product && !product.is_available}
                      >
                        {product && product.is_available
                          ? "Agregar"
                          : "No disponible"}
                        {product && product.is_available && (
                          <svg
                            width="38"
                            height="38"
                            viewBox="0 0 38 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.14001 8.91002H36.87L31.08 24.84C30.86 25.45 30.28 25.85 29.63 25.85H12.04C11.26 25.85 10.61 25.27 10.51 24.5L7.60001 1.20001H1.44M15.3 33.55C15.3 35.25 13.92 36.63 12.22 36.63C10.52 36.63 9.14001 35.25 9.14001 33.55C9.14001 31.85 10.52 30.47 12.22 30.47C13.92 30.47 15.3 31.85 15.3 33.55ZM32.25 33.55C32.25 35.25 30.87 36.63 29.17 36.63C27.47 36.63 26.09 35.25 26.09 33.55C26.09 31.85 27.47 30.47 29.17 30.47C30.87 30.47 32.25 31.85 32.25 33.55Z"
                              stroke-width="1.134"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                      </button>
                      <Link
                        to={`${process.env.PUBLIC_URL}/producto/${product &&
                          product.slug}`}
                        className="btn btn-solid"
                      >
                        Ver más
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;
