import React, { useMemo } from "react";

const OrderProduct = props => {
  const { product } = props;

  const price = useMemo(() => {
    return product.prices &&
      product.prices.data.length > 0 &&
      product.prices.data[0]
      ? product.prices.data[0].price
      : product.total_amount;
  }, [product.prices, product.total_amount]);

  const currency = useMemo(() => {
    return product.prices
      ? product.prices.data[0].currency.symbol
      : product.currency && product.currency.symbol;
  }, [product.currency, product.prices]);

  const compare_to = React.useMemo(() => {
    return (
      (product.prices &&
        product.prices.data.length > 0 &&
        product.prices.data[0] &&
        product.prices.data[0].compare_to) ||
      product.compare_to
    );
  }, [product.compare_to, product.prices]);

  return (
    <div className="row product-order-detail" key={product.uuid}>
      <div className="col-2">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            style={{ height: "60px", objectFit: "contain" }}
            src={
              product.media && product.media.length > 0 && product.media[0]
                ? product.media[0].url
                : `${process.env.PUBLIC_URL}/assets/images/default/default_iso.png`
            }
            alt=""
            className="img-fluid"
          />
        </div>
      </div>
      <div className="col-4 order_detail justify-content-start">
        <div>
          <h5>{product.name}</h5>
        </div>
      </div>
      <div className="col-3 order_detail justify-content-end">
        <div>
          <h5>{product.quantity}</h5>
        </div>
      </div>
      <div className="col-3 order_detail justify-content-end">
        <div>
          {compare_to > 0 &&
            <del>
              {currency}
              {compare_to}
            </del>
          }
          <h5>{currency} {price}</h5>
        </div>
      </div>
    </div>
  );
};

export default OrderProduct;
