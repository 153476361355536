import React, { useState, useContext, useEffect, useMemo } from 'react';
import store from "store";
import { useBusiness } from "../../contexts/business";
import { CartContext } from '../../contexts/cart';
// import { useType } from '../../contexts/Types';

// import { delivery } from '../../utils/constants.js';
import { LAST_ORDER_TYPE } from "../../constants/ActionTypes"
import { useOrderType } from '../../contexts/orderType';

const FreeDelivery = ({
  styles,
}) => {
  const { channelStore } = useBusiness();
  const { orderType } = useOrderType();
  const { prices, getPrices, setFreeShippingDelivery } = useContext(
    CartContext,
  );

  useEffect(() => {
    getPrices();
  }, [getPrices]);

  const freeDelivery = useMemo(() => {
    return Number(channelStore.min_order_free_delivery).toFixed(2);
  }, [channelStore.min_order_free_delivery]);

  const calculatePriceTotal = useMemo(() => {
    const subTotal = Number(prices.subtotal) - Number(prices.discount);
    if (subTotal >= Number(freeDelivery)) return true;
    return false;
  }, [freeDelivery, prices]);

  useEffect(() => {
    if (calculatePriceTotal && !!channelStore.min_order_free_delivery) {
      setFreeShippingDelivery(true);
    } else {
      setFreeShippingDelivery(false);
    }
  }, [
    calculatePriceTotal,
    channelStore.min_order_free_delivery,
    setFreeShippingDelivery,
  ]);

  return (
    <>
      {channelStore.min_order_free_delivery &&
        orderType === "delivery" && (
          <div className="free-delivery">
            ¡DELIVERY GRATIS!{' '}
            <br />
            {calculatePriceTotal ? null : `MIN S/${freeDelivery}`}
          </div>
        )}
    </>
  );
};

export default React.memo(FreeDelivery);
