import React from "react";
import Modal from "react-responsive-modal";

const OutOfStockModal = (props) => {
  const {
    openModal,
    productsOutOfStock,
    handleAcceptChangeStockToCart,
  } = props;

  return (
    <Modal open={openModal} onClose={handleAcceptChangeStockToCart} center>
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content quick-view-modal">
          <div className="modal-body">
            <div className="modal-outstock-header">
              <h3>
                Detectamos que algunos productos se encuentran fuera de stock
              </h3>
              <h4>
                Tu carrito de compras será modificado dependiendo a la
                disponibilidad del producto
              </h4>
            </div>
            <div className="modal-list">
              <p>Listado de productos:</p>
              <ul>
                {productsOutOfStock &&
                  productsOutOfStock.length > 0 &&
                  productsOutOfStock.map((p, i) => {
                    return (
                      <li key={p.uuid}>
                        {i + 1}- El producto <strong>{p.name}</strong> solo
                        tenemos disponible <strong>{p.stock}</strong>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="text-center">
              <button
                onClick={handleAcceptChangeStockToCart}
                className="btn btn-solid"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OutOfStockModal;
