import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { CartContext } from "../../../contexts/cart";

const ProductListItem = props => {
  const [prices, setPrices] = useState({
    price: 0,
    symbol: "S/",
    compare_to: 0,
    discount: 0
  });

  const { product, path, setOpen, setActiveProduct } = props;
  const { handleAddProduct } = useContext(CartContext);

  const productPrices = () => {
    const { product } = props;
    if (product.prices.data.length > 0) {
      const data = product.prices.data[0];
      const diff = data.compare_to > 0 ? data.compare_to - data.price : 0;
      const discount = diff > 0 ? (diff * 100) / data.compare_to : 0;

      setPrices({
        price: data.price,
        symbol: data.currency && data.currency.symbol,
        compare_to: data.compare_to,
        discount
      });
    }
  };

  useEffect(
    () => {
      if (product) {
        productPrices();
      }
    },
    [product]
  );

  const onOpenModal = () => {
    setOpen(true);
    setActiveProduct(product);
    handleClickProduct();
  };

  // const onCloseModal = () => {
  //   setOpen(false);
  // };

  const handleClickProduct = () => {
    if (product) {
      const actionFieldList = path ? path : "Búsqueda";

      window.dataLayer.push({
        event: "productClick",
        ecommerce: {
          click: {
            actionField: {
              list: actionFieldList
            },
            product: {
              id: product.uuid,
              name: product.name,
              price: prices.price,
              brand: product.vendor,
              category: product.category.name
            }
          }
        }
      });
    }
  };

  return (
    <div
      className={`product-box ${!product.is_available &&
        "product-no-available"}`}
    >
      <div className="img-wrapper">
        <div className="lable-block">
          {prices.discount > 0 && (
            <span className="lable3">{prices.discount.toFixed(0)}%</span>
          )}
          {!product.is_available && (
            <span className="no-available-label">No disponible</span>
          )}
        </div>
        <div className="front">
          <Link
            to={`${process.env.PUBLIC_URL}/producto/${product.slug}`}
            onClick={handleClickProduct}
          >
            <img
              src={
                product.media && product.media[0]
                  ? product.media[0].conversions.original
                  : `${
                      process.env.PUBLIC_URL
                    }/assets/images/default/default_logo.png`
              }
              className="img-fluid"
              alt=""
            />
          </Link>
        </div>
        {setOpen && (
          <div className="cart-info cart-wrap">
            {product.is_available && (
              <button
                title="Add to cart"
                onClick={() =>
                  product.has_variants
                    ? onOpenModal()
                    : handleAddProduct(product, 1)
                }
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/cart.svg`}
                  style={{ width: "20px", margin: "0 8px" }}
                  alt=""
                />
              </button>
            )}
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icons/search.svg`}
              style={{ width: "20px", margin: "0 8px", cursor: "pointer" }}
              alt=""
              onClick={onOpenModal}
            />
          </div>
        )}
      </div>
      <div className="product-detail">
        <div>
          {product.vendor && <span>{product.vendor}</span>}
          <Link
            to={`${process.env.PUBLIC_URL}/producto/${product.slug}`}
            onClick={handleClickProduct}
          >
            <h6>{product.name}</h6>
          </Link>
          <h4>{`${prices.symbol} ${prices.price}`}</h4>
        </div>
      </div>
    </div>
  );
};

export default ProductListItem;
