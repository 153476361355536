import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import { Product4, Product5 } from "../../../services/script";
import ProductModal from "../../common/product-modal";
import ProductItem from "../../features/product/common/product-style-five";

const TopCollection = props => {
  const [open, setOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);

  const { products, type } = props;

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (!open) {
      setActiveProduct(null);
      html.removeAttribute("style");
    } else {
      html.style.position = "fixed";
      html.style.overflow = "hidden";
    }
  }, [open]);

  let properties;
  if (type === "kids") {
    properties = Product5;
  } else {
    properties = Product4;
  }

  return (
    <section style={{ paddingBottom: "70px" }}>
      {/*Paragraph*/}
      <div className="title1  section-t-space">
        {/* <h4>BOTICA NATURAL</h4> */}
        <h2 className="title-inner1">Productos destacados</h2>
      </div>
      {/*Paragraph End*/}
      <section className="section-b-space p-t-0">
        <div className="container">
          <div className="row">
            <div className="col">
              <Slider {...properties} className="product-4 product-m">
                {products.map((product, index) => (
                  <div key={index}>
                    <ProductItem
                      product={product}
                      key={index}
                      setActiveProduct={setActiveProduct}
                      setOpen={setOpen}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {activeProduct && (
        <ProductModal
          uuid={activeProduct.uuid}
          open={open}
          onCloseModal={() => setOpen(false)}
        />
      )}
    </section>
  );
};
export default TopCollection;
