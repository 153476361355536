import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { CartContext } from "../../contexts/cart";

const ProductCartItem = props => {
  const { item } = props;
  const { handleRemoveProduct, handleAddProduct } = useContext(CartContext);

  const handleClickProduct = () => {
    if (item) {
      const price =
        item.prices && item.prices.data[0] && item.prices.data[0].price;

      window.dataLayer.push({
        event: "productClick",
        ecommerce: {
          click: {
            actionField: {
              list: "Carrito"
            },
            product: {
              id: item.uuid,
              name: item.name,
              price: price,
              brand: item.vendor,
              category: item.category.name
            }
          }
        }
      });
    }
  };

  return (
    <tbody>
      <tr>
        <td>
          <Link
            to={`${process.env.PUBLIC_URL}/producto/${item.slug}`}
            onClick={handleClickProduct}
          >
            <img
              src={
                item.media && item.media[0]
                  ? item.media[0].conversions.original
                  : `${process.env.PUBLIC_URL}/assets/images/default/default_iso.png`
              }
              alt=""
            />
          </Link>
        </td>
        <td>
          <Link
            to={`${process.env.PUBLIC_URL}/producto/${item.slug}`}
            className="td-name"
            onClick={handleClickProduct}
          >
            {item.vendor && <span>{item.vendor}</span>}
            {item.name}
          </Link>
          <div className="mobile-cart-content row">
            <div className="col-xs-2">
              <div className="qty-box">
                <div className="input-group">
                  <span className="input-group-prepend">
                    <button
                      type="button"
                      className="btn quantity-left-minus"
                      onClick={() =>
                        handleRemoveProduct(
                          item,
                          item.quantity - 1 === 0 ? null : 1
                        )
                      }
                      data-type="minus"
                      data-field=""
                    >
                      <i className="fa fa-angle-left" />
                    </button>
                  </span>
                  <input
                    type="text"
                    name="quantity"
                    value={item.quantity}
                    readOnly={true}
                    className="form-control input-number"
                  />

                  <span className="input-group-prepend">
                    <button
                      className="btn quantity-right-plus"
                      onClick={() => handleAddProduct(item, 1, true)}
                      data-type="plus"
                      disabled={item.quantity >= item.stock ? true : false}
                    >
                      <i className="fa fa-angle-right" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xs-3">
              <h2 className="td-color">
                {item.prices &&
                  item.prices.data[0] &&
                  item.prices.data[0].currency.symbol}
                {(
                  item.prices &&
                  item.prices.data[0] &&
                  Number(item.prices.data[0].price) * item.quantity
                ).toFixed(2)}
              </h2>
            </div>
            <div className="col-xs-2">
              <h2 className="td-color">
                <div className="icon" onClick={() => handleRemoveProduct(item)}>
                  <i className="fa fa-times" />
                </div>
              </h2>
            </div>
          </div>
        </td>
        <td>
          <h2>
            {item.prices &&
              item.prices.data[0] &&
              item.prices.data[0].currency.symbol}
            {item.prices &&
              item.prices.data[0] &&
              Number(item.prices.data[0].price).toFixed(2)}
          </h2>
        </td>
        <td>
          <div className="qty-box">
            <div className="input-group">
              <span className="input-group-prepend">
                <button
                  type="button"
                  className="btn quantity-left-minus"
                  onClick={() =>
                    handleRemoveProduct(
                      item,
                      item.quantity - 1 === 0 ? null : 1
                    )
                  }
                  data-type="minus"
                  data-field=""
                >
                  <i className="fa fa-angle-left" />
                </button>
              </span>
              <input
                type="text"
                name="quantity"
                value={item.quantity}
                readOnly={true}
                className="form-control input-number"
              />

              <span className="input-group-prepend">
                <button
                  className="btn quantity-right-plus"
                  onClick={() => handleAddProduct(item, 1, true)}
                  data-type="plus"
                  disabled={item.quantity >= item.stock ? true : false}
                >
                  <i className="fa fa-angle-right" />
                </button>
              </span>
            </div>
          </div>
          {item.quantity >= item.stock && "Producto agotado"}
          {!!item.max_per_order &&
            Number(item.max_per_order) === Number(item.quantity) &&
            "Llegaste al máximo permitido por orden"}
        </td>
        <td>
          <div className="icon" onClick={() => handleRemoveProduct(item)}>
            <i className="fa fa-times" />
          </div>
        </td>
        <td>
          <h2 className="td-color">
            {item.prices &&
              item.prices.data[0] &&
              item.prices.data[0].currency.symbol}
            {item.prices &&
              item.prices.data[0] &&
              Number(item.prices.data[0].price * item.quantity).toFixed(2)}
          </h2>
        </td>
      </tr>
    </tbody>
  );
};

export default ProductCartItem;
