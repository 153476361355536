import React from "react";
import styled, { css } from "styled-components";

const Btn = styled.div`
  width: 100%;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  height: 50px;
  font-size: 17px;
  ${(props) => {
    if (props.disabled) {
      return css`
        background-color: #84869a;
        cursor: not-allowed;
      `;
    } else {
      return css`
        background-color: #151e5a;
      `;
    }
  }}
`;

const BtnFinal = (props) => {
  return (
    <Btn
      onClick={props.disabled ? () => {} : props.onClick}
      disabled={props.disabled}
      style={props.style}
    >
      {props.children}
      {props.value}
    </Btn>
  );
};

export default BtnFinal;
