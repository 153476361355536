let env = {
  NODE_ENV: "production",
  BASE_API: "https://api.idbi.pe/api/v4",
  BASE_APIv5: "https://api.idbi.pe/api/v5",
  BASE_APIv6: "https://api.idbi.pe/api/v6",
  BASE_APIv1: 'https://u1agkdlr54.execute-api.us-east-1.amazonaws.com/api/v1',

  // DEVELOPMENT
  // BASE_API: "https://api.idbi.dev/api/v4",
  // BASE_APIv5: "https://api.idbi.dev/api/v5",
  // BASE_APIv6: "https://api.idbi.dev/api/v6",
  // BASE_APIv1: "https://delivery.idbi.dev/api/v1",


  // BASE_API: 'https://back0.sa.ngrok.io/api/v4',
  // BASE_APIv1: 'https://del0.sa.ngrok.io/api/v1',
  // BASE_APIv5: 'https://back0.sa.ngrok.io/api/v5',
  // BASE_APIv6: 'https://back0.sa.ngrok.io/api/v6',

  // BASE_API: "http://idpos.ngrok.io/api/v4", //NILS
  // BASE_APIv5: "http://idpos.ngrok.io/api/v5", //NILS
  // BASE_APIv6: "http://idpos.ngrok.io/api/v6", //NILS
  // BASE_APIv1: "http://iddelivery.ngrok.io/api/v1",

  //ANTHONY
  // BASE_API: "http://idpos-ar.ngrok.io/api/v4",
  // BASE_APIv5: "http://idpos-ar.ngrok.io/api/v5",
  // BASE_APIv6: "http://idpos-ar.ngrok.io/api/v6",
  // BASE_APIv1: "http://idpos-ar-de.ngrok.io/api/v1",

  MERCADOPAGO_KEY: "TEST-c1c24747-b829-4d12-a494-cb1e0efa104d",
  WSHOST: "sockets.idbi.pe",
  PUSHER_APP_KEY: "ygIg5IvPc0sLIR91",
  SENTRY_KEY: "",
  GOOGLE_MAPS_KEY: "AIzaSyAVMuVmXC6BSD0xnHC8ynEIWYgXMdQy0YU",
};
export default env;
