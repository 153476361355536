import React, { useEffect, useContext, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { CartContext } from "../../contexts/cart";
import ProductsApi from "../../services/ProductsApi";
import { slug_business } from "../../constants";

import Breadcrumb from "../common/breadcrumb";
import HeaderTwo from "../common/headers/header-two";
import FooterOne from "../common/footers/footer-one";
import ProductCartItem from "./product-cart-item";
import OutOfStockModal from "../common/out-stock-modal";

const apiProductService = new ProductsApi();

const cartComponent = (props) => {
  const [productsOutOfStock, setProductsOutOfStock] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }, []);

  const {
    productsCartArray,
    getPrices,
    prices,
    updateCartForOutOfStock,
  } = useContext(CartContext);

  useEffect(
    () => {
      getPrices();
    },
    [getPrices]
  );

  const getAllStock = async () => {
    const requests = [];

    productsCartArray.forEach(async (product, index) => {
      if (product.inventory_policy === "deny") {
        requests.push(
          apiProductService.getStock({
            product_uuid:
              product.has_variants === true
                ? product.uuidVariant
                : product.uuid,
            slug_business,
            indexCart: index,
          })
        );
      }
    });

    const result = await Promise.all(requests);
    const outOfStock = result
      .map((valueStock) => {
        const productsWithUuid = productsCartArray.filter((p) =>
          p.has_variants
            ? valueStock.uuid === p.uuidVariant
            : valueStock.uuid === p.uuid
        );

        const sumProducts = productsWithUuid.reduce(
          (acc, item) => Number(item.quantity) + acc,
          0
        );

        const productSelect = productsCartArray.find(
          (p, index) => index === valueStock.indexCart
        );

        if (
          productsWithUuid.length === 1 &&
          (Number(valueStock.stock) !== -1 || Number(valueStock.stock) > 0) &&
          productSelect.quantity > Number(valueStock.stock) &&
          productSelect.inventory_policy === "deny"
        ) {
          return {
            ...productSelect,
            stock: valueStock.stock,
            indexCart: valueStock.indexCart,
          };
        } else if (
          productsWithUuid.length > 1 &&
          (Number(valueStock.stock) !== -1 || Number(valueStock.stock) > 0) &&
          sumProducts > Number(valueStock.stock) &&
          productSelect.inventory_policy === "deny"
        ) {
          return {
            ...productSelect,
            stock: valueStock.stock,
            indexCart: valueStock.indexCart,
          };
        }
        return null;
      })
      .filter((value) => value !== null);

    const sanitizerDuplicate = outOfStock.reduce((acc, current) => {
      const x = acc.find((item) =>
        item.has_variants
          ? item.uuidVariant === current.uuidVariant
          : item.uuid === current.uuid
      );
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);

    if (outOfStock.length > 0) {
      setProductsOutOfStock(sanitizerDuplicate);
      setOpenModal(true);
    }
  };

  const handleAcceptChangeStockToCart = useCallback(
    () => {
      updateCartForOutOfStock(productsOutOfStock);
      setOpenModal(false);
      setProductsOutOfStock([]);
    },
    [productsOutOfStock, setOpenModal, updateCartForOutOfStock]
  );

  useEffect(() => {
    getAllStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Helmet>
        <title>Pasiflora | Carrito</title>
      </Helmet>
      <HeaderTwo logoName={"layout3/logo.png"} />
      <Breadcrumb title={"Carrito"} />

      {productsCartArray.length > 0 ? (
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <table className="table cart-table table-responsive-xs">
                  <thead>
                    <tr className="table-head">
                      <th scope="col">imagen</th>
                      <th scope="col">nombre de producto</th>
                      <th scope="col">precio</th>
                      <th scope="col">cantidad</th>
                      <th scope="col">acción</th>
                      <th scope="col">total</th>
                    </tr>
                  </thead>
                  {productsCartArray.map((item) => {
                    return <ProductCartItem item={item} key={item.uuid} />;
                  })}
                </table>
                <table className="table cart-table table-responsive-md">
                  <tfoot>
                    <tr>
                      <td>precio total :</td>
                      <td>
                        <h2>S/ {Number(prices.subtotal).toFixed(2)} </h2>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="row cart-buttons">
              <div className="col-6">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="btn btn-solid"
                >
                  continua comprando
                </Link>
              </div>
              <div className="col-6">
                <Link
                  to={`${process.env.PUBLIC_URL}/checkout`}
                  className="btn btn-solid"
                >
                  pagar
                </Link>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/icon-empty-cart.png`}
                      className="img-fluid mb-4"
                      alt=""
                    />
                    <h3>
                      <strong>Tu carrito está vacío</strong>
                    </h3>
                    <Link
                      to={`${process.env.PUBLIC_URL}/`}
                      className="btn btn-solid"
                    >
                      Volver
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <OutOfStockModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleAcceptChangeStockToCart={handleAcceptChangeStockToCart}
        productsOutOfStock={productsOutOfStock}
      />
      <FooterOne logoName={"layout3/logo.png"} />
    </div>
  );
};

export default cartComponent;
