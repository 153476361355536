import axios from "axios";
import store from "store";
import dayjs from "dayjs";

import { BRAND_INFO } from "../../constants/ActionTypes";

import { slug_business, baseUrlAPIV5, baseUrlAPIV6 } from "../../constants";

class BusinessApi {
  async getBrandInformations({ channel }) {
    const infoBrand = store.get(BRAND_INFO);

    const dateLS = !infoBrand
      ? dayjs(new Date()).format("YYYY-MM-DD")
      : dayjs(infoBrand.petition_date).format("YYYY-MM-DD");
    const dateCurrent = dayjs(new Date()).format("YYYY-MM-DD");

    if (
      !infoBrand ||
      !infoBrand.uuid ||
      infoBrand.slug !== slug_business ||
      dayjs(dateLS).isBefore(dateCurrent)
    ) {
      const response = await axios.get(
        `${baseUrlAPIV5}/brands/${slug_business}`,
        {
          headers: { "I-Channel": channel },
        }
      );
      const brand = response.data.data;
      return brand;
    }
    return infoBrand;
  }

  async getQuestionsBrandForSlug(slug_business) {
    const response = await axios.get(
      `${baseUrlAPIV5}/brands/${slug_business}/questions`
    );

    return response.data.data;
  }

  async getContactByBrandForSlug(slug_business) {
    const response = await axios.get(
      `${baseUrlAPIV5}/brands/${slug_business}/contact`
    );

    return response.data.data;
  }

  async getLocationsStoreForSlug(slug_business) {
    const response = await axios.get(
      `${baseUrlAPIV5}/brands/${slug_business}/stores`
    );

    return response.data.data;
  }

  async getGalleryForSlug(slug_business) {
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${slug_business}/media?collection=gallery`
    );

    return response.data.data;
  }

  async getBannersForSlug(slug_business) {
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${slug_business}/media?collection=banner`
    );

    return response.data.data;
  }

  async getSchedulesOfPickupForSlug(business_slug){
    const response = await axios.get(
      `${baseUrlAPIV5}/stores/${business_slug}/schedules`,
    );

    return response.data.data;
  }

  async getOrderTypesForUuid(uuid){
    const response = await axios.get(`${baseUrlAPIV6}/order-types`, {
      headers: {
        'i-store': uuid,
      },
    });

    return response.data.data;
  }
}

export default BusinessApi;
