import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import "dayjs/locale/es";

dayjs().format();
dayjs.locale("es");
dayjs.extend(calendar);

export const getOptionsIdentification = () => {
  return [
    {
      label: "DNI",
      value: "dni",
    },
    {
      label: "Carnet de extranjería",
      value: "foreigners_card",
    },
    {
      label: "Pasaporte",
      value: "passport",
    },
    {
      label: "RUC",
      value: "ruc",
    },
  ];
};

export const getLast4Number = (number) => {
  return number.substring(number.length - 4);
};

export const getFirst6Numbers = (number) => {
  return number.substr(0, 7).replace(" ", "");
};

export const removeSpaces = (text) => {
  let n = text.replace(/\s/g, "");
  return n;
};

export const getValueIDToMP = (value) => {
  if (value === "foreigners_card") {
    return "C.E";
  } else if (value === "passport") {
    return "Otro";
  } else {
    return value.toUpperCase();
  }
};

export const getObjectFromArrayMap = (map) => {
  let objects = [];
  for (var valor of map.values()) {
    objects = objects.concat(valor);
  }
  return objects;
};

export const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export function formatEstimatedTime({ start, end }) {
  if (start && end) {
    const startF = dayjs(start).calendar(undefined, {
      sameDay: "[Hoy] DD [entre las] h:mm a",
      nextDay: "[Mañana] DD [entre las] h:mm a",
      nextWeek: "dddd DD [entre las] h:mm a",
      lastDay: "[Ayer a las] h:mm a",
      lastWeek: "[El pasado] dddd",
      sameElse: "dddd DD [entre las] h:mm a",
    });

    const endF = dayjs(end).format("h:mm a");

    return `${startF} - ${endF}`;
  }
  return "No se pudo calcular";
}

export const verifyVersion = (version) => {
  let storageVersion = localStorage.getItem("version");
  if (storageVersion) {
    if (version !== storageVersion) {
      const newNumbers = version.split(".");
      const oldNumbers = storageVersion.split(".");
      if (newNumbers[0] !== oldNumbers[0]) {
        localStorage.clear();
        localStorage.setItem("version", version);
      } else {
        localStorage.clear();
        localStorage.setItem("version", version);
        window.location.reload();
      }
    }
  } else {
    localStorage.clear();
    localStorage.setItem("version", version);
  }
};

const getKeysOfObject = (obj) => {
  let keys = [];
  for (let k in obj) {
    keys.push(k);
  }
  return keys;
};

const getMessageString = (error) => {
  let aux = "";
  getKeysOfObject(error.response.data.errors).map((k) => {
    return error.response.data.errors[k].map((i) => {
      aux += i + " ";
    });
  });
  return aux;
};

export const getErrorFromError = (error) => {
  switch (typeof error) {
    case "object":
      if (error.response) {
        if (error.response.data) {
          const title = error.response.data.title
            ? error.response.data.title
            : "Ocurrio un error";
          const message = error.response.data.errors
            ? getMessageString(error)
            : error.response.data.message;
          return { title, message, active: true };
        }
      } else {
        if (!navigator.onLine) {
          return {
            title: "No tienes conexión a internet",
            message: "Conectate a internet para continuar.",
            active: true,
          };
        } else {
          return { title: error.message, message: error.message, active: true };
        }
      }
      break;
    case "string":
      return { title: "Ocurrio un error", message: error, active: true };
  }
};

export function formatScheludePikup({ start }) {
  if (start) {
    const startF = dayjs(start).calendar(undefined, {
      sameDay: '[Hoy] DD | h:mm a',
      nextDay: '[Mañana] DD | h:mm a',
      nextWeek: 'dddd DD | h:mm a',
      lastDay: '[Ayer a las] h:mm a',
      lastWeek: '[El pasado] dddd',
      sameElse: 'dddd DD | h:mm a',
    });

    return `${startF}`;
  }
  return 'No se pudo calcular';
}