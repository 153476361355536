import axios from "axios";

import { slug_business, baseUrlAPIV5 } from "../constants";

export const getBrandInfoEndpoint = () =>
  axios.get(`${baseUrlAPIV5}/brands/${slug_business}`, {
    headers: {
      "i-channel": "1b2fc3cc-6c14-4f7a-8be9-6315fffafba5"
    }
  });

export const getBannersEndpoint = () =>
  axios.get(`${baseUrlAPIV5}/stores/${slug_business}/media?collection=banner`);

export const getGalleryEndpoint = () =>
  axios.get(`${baseUrlAPIV5}/stores/${slug_business}/media?collection=gallery`);

export const getContactInfoEndpoint = () =>
  axios.get(`${baseUrlAPIV5}/brands/${slug_business}/contact`);
