import React, { createContext, useContext, useState, useCallback } from "react";
import store from "store";
import axios from "axios";
import ProductsApi from "../../services/ProductsApi";
import BusinessApi from "../../services/BusinessApi";

import { CartContext } from "../cart";
import {
  MAIN_STORE,
  BRAND_INFO,
  CATEGORIES_MENU,
  CATALOGS_STORE,
  STORE_SELECTED,
  COUPON_APPLIED,
  CHANNEL_STORE
} from "../../constants/ActionTypes";
import { slug_business, baseUrlAPIV5 } from "../../constants";

const getCredentialsByStore = (slug) =>
  axios.get(`${baseUrlAPIV5}/stores/${slug}/gateway-credentials`);

const BusinessContext = createContext();
const businessApi = new BusinessApi();
const productApi = new ProductsApi();

const BusinessProvider = ({ children }) => {
  const { total, handleClearEmpty } = React.useContext(CartContext);

  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [slugSelected, setSlugSelected] = useState(() => {
    const getSlugLS = localStorage.getItem(STORE_SELECTED);
    if (getSlugLS) {
      return getSlugLS;
    }
    return "";
  });

  const [brand, setBrand] = useState(() => {
    return store.get(BRAND_INFO);
  });
  const [categories, setCategories] = useState(() => {
    return store.get(CATEGORIES_MENU) || [];
  });
  const [gallery, setGallery] = useState([]);
  const [banners, setBanners] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [contact, setContact] = useState({});
  const [stores, setStores] = useState([]);

  const [catalogs, setCatalogs] = useState(() => {
    return store.get(CATALOGS_STORE) || [];
  });

  const [credentials, setCredentials] = useState(null);

  const [allcategoryWProducts, setAllcategoryWProducts] = useState([]);

  const [mainStore, setMainStore] = useState(() => {
    return store.get(MAIN_STORE);
  });

  const [channelStore, setChannelStore] = useState(() => {
    const channelLS = store.get(CHANNEL_STORE);
    if (channelLS) return channelLS;
    return {};
  });

  const getBrandInformations = useCallback(
    async ({ business_slug, channel }) => {
      try {
        if (slugSelected !== business_slug) {
          setLoading(true);
          setBanners([]);
          setGallery([]);
        }

        const responseBrand = await businessApi.getBrandInformations({
          business_slug,
          channel,
        });

        if (responseBrand) {
          setBrand(responseBrand);

          store.set(BRAND_INFO, {
            ...responseBrand,
            petition_date: new Date(),
          });

          const responseMainStore = responseBrand.stores.find(
            (brandItem) => brandItem.is_main,
          );

          const channelECommerce = responseMainStore && responseMainStore.channels.find(
            (item) => item.slug === 'e-commerce',
          );

          if (channelECommerce) setChannelStore(channelECommerce);
          if (responseMainStore) setMainStore(responseMainStore);

          store.set(MAIN_STORE, responseMainStore);
          store.set(CHANNEL_STORE, channelECommerce);
          setLoading(false);
        } else {
          setLoading(false);
        }
        setLoading(false);
      } catch (err) {
        throw new Error('grow any error in brand business');
      }
    },
    [slugSelected]
  );

  const getGallery = useCallback(async (business_slug) => {
    const response = await businessApi.getGalleryForSlug(business_slug);
    if (response) {
      setGallery(response);
    }
  }, []);

  const getBanners = useCallback(async (business_slug) => {
    const response = await businessApi.getBannersForSlug(business_slug);
    if (response) {
      setBanners(
        response.sort((a, b) => {
          return a.order - b.order;
        })
      );
    }
  }, []);

  const getAllCategoriesWithProducts = useCallback(
    async (businessUuid) => {
      if (slugSelected !== businessUuid) {
        setCategories([]);
      }
      try {
        setLoadingProducts(true);

        const products = await productApi.getAllProductForCategoriesBySlug(
          businessUuid
        );

        const allCategories = [];
        if (products) {
          setAllcategoryWProducts(products);
          products.forEach((e) => {
            allCategories.push({ name: e.name, slug: e.slug, uuid: e.uuid });
          });
          setCategories(allCategories);
          store.set(CATEGORIES_MENU, allCategories);
          setLoadingProducts(false);
        }
      } catch (error) {
        throw new Error("grow any error in categories with products");
      }
    },
    [slugSelected]
  );

  const resetMainStoreState = useCallback(() => {
    setMainStore({});
  }, []);

  const getCredentialsByStoreFunc = useCallback(
    async () => {
      const r = await getCredentialsByStore(slug_business);
      setCredentials(r.data.data);
    },
    [slug_business]
  );

  const validateButtonPay = useCallback(
    () => {
      if (mainStore && mainStore.channels) {
        if (mainStore && mainStore.channels.length > 0) {
          return total >= mainStore && mainStore.channels[0].min_order;
        }
      }
      return true;
    },
    [mainStore && mainStore.channels, total]
  );

  const saveSlugInLS = useCallback(
    async (business_slug) => {
      if (slugSelected !== business_slug) {
        setCategories([]);
        setAllcategoryWProducts([]);
        store.remove(CATALOGS_STORE);
        store.remove(MAIN_STORE);
        store.remove(COUPON_APPLIED);
        handleClearEmpty();

        setQuestions([]);
        setContact({});
        setStores([]);
        localStorage.setItem(STORE_SELECTED, business_slug);
        setSlugSelected(business_slug);
      }
    },
    [handleClearEmpty, slugSelected]
  );

  const getQuestionsByBrandFunc = useCallback(async (business_slug) => {
    try {
      const response = await businessApi.getQuestionsBrandForSlug(
        business_slug
      );

      if (response) {
        setQuestions(response);
      }
    } catch (error) {
      throw new Error("Not found questions Business");
    }
  }, []);

  const getContactByBrandFunc = useCallback(async (business_slug) => {
    try {
      const response = await businessApi.getContactByBrandForSlug(
        business_slug
      );

      if (response) {
        setContact(response);
      }
    } catch (error) {
      throw new Error("Not found contacts Business");
    }
  }, []);

  const getStoresLocation = useCallback(async (business_slug) => {
    try {
      const response = await businessApi.getLocationsStoreForSlug(
        business_slug
      );
      if (response) {
        setStores(response);
      }
    } catch (error) {
      throw new Error("Not found stores location Business");
    }
  }, []);

  return (
    <BusinessContext.Provider
      value={{
        brand,
        mainStore,
        channelStore,
        gallery,
        banners,
        categories,
        catalogs,
        loading,
        questions,
        contact,
        stores,
        loadingProducts,
        getBrandInformations,
        resetMainStoreState,
        validateButtonPay,
        saveSlugInLS,
        slugSelected,
        getAllCategoriesWithProducts,
        allcategoryWProducts,
        credentials,
        getCredentialsByStoreFunc,
        getQuestionsByBrandFunc,
        setMainStore,
        setChannelStore,
        setCatalogs,
        getGallery,
        getBanners,
        getContactByBrandFunc,
        getStoresLocation,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

function useBusiness() {
  const context = useContext(BusinessContext);

  if (!context) {
    throw new Error("useBusiness must be used within an BusinessProvider");
  }
  return context;
}

export { BusinessProvider, useBusiness };
