import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../../../contexts/cart";

const CartHeader = ({ item }) => {
  const { handleRemoveProduct } = useContext(CartContext);

  const handleClickProduct = () => {
    if (item) {
      const price = item.prices && item.prices.data[0] && item.prices.data[0].price;

      window.dataLayer.push({
        event: "productClick",
        ecommerce: {
          click: {
            actionField: {
              list: "Carrito",
            },
            product: {
              id: item.uuid,
              name: item.name,
              price: price,
              brand: item.vendor,
              category: item.category.name,
            }
          }
        }
      });
    }
  }

  return (
    <li>
      <div className="media">
        <Link to={`${process.env.PUBLIC_URL}/producto/${item.slug}`} onClick={handleClickProduct}>
          <img
            alt=""
            className="mr-3"
            src={
              item.media && item.media[0]
                ? item.media[0].conversions.original
                : `${
                    process.env.PUBLIC_URL
                  }/assets/images/default/default_iso.png`
            }
          />
        </Link>
        <div className="media-body">
          {item.vendor && <span>{item.vendor}</span>}
          <Link to={`${process.env.PUBLIC_URL}/producto/${item.slug}`} onClick={handleClickProduct}>
            <h4>{item.name}</h4>
          </Link>
          <h4>
            <span>
              {item.quantity} x{" "}
              {item.prices &&
                item.prices.data[0] &&
                item.prices.data[0].currency &&
                item.prices.data[0].currency.symbol}
              {item.prices && item.prices.data[0] && item.prices.data[0].price}
            </span>
          </h4>
        </div>
      </div>
      <div className="close-circle">
        <a href={null} onClick={() => handleRemoveProduct(item)}>
          <i className="fa fa-times" aria-hidden="true" />
        </a>
      </div>
    </li>
  );
};

export default CartHeader;
