import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import ProductListItem from "./product-list-item";
import ProductListItemLoader from "./product-list-item-loader";
import ProductModal from "../../common/product-modal";

const ProductListing = props => {
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [open, setOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);
  const { limit, setLimit, path, products, colSize, loadingProducts } = props;

  useEffect(() => {
    return function() {
      fetchMoreItems();
    };
  }, []);

  useEffect(() => {
    if (products.length > 0 && limit >= products.length) {
      setHasMoreItems(false);
    } else {
      setHasMoreItems(true);
    }
  }, [products]);

  const fetchMoreItems = () => {
    if (limit >= products.length) {
      setHasMoreItems(false);
      return;
    }

    setTimeout(() => {
      setLimit(limit + 8);
    }, 2000);
  };

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (!open) {
      setActiveProduct(null);
      html.removeAttribute("style");
    } else {
      html.style.position = "fixed";
      html.style.overflow = "hidden";
    }
  }, [open]);

  return (
    <div>
      <div className="product-wrapper-grid">
        <div className="container-fluid">
          {loadingProducts && (
            <div className="row">
              <div className={`col-lg-${colSize}`}>
                <ProductListItemLoader />
              </div>
              <div className={`col-lg-${colSize}`}>
                <ProductListItemLoader />
              </div>
              <div className={`col-lg-${colSize}`}>
                <ProductListItemLoader />
              </div>
              <div className={`col-lg-${colSize}`}>
                <ProductListItemLoader />
              </div>
              <div className={`col-lg-${colSize}`}>
                <ProductListItemLoader />
              </div>
              <div className={`col-lg-${colSize}`}>
                <ProductListItemLoader />
              </div>
              <div className={`col-lg-${colSize}`}>
                <ProductListItemLoader />
              </div>
              <div className={`col-lg-${colSize}`}>
                <ProductListItemLoader />
              </div>
            </div>
          )}
          {!loadingProducts && products.length > 0 && (
            <InfiniteScroll
              dataLength={limit} //This is important field to render the next data
              next={fetchMoreItems}
              hasMore={hasMoreItems}
              loader={<div className="loading-cls" />}
              endMessage={
                <p className="seen-cls seen-it-cls">
                  <b>Has llegado al final!</b>
                </p>
              }
            >
              <div className="row">
                {products.slice(0, limit).map(product => (
                  <div
                    className={`${
                      colSize === 3
                        ? "col-xl-3 col-md-4 col-sm-6 col-grid-box"
                        : "col-lg-" + colSize
                    }`}
                    key={product.uuid}
                  >
                    <ProductListItem
                      product={product}
                      key={product.uuid}
                      path={path}
                      setOpen={setOpen}
                      setActiveProduct={setActiveProduct}
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          )}
          {activeProduct && (
            <ProductModal
              uuid={activeProduct.uuid}
              open={open}
              onCloseModal={() => setOpen(false)}
            />
          )}
          {!loadingProducts && products.length === 0 && (
            <div className="row">
              <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                  alt="empty listing"
                  className="img-fluid mb-4"
                />
                <h3>Lo siento! No hay productos que mostrar</h3>
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="btn btn-solid"
                >
                  continuar comprando
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
