import React, { useState, useEffect } from "react";

import { SlideUpDown } from "../../../services/script";
import LogoImage from "../headers/common/logo";
import { getContactInfoEndpoint } from "../../../services/endpoints";
import { getCategories } from "../../../services/products";

const FooterOne = (props) => {
  const [address, setAddress] = useState([]);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [showScroll, setShowScroll] = useState(false);

  const getContactInfo = async () => {
    const res = await getContactInfoEndpoint();
    const { address, emails, phones, social_networks } = res.data.data;

    setAddress(address);
    setEmails(emails);
    setPhones(phones);
    setSocialNetworks(social_networks);
  };

  const getParentCategories = async () => {
    const data = await getCategories();
    setParentCategories(data);
  };

  useEffect(() => {
    const contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      const elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }

    getContactInfo();
    getParentCategories();
  }, []);

  const clickToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <footer className="footer-light">
      {/* <div className="light-layout">
          <div className="container">
            <section className="small-section border-section border-top-0">
              <div className="row">
                <div className="col-lg-6">
                  <div className="subscribe">
                    <div>
                      <h4>Sé el primero en conocer!</h4>
                      <p>
                        No te pierdas nada subscribiendote a nuestro newsletter
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <form className="form-inline subscribe-form">
                    <div className="form-group mx-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Ingresa tu correo"
                      />
                    </div>
                    <button type="submit" className="btn btn-solid">
                      subscribirte
                    </button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div> */}
      <div
        className="tap-top"
        onClick={clickToTop}
        style={{ display: showScroll ? "block" : "none" }}
      >
        <div>
          <i className="fa fa-angle-double-up" />
        </div>
      </div>
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row footer-theme partition-f">
            <div className="col-lg-4 col-md-12 mb-md-5">
              <div className="footer-title footer-mobile-title">
                <h4>nosotros</h4>
              </div>
              <div className="footer-contant">
                <div className="footer-logo">
                  <LogoImage logo={props.logoName} />
                </div>
                <div className="sub-title">
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <a href="/nosotros#quienes-somos">Quiénes Somos</a>
                      </li>
                      <li>
                        <a href="/nosotros#nuestra-historia">
                          Nuestra Historia
                        </a>
                      </li>
                      <li>
                        <a href="/nosotros#vision-y-mision">Visión y Misión</a>
                      </li>
                      <li>
                        <a href="/nosotros#nuestro-concepto">
                          Nuestro Concepto
                        </a>
                      </li>
                      <li>
                        <a href="/nosotros#nuestra-filosofia">
                          Nuestra Filosofía
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-social">
                  <ul>
                    {socialNetworks.map((social) => {
                      return (
                        <li key={social.uuid}>
                          <a
                            href={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i
                              className={`fa fa-${social.name.toLowerCase()}`}
                              aria-hidden="true"
                            />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-md-5">
              <div className="sub-title">
                <div className="footer-title">
                  <h4>Empresa</h4>
                </div>
                <div className="footer-contant">
                  <ul>
                    <li>
                      <a href="/politica-de-cambios-y-devoluciones">
                        Política de Cambios y Devoluciones
                      </a>
                    </li>
                    <li>
                      <a href="/condiciones-del-delivery">
                        Condiciones del Delivery
                      </a>
                    </li>
                    <li>
                      <a href="/terminos-y-condiciones">
                        Términos y condiciones
                      </a>
                    </li>
                    <li>
                      <a href="/politicas-de-privacidad">
                        Políticas de Privacidad
                      </a>
                    </li>
                    <li>
                      <a href="/libro-de-reclamaciones/pasiflora">
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icons/book.png`}
                          alt="libro de reclamaciones"
                          style={{width: "25px", marginRight: "5px"}}
                        />
                        Libro de Reclamaciones
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-md-5">
              <div className="sub-title">
                <div className="footer-title">
                  <h4>Información de la tienda</h4>
                </div>
                <div className="footer-contant">
                  <ul className="contact-list">
                    {address.length !== 0 && (
                      <li>
                        <i className="fa fa-map-marker" />
                        {address[0].address}
                      </li>
                    )}
                    {phones.length !== 0 && (
                      <li>
                        <i className="fa fa-phone" />
                        {phones[0].phone}
                      </li>
                    )}
                    {emails.length !== 0 && (
                      <li>
                        <i className="fa fa-envelope-o" />
                        <a href={`mailto:${emails[0].email}`}>
                          {emails[0].email}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sub-footer ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="footer-end">
                <p>
                  <i className="fa fa-copyright" aria-hidden="true" /> 2020 -
                  Pasiflora |{" "}
                  <a
                    href="https://www.negocios.idbi.pe/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#ce9cf0" }}
                  >
                    Powered by ID B.I.
                  </a>
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="payment-card-bottom">
                <ul>
                  <li>
                    <a href="#">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/icon/visa.png`}
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/icon/mastercard.png`}
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/icon/american-express.png`}
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
