import React, { createContext, useContext, useState, useCallback } from "react";
import store from "store";

import {
  UBICATION_ORDER_CART,
  DATA_VERIFY_WORKING_AREA,
} from "../../constants/ActionTypes";
import UbicationApi from "../../services/UbicationApi";

const MapContext = createContext();

const ubicationApi = new UbicationApi();

const MapProvider = ({ children }) => {
  const [isInCoverage, setIsInCoverage] = useState(() => {
    const coverage = store.get(DATA_VERIFY_WORKING_AREA);
    if (coverage) {
      return coverage.is_coverage;
    }
    return false;
  });
  const [methodsDelivery, setMethodsDelivery] = useState(() => {
    const methodsDel = store.get(DATA_VERIFY_WORKING_AREA);
    if (methodsDel) {
      return methodsDel.methods_delivery;
    }
    return [];
  });
  const [price, setPrice] = useState(() => {
    const priceLS = store.get(DATA_VERIFY_WORKING_AREA);
    if (priceLS) {
      return priceLS.price_delivery;
    }
    return {};
  });

  const [showMap, setShowMap] = useState(false);

  const [ubication, setUbication] = useState(() =>
    store.get(UBICATION_ORDER_CART)
  );

  const setUbicationAndVerifyCoverageAndPrice = useCallback(
    async ({ business_slug, channel, locate }) => {
      if (locate && locate.lat && locate.lng) {
        setUbication(locate);
        store.set(UBICATION_ORDER_CART, locate);

        const payload = {
          lat: locate.lat,
          lng: locate.lng,
        };

        const response = await ubicationApi.verifyCoverageAndPrice({
          business_slug,
          channel,
          payload,
        });

        if (response && response.is_coverage === false) {
          setIsInCoverage(response.is_coverage);
          return;
        }

        if (response && response.is_coverage) {
          setIsInCoverage(response.is_coverage);
          setMethodsDelivery(response.methods_delivery);
          setPrice(response.price_delivery);
        }
      }
    },
    []
  );

  return (
    <MapContext.Provider
      value={{
        ubication,
        setUbication,
        showMap,
        isInCoverage,
        methodsDelivery,
        price,
        setShowMap,
        setUbicationAndVerifyCoverageAndPrice,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

function useMap() {
  const context = useContext(MapContext);

  if (!context) {
    throw new Error("useMap must be used within an MapProvider");
  }
  return context;
}

export { MapProvider, useMap };
