import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export const WrappSC = styled.div`
  width: 100%;
  margin: 0;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const ContainerCarousel = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative;

  > a {
    width: 100%;
    height: 179px;

    @media (min-width: 700px) {
      height: 80vh;
      box-shadow: none;
    }
  }
`;

export const ButtonBanner = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &.left {
    left: 25px;
  }
  &.right {
    right: 25px;
  }

  > i {
    color: #000;
    font-size: 16px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const ImgSC = styled.img`
  box-shadow: 0px 1px 6px 3px rgba(0,0,0,0.1);
  width: 100%;
  height: 165px;
  object-fit: cover;
  object-position: center;

  @media (min-width: 700px) {
    box-shadow: none;

    ${(props) =>
      props.height &&
      css`
        height: ${`${props.height}px`};
      `}
  }

  @media (min-width: 700px) and (max-width: 1023px) {
    height: 300px;
  }

  @media (min-width: 1024px) and (max-width: 1199px) {
    height: 430px;
  }

  @media (min-width: 1200px) {
    height: 80vh;
  }
`;

export const WrappButtonsSC = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
`;

export const ButtonSC = styled.div`
  background-color: #C4C4C4;
  border-radius: 4px;
  width: 6px;
  height: 6px;
  margin: 0 3px;
  transition: 0.4s width, 0.4s color;

  ${(props) =>
    props.select &&
    css`
      background-color: #ce9cf0;
      width: 36px;
    `}

  cursor: pointer;
`;


let time = null;

const Carousel = (props) => {
  const { data, timeInterval, height } = props;
  const [index, setIndex] = useState(0);
  const orderedData = data.sort((a, b) => a.order - b.order)

  const element = orderedData[index]

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(() => {
      if (index + 1 === orderedData.length) {
        setIndex(0);
      } else {
        setIndex((s) => s + 1);
      }
    }, element && element.transition_time || 10000);
  }, [index, orderedData.length, timeInterval, orderedData]);

  const handleClick = useCallback((i) => {
    setIndex(i);
  }, []);

  const handleButtonLeft = useCallback(
    (i) => {
      if (i === 0) {
        setIndex(orderedData.length - 1);
      } else {
        setIndex((s) => s - 1);
      }
    },
    [orderedData.length],
  );
  const handleButtonRight = useCallback(
    (i) => {
      if (i + 1 === orderedData.length) {
        setIndex(0);
      } else {
        setIndex((s) => s + 1);
      }
    },
    [orderedData.length],
  );

  const conditionalImage = useMemo(() => {
    if (element && element.href && element.is_external === 1) {
      return (
        <a
          href={`${element && element.href}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ height: `${height}px`, width: '100%', display: 'contents' }}
        >
          <ImgSC src={element && element.url} alt={element && element.url} height={height} />
        </a>
      );
    }

    if (element && element.href && element.is_external === 0) {
      const url = element && element.href && element.href.replace(`${window.location.origin}`, '');
      return (
        <Link
          to={`${url}`}
          style={{ height: `${height}px`, width: '100%', display: 'contents' }}
        >
          <ImgSC src={element && element.url} alt={element && element.url} height={height} />
        </Link>
      );
    }
    return (
      <ImgSC src={element && element.url} alt={element && element.url} height={height} />
    );
  }, [orderedData, height, index]);

  return (
    <WrappSC>
      {orderedData.length > 0 ? (
        <>
          <ContainerCarousel length={orderedData.length}>
            {orderedData.length > 1 &&
              <ButtonBanner
                className="left"
                onClick={() => handleButtonLeft(index)}
              >
                <i className="fa fa-chevron-left"></i>
              </ButtonBanner>
            }

            {conditionalImage}

            {orderedData.length > 1 &&
              <ButtonBanner
                className="right"
                onClick={() => handleButtonRight(index)}
              >
                <i className="fa fa-chevron-right"></i>
              </ButtonBanner>
            }
          </ContainerCarousel>
          {orderedData.length > 1 &&
            <WrappButtonsSC>
              {orderedData.map((e, i) => (
                <ButtonSC
                  key={i}
                  select={index === i}
                  onClick={() => handleClick(i)}
                />
              ))}
            </WrappButtonsSC>
          }
        </>
      ) : null}
    </WrappSC>
  );
};

export default React.memo(Carousel);
