import React, { useEffect, useState } from "react";
import { SlideToggle } from "react-slide-toggle";

import FilterItemLoader from "./filter-item-loader";

const Filter = (props) => {
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { loadingCategories, categories, filterProducts } = props;

  const closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  };

  useEffect(
    () => {
      filterProducts(filteredProducts);
    },
    [filteredProducts]
  );

  useEffect(
    () => {
      setFilteredProducts([]);
    },
    [categories]
  );

  const onClickCategory = (event, category) => {
    if (event.target.checked) {
      const products = category.products.map((product) => {
        return { ...product, categorySlug: category.slug };
      });

      setFilteredProducts([...filteredProducts, ...products]);
    } else {
      const newFilteredProducts = filteredProducts.filter((val) => {
        return val.categorySlug !== category.slug;
      });

      setFilteredProducts(newFilteredProducts);
    }
  };

  return (
    <div className="collection-filter-block">
      <div className="collection-mobile-back">
        <span className="filter-back" onClick={(e) => closeFilter(e)}>
          <i className="fa fa-angle-left" aria-hidden="true" /> volver
        </span>
      </div>
      <SlideToggle>
        {({ onToggle, setCollapsibleElement }) => (
          <div className="collection-collapse-block">
            <h3 className="collapse-block-title" onClick={onToggle}>
              categorías
            </h3>
            <div
              className="collection-collapse-block-content"
              ref={setCollapsibleElement}
            >
              <div className="collection-brand-filter">
                {loadingCategories && (
                  <div style={{ marginTop: "10px" }}>
                    <FilterItemLoader />
                    <FilterItemLoader />
                  </div>
                )}
                {!loadingCategories &&
                  categories.length > 0 &&
                  categories
                    .sort((a, b) => a.order - b.order)
                    .map((category) => {
                      return (
                        <div
                          className="custom-control custom-checkbox collection-filter-checkbox"
                          key={category.uuid}
                        >
                          <input
                            type="checkbox"
                            onClick={(e) => onClickCategory(e, category)}
                            value={category.name}
                            className="custom-control-input"
                            id={category.name}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={category.name}
                          >
                            {category.name}
                          </label>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        )}
      </SlideToggle>
    </div>
  );
};

export default Filter;
